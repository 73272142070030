import { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import parse from "html-react-parser";
import { useHistory, useParams } from "react-router-dom";
import fetchApi from "utils/fetchApi";
import {
  setLocalStorge,
  getLocalStorge,
  removeLocalStorge,
} from "utils/localStorge";
import timeLiner from "utils/timeLiner";


const TESTDATA = "testdata";
const COUNTDATE = "countdate";

function TestExecution() {
  const history = useHistory();
  const param = useParams()

  const [date, setDate] = useState(null);
  const [testItem, setTestItem] = useState("");
  const [loading, setLoading] = useState(true);
  const [exam, setExam] = useState({});
  const [questions, setQuestions] = useState([]);

  //handler functions
  const handleSelectItem = (e, testE) => {
    const data = questions.map((item) =>
      item.id === testItem.id
        ? {
            ...item,
            checked: true,
            answer: item.answer.map((i) => ({
              ...i,
              isTrue: e.id === i.id,
            })),
          }
        : item
    );
    const itemTest = {
      ...testE,
      answer: testE.answer.map((i) => ({
        ...i,
        isTrue: e.id === i.id,
      })),
    };
    setTestItem(itemTest);
    setQuestions(data);
    setLocalStorge(TESTDATA, data);
  };

  //util functions
  const lineDate = () => {
      setDate((prev) => {
        setLocalStorge(COUNTDATE, prev);
        if(prev <= 0) {
          postExam()
        };
        return --prev;
      });
  };
  let setTime;
  //server function
  const getExam = () => {
    fetchApi
      .get(`/exam/${param.id}/start`)
      .then((res) => {
        const { exam, questions } = res.data;
        setExam(exam);

        const data = questions.map((i) => ({
          ...i,
          checked: false,
          answer: i.answer.map((item) => ({
            ...item,
            isTrue: false,
          })),
        }));

        const localData = getLocalStorge(TESTDATA);
        const localDate = parseInt(getLocalStorge(COUNTDATE));

        if (localData?.length > 0 && localDate > 0) {
          setQuestions(localData);
          setTestItem(localData[0]);
        } else {
          setQuestions(data);
          setTestItem(data[0]);
        }

        if (localDate) {
          setDate(localDate);
        } else {
          const time = exam.duration * 60;
          setDate(time);
          setLocalStorge(COUNTDATE, time);
        }

        setTime = setInterval(lineDate, 1000)
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.data);
      });
  };
  const postExam = () => {
      let questions = getLocalStorge(TESTDATA);
      questions = questions
      .map((item) => ({
        id: item.id,
        answerId: item.answer.find((i) => i.isTrue)?.id,
        point: item.point
      }));
    fetchApi
      .post(`/exam/${param.id}/store-result`, {
        questions
      })
      .then((res) => {
          removeLocalStorge(TESTDATA);
          removeLocalStorge(COUNTDATE);
      })
      .catch((err) => {
          removeLocalStorge(TESTDATA);
          removeLocalStorge(COUNTDATE);
      });

    history.push("/admin/exams");
  };

  //useEffect
  useEffect(() => {
    getExam();
    return ()=>{
      removeLocalStorge(TESTDATA);
      clearInterval(setTime);
      removeLocalStorge(COUNTDATE);

    }
  }, []);

  return (
    <div className="selector-group">
      {loading ? (
        "Loading..."
      ) : (
        <Container fluid>
          <div className="py-4 flex justify-between ">
            <h1 className="font-bold text-xl">{exam.name}</h1>
            <div className="font-bold text-xl">{timeLiner(date)}</div>
            <Button onClick={postExam}>Yakunlash</Button>
          </div>
          <div>
            {questions.map((item, index) => (
              <Button
                key={index}
                onClick={() => setTestItem(item)}
                className={`m-1 hover:bg-blue-700 hover:text-white ${
                  item.checked && "bg-blue-700 text-white"
                }`}>
                {index + 1}
              </Button>
            ))}
          </div>
          {testItem ? (
            <div>
              <div className="p-3 my-2 text-xl bg-white rounded">
                {parse(testItem.name)}
              </div>
              <div className="py-2">
                {testItem.answer.map((item, index) => (
                  <div
                    key={index}
                    className="p-2 my-2 text-xl bg-white rounded">
                    <label
                      className="flex  space-x-2 items-center cursor-pointer m-0 text-md"
                      onClick={() => handleSelectItem(item, testItem)}>
                      <div
                        className={`h-4 w-4 border border-indigo-600 rounded-full ${
                          item.isTrue && "bg-blue-700"
                        }`}></div>
                      <div>{parse(item.name)}</div>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            "Loading..."
          )}
        </Container>
      )}
    </div>
  );
}

export default TestExecution;
