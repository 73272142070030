import React from "react";
import { useLocation, NavLink } from "react-router-dom";

import { Nav } from "react-bootstrap";
import { useSelector } from "react-redux";

//import logo from "assets/img/reactlogo.png";

function Sidebar({ routes }) {
  const location = useLocation();
  const { user } = useSelector((state) => state);

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  return (
    <div className="sidebar">
      {/* <div
        className="sidebar-background"
        // style={{
        //   backgroundImage: "url(" + image + ")",
        // }}
      /> */}
      <div className="sidebar-wrapper bg-white">
        {/* <div className="py-4"></div> */}
        <div className="logo d-flex align-items-center justify-content-start bg-blue-400">
          <a href="/" className="simple-text logo-mini mx-1">
            <div className="logo-">
              <img
                className="max-w-full max-h-60"
                src={require("assets/img/logo-white.png")}
                alt="..."
              />
            </div>
          </a>
        </div>
        <div className="">
          <Nav className="">
            {routes.map((prop, key) => {
              if (!prop.redirect)
                return (
                  <li
                    className={`
                    group
                    hover:bg-blue-400
                    hover:text-white
                    ${
                      prop.hidden?.includes(user?.role?.shortName)
                        ? " "
                        : " hidden"
                    }                   
                    ${
                      prop.upgrade
                        ? "active active-pro"
                        : activeRoute(prop.layout + prop.path)
                    }
                    `}
                    key={key}>
                    <NavLink
                      to={prop.layout + prop.path}
                      className="nav-link"
                      style={{
                        "&:hover": {
                          background: "blue",
                        },
                      }}
                      activeClassName="active">
                      <i
                        className={`${prop.icon} text-black group-hover:text-white`}
                      />
                      <p className="text-black group-hover:text-white">
                        {prop.name}
                      </p>
                    </NavLink>
                  </li>
                );
              return null;
            })}
          </Nav>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
