import React from "react";
import { Card, Container, Row, Col, Table } from "react-bootstrap";
import { RiDeleteBin6Line } from "react-icons/ri";
import { student_exams } from "server";

const StudentExams = () => {
  return (
    <div className="selector-group">
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg bg-white  rounded">
              <Card.Header className="flex w-full justify-end">
                <div className="w-full flex lg:mb-5 md:mb-2 space-x-2 ">
                  <select className="p-2 form-control md:w-full lg:w-1/3  bg-slate-50">
                    <option value="UZ">Uzbeksion</option>
                    <option value="US">United States</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select>
                  <input
                    className="form-control md:w-full lg:w-1/3  bg-slate-50	"
                    placeholder="Search name"
                  />
                </div>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table striped bordered responsive hover size="sm">
                  <thead>
                    <tr>
                      <th className="border-0">#</th>
                      <th className="border-0">Title</th>
                      <th className="border-0">Student</th>
                      <th className="border-0">Savollar</th>
                      <th className="border-0">Javoblar</th>
                      <th className="border-0">Ball</th>
                      <th className="border-0">Ball %</th>
                      <th className="border-0">Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {student_exams.map((item, idx) => (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{item.title}</td>
                        <td>{item.student}</td>
                        <td>{item.savol}</td>
                        <td>{item.javob}</td>
                        <td>{item.ball}</td>
                        <td>{item.ball_f}</td>
                        <td>{item.create_date}</td>
                        <td>
                          <RiDeleteBin6Line />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default StudentExams;
