import React, {useEffect, useState} from "react";

// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col
} from "react-bootstrap";


function User() {
    //states
    const [auth_user] = useState(JSON.parse(localStorage.getItem("user")));


    return (
        <>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col md="4">
                                            <Form.Group>
                                                <label>Foydalanuvchi nomi</label>
                                                <Form.Control
                                                    defaultValue={auth_user.username}
                                                    readOnly
                                                    placeholder="Username"
                                                    type="text"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md="4">
                                            <Form.Group>
                                                <label>Ism</label>
                                                <Form.Control
                                                    defaultValue={auth_user.firstname}
                                                    placeholder="First Name"
                                                    type="text"
                                                    readOnly
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md="4">
                                            <Form.Group>
                                                <label>Familiya</label>
                                                <Form.Control
                                                    defaultValue={auth_user.lastname}
                                                    placeholder="Last Name"
                                                    type="text"
                                                    readOnly
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <Form.Group>
                                                <label htmlFor="exampleInputEmail1">
                                                    Email
                                                </label>
                                                <Form.Control
                                                    defaultValue={auth_user.email}
                                                    readOnly
                                                    placeholder="Email address"
                                                    type="email"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md="8">
                                            <Form.Group>
                                                <label>Yasahsh manzili</label>
                                                <Form.Control
                                                    defaultValue={auth_user.address}
                                                    readOnly
                                                    placeholder="Address"
                                                    type="text"
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-1" md="4">
                                            <Form.Group>
                                                <label>Tug'ulgan sanasi</label>
                                                <Form.Control
                                                    defaultValue={auth_user.dob}
                                                    placeholder="Date of Birth"
                                                    type="date"
                                                    readOnly
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className="px-1" md="4">
                                            <Form.Group>
                                                <label>Telefon nomeri</label>
                                                <Form.Control
                                                    defaultValue={auth_user.phone}
                                                    placeholder="Country"
                                                    type="phone"
                                                    readOnly
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className="pl-1" md="4">
                                            <Form.Group>
                                                <label>Jinsi</label>
                                                <Form.Control
                                                    defaultValue={auth_user.gender === "1" ? "Erkak" : (auth_user.gender === "2" ? "Ayol" : "Boshqa")}
                                                    placeholder="Gender"
                                                    type="text"
                                                    readOnly
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {/*<Button*/}
                                    {/*  className="btn-fill pull-right"*/}
                                    {/*  type="submit"*/}
                                    {/*  variant="info"*/}
                                    {/*>*/}
                                    {/*  Update Profile*/}
                                    {/*</Button>*/}
                                    <div className="clearfix" />
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default User;
