import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { login } from "store/action";
import { useDispatch } from "react-redux";
import fetchApi from "utils/fetchApi";
import { toast } from "react-toastify";

const Redirect = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const { pathname, search } = location;
    fetchApi
      .get(pathname + search)
      .then((res) => {
        const { tokens, user } = res.data;
        dispatch(login(tokens, user));
        history.push("/login");
      })
      .catch((e) => {
        console.log(e);
        e?.data?.message && toast.error(e.data.message);
        e?.message && toast.error(e.message);
      });
  }, []);
  return <h1>Loading...</h1>;
};

export default Redirect;
