import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router";
import { Container, Row, Col, Table } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import fetchApi from "utils/fetchApi";
import Editor from "ckeditor5-custom-build/build/ckeditor";
const parse = require("html-react-parser");

function QuestionsCrud() {
  const params = useParams();
  const history = useHistory();
  //states
  const [name, setName] = useState("");
  const [answerName, setAnswerName] = useState("");
  const [answers, setAnswers] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [subjectId, setSubjectId] = useState("");
  const [point, setPoint] = useState(2);

  //handle functions
  const handleAnswerChange = (event, editor) => {
    const data = editor.getData();
    setAnswerName(data);
  };
  const handleCkeditChange = (event, editor) => {
    const data = editor.getData();
    setName(data);
  };
  const handleCheckedAnswer = (e, answer) => {
    setAnswers(
      answers.map((item) => {
        return {
          ...item,
          isTrue: item.name === answer.name,
        };
      })
    );
  };

  const handleChangePoint = (e) => {
    setPoint(e.target.value);
  }
  const handleAddAnswer = (e) => {
    e.preventDefault();
    const addTrue = answerName && !answers.find((i) => i.name === answerName);
    if (addTrue) {
      setAnswers((prev) => [
        ...prev,
        {
          name: answerName,
          isTrue: false,
        },
      ]);
      setAnswerName("");
    } else {
      toast.error("Javob kiritishda xatolik yuzberdi");
    }
  };
  const handleDelete = (answer) =>
    setAnswers(answers.filter((i) => i.name !== answer.name));
  const handleUpdate = (answer) => {
    setAnswerName(answer.name);
    handleDelete(answer);
  };

  const handleChangeSubject = (e) => setSubjectId(e.target.value);

  //server
  const addAnswers = () => {
    const data = {
      name,
      subjectId,
      answers,
      point
    };
    console.log(data);
    const postTrue = answers.find((i) => i.isTrue)?.isTrue;

    if (!name) {
      toast.error("Savol kiriting");
    } else if (answers.length === 0) {
      toast.error("Javoblar kiritilmagan");
    } else if (!subjectId) {
      toast.error("Fan tanlanmagan");
    } else if (!postTrue) {
      toast.error("To'g'ri javobni tanglang");
    } else {
      const url = params?.id
        ? `/question/${params.id}/update`
        : "/question/store";
      fetchApi
        .post(url, data)
        .then((res) => {
          console.log(res);
          toast.success(res.data.message);
          setAnswers([]);
          setName("");
          setSubjectId("");
          setPoint(2);
          params?.id && history.push("/admin/questions");
        })
        .catch((err) => {
          toast.error(err.data.message);
        });
    }
  };
  const getSubjects = () => {
    fetchApi.get("/subjects").then((res) => {
      const { subjects } = res.data;
      setSubjects(subjects);
    });
  };
  const getQuestionItem = (id) => {
    fetchApi.get(`/question/${id}/view`).then((res) => {
      const { answers, question, subject } = res.data;
      setAnswers(answers);
      setName(question.name);
      setSubjectId(subject.id);
      setPoint(question.point?question.point:2);
    });
  };


  const config = {
    // extraPlugins: [uploadPlugin],
  };

  useEffect(() => {
    if (params?.id) {
      getQuestionItem(params.id);
    }
    getSubjects();
  }, []);
  return (
    <div className="selector-group">
      <Container fluid>
          <Row className="mb-2">
            <Col xs={3}>
              <select
                value={subjectId}
                className="p-2 form-control md:w-full  bg-slate-50"
                onChange={handleChangeSubject}>
                <option disabled value="">
                  Fan tanlang...
                </option>
                {subjects.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </Col>
            <Col xs={3} >
              <input type="number" placeholder={"Ball"} value={point} onChange={handleChangePoint} className="form-control md:w-full  bg-slate-50	" />
            </Col>
            <Col xs={6}>
              <button
                type="button"
                onClick={addAnswers}
                className="rounded bg-blue-500 hover:bg-blue-600 text-slate-50 px-4 py-1 text-md right">
                {params?.id ? "Saqlash" : "Qo'shish"}
              </button>
            </Col>
          </Row>
        <Row>
          <Col md="12">
            <CKEditor
              config = {config}
              editor={Editor}
              data={name}
              onChange={handleCkeditChange}
            />
            <div className="py-2">
              <form
                onSubmit={handleAddAnswer}
                className="w-full flex justify-between space-x-2 answerCke">
                    <CKEditor
                      className="	" 
                      config = {config}
                      editor={Editor}
                      data={answerName}
                      onChange={handleAnswerChange}
                    />
                {/* <input
                  value={answerName}
                  onChange={handleAnswerChange}
                  className="form-control md:w-full  bg-slate-50	"
                  placeholder="Javob"
                /> */}
                <button
                  type="submit"
                  className="rounded bg-blue-500 hover:bg-blue-600 text-slate-50 px-3 py-1 text-md ">
                  +
                </button>
              </form>
              <div>
                <Table striped bordered responsive hover size="sm">
                  <thead>
                    <tr>
                      <th className="border-0">Javoblar</th>
                      <th className="border-0">Harakatlar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {answers.map((answer, index) => (
                      <tr key={index}>
                        <td>
                          <div className="flex space-x-2">
                            <input
                              className="hover:cursor-pointer"
                              id={`label-answer-${index}`}
                              onChange={(e) => handleCheckedAnswer(e, answer)}
                              type="radio"
                              name="answer"
                              checked={answer.isTrue}
                              value={answer.isTrue}
                            />
                            <label
                              htmlFor={`label-answer-${index}`}
                              className="px-2 m-0 hover:cursor-pointer">
                              {parse(answer.name)}
                            </label>
                          </div>
                        </td>
                        <td className="flex space-x-2 full-height align-center justify-center">
                          <div
                            onClick={() => handleUpdate(answer)}
                            className="btn btn-sm btn-success">
                            <FaEdit />
                          </div>
                          <div
                            onClick={() => handleDelete(answer)}
                            className="btn btn-sm btn-danger">
                            <RiDeleteBin6Line />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
function uploadAdapter(loader){
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        const form = new FormData();
        loader.file.then((file) => {
          form.append('image', file);
          fetchApi.post('/image/upload', form).then((res) => {
            let imageUrl = res.data.image.url;
            resolve({default: imageUrl});
          }).catch((err) => {
            reject(err);
          })
        }).catch((err) => {
          reject(err);
        })
      })
    }
  }
}

function uploadPlugin(editor){
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  }
}
export default QuestionsCrud;
