import React, { useState } from "react";
import { useEffect } from "react";
import {Form, Modal, Button, FormCheck, Row, Col} from "react-bootstrap";
import fetchApi from "utils/fetchApi";
import { toast } from "react-toastify";

function AcceptModal (
    {
        taskItem,
        modalAcceptTask,
        handleCloseAcceptCloseModel,
        setTaskLoading,
        setTaskItem,
        protocols
    }
){
    const { Control } = Form;
    const { Header, Title, Body, Footer } = Modal;
    const [educationId, setEducation] = useState('');
    const [experience, setExperience] = useState('');
    const [certificateNumber, setCertificateNumber] = useState('');
    const [certificateDate, setCertificateDate] = useState('');

    const handleOnChangeSelectEducation = (e) => {
        setEducation(e.target.value);
    }

    const handleOnChangeExperience = (e) => {
        setExperience(e.target.value);
    }
    const handleOnChangeCertificateNumber = (e) => {
        setCertificateNumber(e.target.value);
    }
    const handleOnChangeCertificateDate = (e) => {
        setCertificateDate(e.target.value);
    }
    // server function
    const acceptTask = () => {
        const data = {
            educationId: educationId,
            experience: experience,
            certificateDate: certificateDate,
            certificateNumber: certificateNumber
        };
        setTaskLoading(true);
        fetchApi.post(`task/${taskItem.id}/accept`, data).then((res) => {
            toast.success(res.data.message);
            setTaskItem(res.data.task);
            setTaskLoading(false);
        });
        setTaskLoading(false);
        handleCloseAcceptCloseModel();
    };
    useEffect(() => {

    }, []);
    return (
        <Modal show={modalAcceptTask} onHide={handleCloseAcceptCloseModel} size='lg'>
            <Header closeButton>
                <Title>Arizani qabul qlish</Title>
            </Header>
            <Body className="space-y-3">
                <Row>
                    <Col md="6" className="mt-2">
                        <select
                            value={educationId}
                            className="p-2 form-control md:w-full  bg-slate-50"
                            onChange={handleOnChangeSelectEducation}>
                            <option disabled value="">
                                OLiy talimni tanlang...
                            </option>
                            {taskItem.eduInfo?.map((item) => (
                                <option key={item.id} value={item.id.value}>
                                    {item.edu_name.value}
                                </option>
                            ))}
                        </select>
                    </Col>

                    <Col md="6" className="mt-2">
                        <Control
                            type="number"
                            onChange={handleOnChangeExperience}
                            placeholder="Sohadagi mehnat staji...">
                        </Control>
                    </Col>
                    <Col md="6" className="mt-2">
                        <Control
                            type="text"
                            onChange={handleOnChangeCertificateNumber}
                            placeholder="Malaka oshrish kursi sertifikati...">
                        </Control>
                    </Col>
                    <Col md="6" className="mt-2">
                        <Control
                            type="text"
                            onChange={handleOnChangeCertificateDate}
                            placeholder="Malaka oshrish kursini tugatgan sanasi...">
                        </Control>
                    </Col>
                </Row>


            </Body>
            <Footer>
                <Button variant="secondary" onClick={handleCloseAcceptCloseModel}>
                    Bekor qilish
                </Button>
                <Button variant="success" onClick={acceptTask}>
                    Yuborish
                </Button>
            </Footer>
        </Modal>
    );
}

export default AcceptModal;