import React, { useState } from "react";
import { useEffect } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import fetchApi from "utils/fetchApi";
import { toast } from "react-toastify";

function ModalGroup({
  groupEdit,
  modalGroup,
  currentPage,
  handleCloseGroupModel,
  setGroupLoading,
  getGroups,
}) {
  // components
  const { Control } = Form;
  const { Header, Title, Body, Footer } = Modal;

  // states
  const [group, setGroup] = useState("");

  // handler function
  const handleChangeGroup = (e) => setGroup(e.target.value);

  // server function
  const addGroup = () => {
    const data = { name: group, status: "active" };
    const { id } = groupEdit;
    const url = id ? `/group/${groupEdit.id}/update` : "/group/store";

    fetchApi
      .post(url, data)
      .then((res) => {
        setGroupLoading(true);
        setGroup("");
        getGroups(currentPage);
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.data.message);
      });
    handleCloseGroupModel();
  };

  useEffect(() => {
    setGroup(groupEdit.name);
  }, [groupEdit]);

  return (
    <Modal show={modalGroup} onHide={handleCloseGroupModel}>
      <Header closeButton>
        <Title>Guruh qo'shish</Title>
      </Header>
      <Body className="space-y-3">
        <Control
          value={group}
          onChange={handleChangeGroup}
          type="text"
          placeholder="Guruh nomi"
        />
      </Body>
      <Footer>
        <Button variant="secondary" onClick={handleCloseGroupModel}>
          Bekor qilish
        </Button>
        <Button variant="primary" onClick={addGroup}>
          Qo'shish
        </Button>
      </Footer>
    </Modal>
  );
}

export default ModalGroup;
