import React, {useEffect, useState} from "react";
// react-bootstrap components
import {
  Card,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import format from "../utils/moment";
import {RiDeleteBin6Line} from "react-icons/ri";
import fetchApi from "../utils/fetchApi";
import {Link} from "react-router-dom";
import {FaEye} from "react-icons/fa";


function Dashboard() {
  const [regions, setRegions] = useState([]);
  const [points, setPoints] = useState({minPoint: 0, avgPoint: 0, maxPoint: 0});
  const [payments, setPayments] = useState({payment_waiting: 0, paid_count: 0, paid_sum: 0});

  const getRegions = () => {
    fetchApi
        .get("dashboard/regions-stats", {
          params: {},
        })
        .then((res) => {
          const {regions} = res.data;
          setRegions(regions)
        });
  }

  const getPoints = () => {
    fetchApi
        .get("dashboard/points-stats", {
          params: {},
        })
        .then((res) => {
          const {points} = res.data;
          setPoints(points)
        });
  }

  const getPayments = () => {
    fetchApi
        .get("dashboard/payments-stats", {
          params: {},
        })
        .then((res) => {
          const {payments} = res.data;
          setPayments(payments);
        });
  }

  useEffect(() => {
    getRegions();
    getPoints();
    getPayments();
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12" sm="12">
            <Card className="card-stats">
              <Card.Header>
                <h1>Arizalar viloyatlar kesimida</h1>
              </Card.Header>
              <Card.Body>
                <Table striped bordered responsive hover size="sm">
                  <thead>
                    <tr>
                      <th className="border-0">Viloyat</th>
                      <th className="border-0">Arizalar soni</th>
                      <th className="border-0">Yangi</th>
                      <th className="border-0">Rad qilingan</th>
                      <th className="border-0">Tolov kutilmoqda</th>
                      <th className="border-0">Qabul qilingan</th>
                      <th className="border-0">Imtixondan o'tganlar</th>
                      <th className="border-0">Imtixondan o'tmaganlar</th>
                      <th className="border-0">Imtixonga kelmaganlar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {regions.map((item, idx) => (
                        <tr key={idx} >
                          <td className="px-1" style={{ fontWeight: item.name === 'Jami' ? "700" : ''}}>{item.name}</td>
                          <td className="px-1 text-center" style={{ fontWeight: item.name === 'Jami' ? "700" : ''}}>{item.all}</td>
                          <td className="px-1 text-center" style={{ fontWeight: item.name === 'Jami' ? "700" : ''}}>{item.new}</td>
                          <td className="px-1 text-center" style={{ fontWeight: item.name === 'Jami' ? "700" : ''}}>{item.rejected}</td>
                          <td className="px-1 text-center" style={{ fontWeight: item.name === 'Jami' ? "700" : ''}}>{item.waiting_payment}</td>
                          <td className="px-1 text-center" style={{ fontWeight: item.name === 'Jami' ? "700" : ''}}>{item.process}</td>
                          <td className="px-1 text-center" style={{ fontWeight: item.name === 'Jami' ? "700" : ''}}>{item.finished}</td>
                          <td className="px-1 text-center" style={{ fontWeight: item.name === 'Jami' ? "700" : ''}}>{item.exam_failed}</td>
                          <td className="px-1 text-center" style={{ fontWeight: item.name === 'Jami' ? "700" : ''}}>{item.exam_not_come}</td>
                        </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="6" sm="6">
            <Card className="card-stats">
              <Card.Header>
                <h1>Imtihon natijalari bo'yicha to'plangan ballar statistikasi</h1>
              </Card.Header>
              <Card.Body>
                <Table striped bordered responsive hover size="sm">
                  <thead>
                  <tr>
                    <th className="border-0">Eng baland ball</th>
                    <th className="border-0">O'rtacha ball</th>
                    <th className="border-0">Eng past ball</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-1">{points.maxPoint}</td>
                      <td className="px-1">{parseInt(points.avgPoint)}</td>
                      <td className="px-1">{points.minPoint}</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="6" sm="6">
            <Card className="card-stats">
              <Card.Header>
                <h1>To'lov ma'lumotlari</h1>
              </Card.Header>
              <Card.Body>
                <Table striped bordered responsive hover size="sm">
                  <thead>
                    <tr>
                      <th className="border-0">To'lov kutilmoqda</th>
                      <th className="border-0">To'langan arizalar soni</th>
                      <th className="border-0">To'langan summa:</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-1">{payments.payment_waiting}</td>
                      <td className="px-1">{payments.paid_count}</td>
                      <td className="px-1">{payments.paid_sum.toLocaleString()}</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
