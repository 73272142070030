import React, {useEffect, useState} from 'react';
import {Card, Col, Container, Row, Table} from "react-bootstrap";
import SubjectUpdateModal from "../components/Subject/SubjectUpdateModal";
import {RiDeleteBin6Line} from "react-icons/ri";
import ModalDelete from "../components/models/ModalDelete";
import Pagination from "../components/Pagination/Pagination";
import fetchApi from "../utils/fetchApi";
import {toast} from "react-toastify";

const Roles = () => {

    // states
    const [role, setRole] = useState("");
    const [loadingRoles, setLoadingRoles] = useState(false);
    const [roles, setRoles] = useState([]);
    const [roleId, setRoleId] = useState(null);
    //pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(3);
    const [pages, setPages] = useState(1);
    // modal states
    const [modalDelete, setModalDelete] = useState(false);

    // handler function
    const handleChangeInput = (e) => {
        setRole(e.target.value)
    };
    const handleOpenDeleteModel = (id) => {
        setModalDelete(true);
        setRoleId(id);
    };
    const handleCloseDeleteModel = () => setModalDelete(false);

    // add function
    function addRole() {
        const data = { name: subject};
        fetchApi.post("/role/store", data).then((res) => {
            // console.log(res.data);
            setLoadingRoles(true);
            setRole("");
            getRoles(currentPage, recordsPerPage);
            toast.success(res.data.message);
        });
    }

    // get function
    const getRoles = (page = null, limit = recordsPerPage) => {
        fetchApi
            .get("/roles", {
                params: {
                    page,
                    limit,
                },
            })
            .then((res) => {
                const { roles, pages } = res.data;
                // console.log("Subjects:", subjects)
                setRoles(roles);
                setLoadingRoles(false);
                setPages(pages)
            });
    };

    // Effect Hook
    useEffect(() => {
        getRoles(currentPage, recordsPerPage);
    }, [currentPage]);


    return (
        <div className="selector-group">
            <Container fluid>
                <Row>
                    <Col md="12" lg="5">
                        <div className="flex justify-between items-center px-4 py-2 bg-gray-200 rounded ">
                            <div className="text-gray-500 text-lg">Yangi rol qo'shish</div>
                        </div>
                        <div className="form-group  bg-white px-4 py-2">
                            <input
                                type="email"
                                className="form-control"
                                id="exampleInputEmail1"
                                placeholder="Lavozim nomi"
                                value={role}
                                onChange={handleChangeInput}
                            />
                            <div className="flex justify-end">
                                <button className="mt-2 py-2 px-3 rounded bg-sky-500 font-bold text-white hover:bg-sky-600 transition" onClick={addRole}>
                                    Saqlash
                                </button>
                            </div>
                        </div>
                    </Col>
                    <Col md="12" lg="7">
                        <div className="w-full lg:mb-5 md:mb-2 ">
                            <input className="form-control " placeholder="Nomi bilan qidirish" />
                        </div>
                        <Card className="card-plain table-plain-bg bg-white  rounded">
                            <Card.Body className="table-full-width table-responsive px-0">
                                <Table striped bordered responsive hover size="sm">
                                    <thead>
                                    <tr>
                                        <th className="border-0">ID</th>
                                        <th className="border-0">Nomi</th>
                                        <th className="border-0">Kiritilgan sana</th>
                                        <th className="border-0">Harakatlar</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {loadingRoles?<tr><td>Loading ...</td></tr>:roles.map((item, idx) => (
                                        <tr key={item.id}>
                                            <td>{idx + 1}</td>
                                            <td>{item.name}</td>
                                            <td>
                                                {item.createdAt}
                                            </td>
                                            <td className="flex space-x-2 full-height align-center justify-center">
                                                {/*<SubjectUpdateModal subject={item} setSubjectLoading={setLoadingRoles} getSubjects={getRoles} currentPage={currentPage} />*/}
                                                <div
                                                    className="btn btn-sm btn-danger"
                                                    onClick={() => handleOpenDeleteModel(item.id)}>
                                                    <RiDeleteBin6Line />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalDelete
                url={`/role/delete/${roleId}`}
                modalDelete={modalDelete}
                handleCloseDeleteModel={handleCloseDeleteModel}
                getDataRetry={getRoles}
                currentPage={currentPage}
            />
            <Pagination
                nPages = { pages }
                currentPage = { currentPage }
                setCurrentPage = { setCurrentPage }
            />
        </div>
    );
};

export default Roles;