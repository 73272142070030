import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import fetchApi from "utils/fetchApi";
import TableTask from "components/Task/TableTask";
import Pagination from "components/Pagination/Pagination";
import { useSelector } from "react-redux";
import {
  setLocalStorge,
  getLocalStorge,
  removeLocalStorge,
} from "utils/localStorge";

const Tasks = () => {
  const { user } = useSelector((state) => state);

  // states
  const [taskItems, setTaskItems] = useState([]);
  const [taskLoading, setTaskLoading] = useState(true);
  const [pages, setPages] = useState(1);
  const [statuses, setStatuses] = useState([]);
  const [regions, setRegions] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [districts, setDistricts] = useState([]);
  const [allItem, setAllItem] = useState(0);

  const limits = [10, 20, 30, 40, 50];
  let localFilter = getLocalStorge('filtertask');
  if(!localFilter){
    localFilter = {
      epiguid:'',
      page: 1,
      limit: 10,
      name: '',
      fio: '',
      selectedStatus: '',
      selectedRegion: '',
      selectedDistrict: ''
    }
  }
  const [currentPage, setCurrentPage] = useState(localFilter.page);
  const [filterTask, setTaskFilter] = useState(localFilter);
  const handlerSearchByEpiguId = (e) => {
    let id = e.target.value;
    setTaskFilter({...filterTask,epiguid: id});
  }
  const handlerSearchByFio = (e) => {
    let fio = e.target.value
    setTaskFilter({...filterTask,fio: fio});
  }

  const handlerSearchByStatus = (e) => {
    let id = e.target.value
    setTaskFilter({...filterTask,selectedStatus: id});
  }

  const handlerSearchByRegion = (e) => {
    let id = e.target.value
    setTaskFilter({...filterTask,selectedRegion: id, selectedDistrict:''});
    getDistricts(id);
  }
  const handlerSearchByDistrict = (e) => {
    let id = e.target.value;
    setTaskFilter({...filterTask,selectedDistrict:id});
  }
  const handlerChangeLimit = (e) => {
    let limit = e.target.value
    setTaskFilter({...filterTask,limit:limit});
  }
  const getStatuses = () => {
    fetchApi
        .get("/get-statuses", {
          params: {},
        })
        .then((res) => {
          const { statuses } = res.data;
          setStatuses(statuses);
        });

  }
  const getRegions = () => {
    fetchApi
        .get("/get-regions", {
          params: {},
        })
        .then((res) => {
          const {regions} = res.data;
          setRegions(regions);
        });
  }
  const getDistricts = (id) => {
      fetchApi
          .get("/get-districts", {
            params: {selectedRegion: id},
          })
          .then((res) => {
            const {districts} = res.data;
            setDistricts(districts);
          });
  }
  //server funtions
  const getTask = () => {
    //let filterTasks = getLocalStorge("filtertask");
    console.log(filterTask);
    fetchApi
      .get("/tasks", {
        params: filterTask,
      })
      .then((res) => {
        const { tasks, pages, items} = res.data;
        setTaskItems(tasks);
        setPages(pages);
        setTaskLoading(false);
        setAllItem(items);
      });
  };
  const downloadExcel = () => {
    const link = document.createElement("a");
    let url = fetchApi.defaults.baseURL + "/task/excel-download?epiguid=" + filterTask.epiguid + "&fio=" + filterTask.fio + "&selectedStatus=" + filterTask.selectedStatus + "&selectedRegion=" + filterTask.selectedRegion + "&selectedDistrict=" + filterTask.selectedDistrict;
    console.log(url);
    link.href = url;
    link.download = url;
    link.click();
  }
  // Lifecycle
  useEffect(() => {
    setLocalStorge('filtertask', filterTask);
    getTask();
  }, [filterTask]);
  // useEffect(() => {
  //   // getDistricts();
  //   // getTask();
  // }, []);
  useEffect(() => {
    getRegions();
    getStatuses();
  }, []);
  useEffect(() => {
    setTaskFilter({...filterTask,page:currentPage});
  }, [currentPage])

  return (
    <div className="selector-group">
      <Container fluid>
        <div className="flex w-full justify-end mb-2 space-x-5"></div>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg bg-white  rounded">
              <Card.Header className="w-full">
                <Row>
                  <Col md="1">
                    <select
                        value={filterTask.limit}
                        className="p-2 form-control md:w-full  bg-slate-50"
                        onChange={handlerChangeLimit}>
                      {limits.map((item) => (
                          <option key={item} value={item} >
                            {item}
                          </option>
                      ))}
                    </select>
                  </Col>
                  <Col md="1">
                    <input
                        type="text"
                        className="form-control md:w-full  bg-slate-50	"
                        placeholder="Ariza raqami"
                        onChange={handlerSearchByEpiguId}
                        value={filterTask.epiguid}
                    />
                  </Col>
                  <Col md="2">
                    <input
                        type="text"
                        className="form-control md:w-full  bg-slate-50	"
                        placeholder="F.I.O"
                        onChange={handlerSearchByFio}
                        value={filterTask.fio}
                    />
                  </Col>
                  <Col md="2">
                    <select
                        value={filterTask.selectedRegion}
                        className="p-2 form-control md:w-full  bg-slate-50"
                        onChange={handlerSearchByRegion}>
                      <option value="">
                        Viloyatni tanlang...
                      </option>
                      {regions.map((item) => (
                          <option key={item.areaId} value={item.areaId}>
                            {item.name}
                          </option>
                      ))}
                    </select>
                  </Col>
                  <Col md="2">
                    <select
                        value={filterTask.selectedDistrict}
                        className="p-2 form-control md:w-full  bg-slate-50"
                        onChange={handlerSearchByDistrict}>
                      <option value="">
                        Tumanni tanlang...
                      </option>
                      {districts.map((item) => (
                          <option key={item.areaId} value={item.areaId}>
                            {item.name}
                          </option>
                      ))}
                    </select>
                  </Col>
                  <Col md="2">
                    <select
                        value={filterTask.selectedStatus}
                        className="p-2 form-control md:w-full  bg-slate-50"
                        onChange={handlerSearchByStatus}>
                      <option value="">
                        Statusni tanlang...
                      </option>
                      {statuses.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                      ))}
                    </select>
                  </Col>
                  <Col md="2">
                    <Button variant="success" onClick={() => downloadExcel()}> Yuklab olish</Button>
                  </Col>
                </Row>


              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                {taskLoading ? (
                  <h1>Loading...</h1>
                ) : (
                  <TableTask exams={taskItems} currentPage={filterTask.page} limitData={filterTask.limit} allItem={allItem} />
                )}
              </Card.Body>
            </Card>
            <Pagination
              nPages={pages}
              currentPage={filterTask.page}
              setCurrentPage={setCurrentPage}
              allItem={allItem}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Tasks;
