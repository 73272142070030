import React, { useState } from "react";
import { useEffect } from "react";
import {Form, Modal, Button, Table} from "react-bootstrap";
import fetchApi from "utils/fetchApi";
import { toast } from "react-toastify";
import {FaDownload} from "react-icons/fa";

function AfterExamProtocol({
    protocolItem,
    modalView,
    handleCloseModalView,
})
{
    const [loadingInfo, setLoadingInfo] = useState(true);
    const [protocolCommision, setProtocolCommision] = useState([]);
    const [rais, setProtocolRais] = useState("");
    const [raisDeputy, setProtocolRaisDeputy] = useState("");
    const [asCommision, setProtocolAsCommision] = useState("");
    const [allTasks, setAllTasks] = useState([]);
    const [successTasks, setSuccessTasks] = useState([]);
    const [failTasks, setFaileTasks] = useState([]);
    const [notComeTasks, setNotComeTasks] = useState([]);
    const [allExam, setAllExam] = useState([]);
    const handleProtocolGetPdf = (id) => {
        fetchApi.get(`/protocol/${id}/get-after-url`).then((res) => {
            const link = document.createElement("a");
            console.log(res);
            link.href = res.data;
            link.download = res.data;
            link.click()
        })
    }
    const { Body, Footer } = Modal;
    // server function
    const getAfterExamInfo = (id) => {
        fetchApi.get(`/protocol/${id}/get-after-exam-info`).then((res) => {
            const { commision, rais, raisDeputy, asCommision, allTasks, failTasks, notComeTasks, allExam, successTasks} = res.data;
            setProtocolCommision(commision);
            setProtocolRais(rais);
            setProtocolRaisDeputy(raisDeputy);
            setProtocolAsCommision(asCommision);
            setSuccessTasks(successTasks);
            setAllTasks(allTasks);
            setFaileTasks(failTasks);
            setNotComeTasks(notComeTasks);
            setAllExam(allExam);
            setLoadingInfo(false);
        })
    }
    useEffect(() => {
        if(modalView){
            getAfterExamInfo(protocolItem.id);
        }
    }, [modalView]);
    return (
        <Modal show={modalView} onHide={handleCloseModalView} dialogClassName="protocol-view">
            {!loadingInfo ? (
                <Body className="space-y-3" style={{padding:"75px"}}>
                    <div className="">
                        <div className="heading">
                            <table className="no-border">
                                <tbody>
                                <tr>
                                    <td width="35%">
                                        {(rais?.examCommision?.second_approve == 1)?(
                                            <img style={{height:"125px"}} src={rais.qrCode}/>
                                        ):""}
                                    </td>
                                    <td width="35%">

                                    </td>
                                    <td width="30%">
                                        <p style={{fontWeight:"700", textAlign:"center"}}>"TАSDIQLАYMАN"
                                            <br/>
                                            O‘zbekiston Respublikasi
                                            <br/>
                                            Iqtisodiyot va moliya vazirligi huzuridagi
                                            <br/>
                                            {rais?.position}
                                            <br/>
                                            {rais?.fullName}
                                            <br/>
                                            {protocolItem.fullYearSecond??""}
                                            <br/>
                                        </p>
                                    </td>
                                </tr>
                                </tbody>

                            </table>
                        </div>
                        <hr className="style2" />
                        <div className="title">
                            <p className="filial-text text-center"  style={{fontWeight:"700"}}>
                                O‘zbekiston Respublikasi Iqtisodiyot va moliya vazirligi huzuridagi Kadastr
                                agentligining
                                2021-yil 30-apreldagi 130-05-sonli hamda 2023-yil 24-fevraldagi 35-11-sonli
                                buyrug‘lari asosida tashkil
                                qilingan Ko‘chmas mulk bo‘yicha kadastr muhandisi malaka shahodatnomasini olishga
                                talabgor bo‘lgan jismoniy
                                shaxslarni malaka imtihonidan o‘tkazish bo‘yicha komissiyasining
                            </p>
                            <div className="subtitle" style={{marginTop: "10px", textAlign:"center"}}>
                                <p  style={{fontWeight:"700"}}>№ {protocolItem.number+1}-sonli BAYONI</p>
                            </div>
                        </div>
                        <article>
                            <div className="container">
                                <div className="text-main">
                                    <div className="item">
                                        <table className="no-border">
                                            <tbody>
                                            <tr>
                                                <td width="30%">
                                                    <div className="first-child" style={{fontWeight:"700"}}>Raislik qiluvchi:</div>
                                                </td>
                                                <td width="70%">
                                                    <div className="second-child">
                                                        {rais?.position} {rais?.fullName}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%">
                                                    <div className="first-child"  style={{fontWeight:"700"}}>Komissiya aʼzolari:</div>
                                                </td>
                                                <td width="70%">
                                                    <div className="second-child">
                                                        <div>{raisDeputy?.position} {raisDeputy?.fullName},</div>
                                                        {protocolCommision.map((item, idx) => (
                                                            <div key={idx}>{item.position} {item.fullName},</div>
                                                        ))}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="30%">
                                                    <div className="first-child"  style={{fontWeight:"700"}}>Komissiya kotibi:</div>
                                                </td>
                                                <td width="70%">
                                                    <div className="second-child">
                                                        {asCommision?.position} {asCommision?.fullName}
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                    <div>
                                        <div className="subtitle" style={{margin: "20px", textAlign:"center"}}>
                                            <p style={{fontSize: "24px!important", fontWeight:"700"}}>KUN TАRTIBI</p>
                                        </div>
                                    </div>
                                    <div clas="item">
                                        <div className="third-child" style={{textIndent:"35px", textAlign:"justify"}}>
                                            O‘zbekiston Respublikasi Vazirlar Mahkamasining “Kadastr sohasida ayrim davlat xizmatlari ko‘rsatishning ma’muriy reglamentlarini tasdiqlash to‘g‘risida”gi
                                            2020-yil 2-sentyabrdagi 535-sonli qaroriga 5v-ilova,O‘zbekiston Respublikasi Davlat soliq qo‘mitasi huzuridagi Kadastr agentligining 2021-yil
                                            30-apreldagi “Ko‘chmas mulk bo‘yicha kadastr muhandisining malaka shahodatnomasini berish to‘g‘risida”gi 130-05-sonli buyrug‘i hamda O‘zbekiston Respublikasi
                                            Iqtisodiyot va moliya vazirligi huzuridagi Kadastr agentligining “Kadastr agentligining 2021-yil 30-apreldagi 130-05-son buyrug‘iga
                                            qo‘shimcha va o‘zgartirishlar kiritish to‘g‘risida”gi 2023-yil 24-fevraldagi 35-11-sonli buyrug‘lari asosida tashkil qilingan Ko‘chmas
                                            mulk bo‘yicha kadastr muhandisining malaka shahodatnomasini berish bo‘yicha malaka imtihoni komissiyasi tomonidan Kadastr muhandisi malaka
                                            shahodatnomasini olishga talabgor bo‘lgan malaka imtihonini topshirishga ruxsat etilgan {allTasks.length} nafar jismoniy shaxslarni imtihon natijalarini ko‘rib chiqish haqida.
                                        </div>
                                        <div className="third-child" style={{textIndent:"35px", textAlign:"justify"}}>
                                            Kun tartibidagi masala yuzasidan { asCommision?.position } { asCommision?.fullName } (mas’ul kotib) so‘zga chiqib, malaka imtihonini topshirishga ruxsat etilgan {allTasks.length} nafar
                                            talabgorlar geodeziya va yer kadastri bo‘yicha oliy ma’lumotga ega yoki kadastr munosabatlari sohasida kasbiy qayta
                                            tayyorgarlik dasturi bo‘yicha ta’lim olganligidan qatiy nazar, geodeziya va yer kadastri bo‘yicha kamida bir yil ish
                                            stajiga ega bo‘lgan jismoniy shaxslar bo‘lib,
                                            {allExam.map((item, idx) => (
                                                item.examDate+" kuni soat "+item.examTime+" "
                                            ))} da Davlat kadastrlari palatasi binosida bo‘lib o‘tgan  malaka imtihoni haqida  quyidagilarni ma’lum qildi:
                                        </div>
                                        <div className="third-child" style={{textIndent:"70px", textAlign:"justify"}}>
                                            {successTasks.length + failTasks.length} nafar talabgor imtihonga o‘z vaqtida keldi.
                                        </div>
                                        <div className="third-child" style={{textIndent:"70px", textAlign:"justify"}}>
                                            {notComeTasks.length} nafari tegishli tarzda xabardor qilingan bo‘lsada imtihon topshirish uchun o‘z vaqtida kelmadi.
                                        </div>
                                        <div className="third-child" style={{textIndent:"35px", textAlign:"justify"}}>
                                            O‘zbekiston Respublikasi Vazirlar Mahkamasining “Kadastr sohasida ayrim davlat xizmatlari ko‘rsatishning ma’muriy reglamentlarini
                                            tasdiqlash to‘g‘risida”gi 2020-yil 2-sentyabrdagi 535-sonli qaroriga 5v-ilova,2-bob 21-bandiga muvofiq Malaka imtihonini topshirishda
                                            talabgorlar O‘zbekiston Respublikasi fuqarosining biometrik pasporti, O‘zbekiston Respublikasi fuqarosining identifikatsiyalovchi
                                            ID-kartasi yoki shaxsini tasdiqlovchi boshqa hujjatni taqdim etishi belgilanganligi,ushbu bobning 22-bandiga muvofiq Talabgor
                                            O‘zbekiston Respublikasi fuqarosining biometrik pasporti, O‘zbekiston Respublikasi fuqarosining identifikatsiyalovchi ID-kartasi
                                            yoki shaxsini tasdiqlovchi boshqa hujjatni taqdim qilmaganda yoxud malaka imtihoniga kechikkan taqdirda, imtihonga qo‘yilmaydi va
                                            malaka imtihoniga kelmagan hisoblanadi.Shuningdek ushbu bobning 28-bandiga muvofiq  test sinovi sohaga oid 75 ta savoldan iborat
                                            bo‘lib, talabgorga malaka imtihonini topshirish umumiy imtihon vaqti davomiyligi 60 daqiqani tashkil etadi. Ushbu maʼmuriy reglamentning
                                            2-bob 32-bandiga muvofiq talabgorning to‘g‘ri javoblar bo‘yicha yakuniy natijasi 70 foiz va undan yuqori ko‘rsatkichni tashkil qilgan
                                            bo‘lsa, u malaka imtihonini muvaffaqiyatli topshirgan hisoblanadi, aksincha to‘g‘ri javoblar bo‘yicha yakuniy natijasi 70 foizdan past
                                            bo‘lgan talabgor malaka imtihonidan o‘tmagan hisoblanadi.
                                        </div>
                                        <div className="third-child" style={{textIndent:"35px", textAlign:"justify"}}>
                                            Shu bilan birga, imtihon test o‘tkazish jarayonida maʼmuriy reglamentning
                                            2-bob 29-bandiga muvofiq test sinovlari o‘tkaziladigan xonaga jadval, kitob, uyali telefon va shpargalkalar olib kirish,
                                            boshqa talabgorlar bilan suhbatlashish taqiqlanishi hamda ushbu talablarni buzgan talabgorlar test sinovlari o‘tkaziladigan
                                            xonadan chiqarib yuborilishi va malaka imtihonidan o‘tmagan hisoblanishi belgilanganligi haqida talabgorlar ogohlantirilib
                                            o‘tilganligini  qo‘shimcha ravishda bildirib o‘tdi.
                                        </div>
                                        <div className="third-child" style={{textIndent:"35px", textAlign:"justify", fontWeight:"700"}} >
                                            Kun tartibida muhokama qilingan masalalardan kelib chiqib, yig‘ilish qaror qiladi
                                        </div>
                                        <div className="third-child" style={{textIndent:"70px", textAlign:"justify"}}>
                                            1. { asCommision?.position } { asCommision?.fullName } axboroti ma’lumot uchun qabul qilinsin.
                                        </div>
                                        <div className="third-child" style={{textIndent:"70px", textAlign:"justify"}}>
                                            2. Quyidagilar:
                                        </div>
                                        <div className="third-child" style={{textIndent:"105px", textAlign:"justify"}}>
                                            Kadastr muhandisi malaka shahodatnomasini olishga talabgor bo‘lgan jismoniy shaxslarning malaka imtihonini topshirishdagi
                                            to‘g‘ri javoblar bo‘yicha yakuniy natijasi 70 foiz va undan yuqori ko‘rsatkichni tashkil qilgan,  malaka imtihonini muvaffaqiyatli
                                            topshirganlar ro‘yxati 1-ilovaga muvofiq;
                                        </div>
                                        <div className="third-child" style={{textIndent:"105px", textAlign:"justify"}}>
                                            Kadastr muhandisi malaka shahodatnomasini olishga talabgor bo‘lgan jismoniy shaxslarning malaka imtihonini topshirishdagi to‘g‘ri
                                            javoblar bo‘yicha yakuniy natijasi 70 foizdan past bo‘lgan, malaka imtihonidan o‘tmaganlar  ro‘yxati 2-ilovaga muvofiq;
                                        </div>

                                        <div className="third-child" style={{textIndent:"105px", textAlign:"justify"}}>
                                            Kadastr muhandisi malaka shahodatnomasini olishga talabgor bo‘lgan jismoniy shaxslarning malaka imtihonini topshirishga
                                            kelmaganlar ro‘yxati 3-ilovaga muvofiq tasdiqlansin.
                                        </div>
                                        <div className="third-child" style={{textIndent:"70px", textAlign:"justify"}}>
                                            3. Ko‘chmas mulk bo‘yicha kadastr muhandisi malaka shahodatnomasini olishga talabgor bo‘lgan jismoniy
                                            shaxslarni malaka imtihonidan o‘tkazish bo‘yicha komissiya  kotibi { asCommision?.fullName }:
                                        </div>
                                        <div className="third-child" style={{textIndent:"105px", textAlign:"justify"}}>
                                            Malaka shahodatnomasi Agentlik tomonidan malaka imtihoni komissiyasining bayonnomasi olinganidan boshlab besh kun ichida
                                            rasmiylashtirilishini va talabgor ariza va hujjatlarni Agentlikka taqdim etgan usulda elektron ko‘rinishda talabgorga taqdim
                                            etilishi 1-ilovada keltirilgan talabgorlarga malaka shahodatnomasi berilishi ta’minlansin;
                                        </div>
                                        <div className="third-child" style={{textIndent:"105px", textAlign:"justify"}}>
                                            ushbu bayonning 2-ilovasida keltirilgan, ro‘yxatdagi malaka imtihonidan o‘tmagan talabgorlar uni navbatdagi malaka imtihonida
                                            takroran topshirish huquqiga ega ekanliklari, biroq shu kunning o‘zida qayta topshirishga yo‘l qo‘yilmasligi to‘g‘risida og‘zaki
                                            ravishda ogohlantirilganligi inobadga olinsin.
                                        </div>
                                        <div className="third-child" style={{textIndent:"70px", textAlign:"justify"}}>
                                            4. Mazkur bayonda belgilab berilgan topshiriqlar ijrosini nazorat qilish
                                            Davlat kadastrlari palatasi raisi M.Valiyev zimmasiga yuklatilsin.
                                        </div>
                                    </div>
                                    <div>
                                        <div className="subtitle" style={{margin: "20px 0"}}>
                                            <p style={{fontSize: "24px!important", fontWeight:"700"}}>Komissiya aʼzolari:</p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <table className="no-border" style={{width:"100%"}}>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <div className="first-child"  style={{fontWeight:"700"}}>{raisDeputy?.position}:</div>
                                                </td>
                                                <td width="30%" style={{textAlign: "center"}}>
                                                    {(raisDeputy?.examCommision?.second_approve ==1)?(
                                                        <img style={{height:"125px"}} src={raisDeputy.qrCode}/>
                                                    ):""}
                                                </td>
                                                <td width="30%">
                                                    <div className="second-child">{raisDeputy?.fullName}</div>
                                                </td>
                                            </tr>
                                            {protocolCommision.map((item, idx) => (
                                                <tr key={idx}>
                                                    <td width="40%">
                                                        <div className="first-child"  style={{fontWeight:"700"}}>{item.position}:</div>
                                                    </td>
                                                    <td width="30%" style={{textAlign: "center"}}>
                                                        {(item?.examCommision?.second_approve ==1)?(
                                                            <img style={{height:"125px"}} src={item.qrCode}/>
                                                        ):""}

                                                    </td>
                                                    <td width="30%">
                                                        <div className="second-child">{item.fullName}</div>
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td width="40%">
                                                    <div className="first-child"  style={{fontWeight:"700"}}>{asCommision.position}:</div>
                                                </td>
                                                <td width="30%" style={{textAlign: "center"}}>
                                                    {(asCommision?.examCommision?.second_approve ==1)?(
                                                        <img style={{height:"125px"}} src={asCommision.qrCode}/>
                                                    ):""}

                                                </td>
                                                <td width="30%">
                                                    <div className="second-child">{asCommision.fullName}</div>
                                                </td>
                                            </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </article>
                        <div className="heading" style={{marginTop:"30px"}}>
                            <table className="no-border">
                                <tbody>
                                <tr>
                                    <td width="32%">

                                    </td>
                                    <td width="32%">

                                    </td>
                                    <td width="36%">
                                        <p  style={{fontWeight:"700", textAlign:"center"}}>Ko‘chmas mulk bo‘yicha kadastr
                                            <br/>
                                            muhandisi malaka shahodatnomasini olishga
                                            <br/>
                                            talabgor bo‘lgan jismoniy shaxslarni malaka
                                            <br/>
                                            imtihonidan  o‘tkazish bo‘yicha komissiyasining
                                            <br/>
                                            {protocolItem.fullYear} dagi {protocolItem.number+1}-sonli
                                            <br/>
                                            bayoniga 1-ilova
                                            <br/>
                                        </p>
                                    </td>
                                </tr>
                                </tbody>

                            </table>
                        </div>
                        <div className="title">
                            <p className="filial-text text-center" style={{fontWeight: "700", marginTop:"20px"}} >
                                Malaka imtihonini topshirishdagi to‘g‘ri javoblar bo‘yicha yakuniy natijasi
                                70 foiz va undan yuqori ko‘rsatkichni tashkil qilgan,  malaka imtihonini muvaffaqiyatli
                                topshirganlar
                            </p>
                            <div className="subtitle" style={{marginTop: "10px", textAlign:"center"}}>
                                <p style={{fontWeight: "700"}}>RO‘YXATI</p>
                            </div>
                        </div>
                        <article>
                            <div className="container">
                                <div className="text-main">
                                    <div className="item">
                                        <table style={{fontSize: "20px!important"}}>
                                            <thead>
                                                <tr style={{border: "1px solid #000", lineHeight:"20px"}}>
                                                    <td width="5%" style={{border: "1px solid #000"}}>№</td>
                                                    <td width="50%" style={{border: "1px solid #000"}}>Talabgorning F.I.SH</td>
                                                    <td width="15%" style={{border: "1px solid #000"}}>To‘g‘ri topilgan savollar soni</td>
                                                    <td width="15%" style={{border: "1px solid #000"}}>Foiz (%) ko‘rinishida</td>
                                                    <td width="15%" style={{border: "1px solid #000"}}>Topshirilgan  Test imtihoni natijasi</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {successTasks.map((item, idx) => (
                                                <tr key={idx}>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                        {item.len}
                                                    </td>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                        {item.fullName}
                                                    </td>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                        {item.trueAnswers}
                                                    </td>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                        {item.percentAnswers}
                                                    </td>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                        Muvaffaqiyatli
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </article>
                        <div className="heading">
                            <table className="no-border" style={{marginTop:"20px"}}>
                                <tbody>
                                <tr>
                                    <td width="32%">

                                    </td>
                                    <td width="32%">

                                    </td>
                                    <td width="36%">
                                        <p style={{fontWeight:"700", textAlign:"center"}}>Ko‘chmas mulk bo‘yicha kadastr
                                            <br/>
                                            muhandisi malaka shahodatnomasini olishga
                                            <br/>
                                            talabgor bo‘lgan jismoniy shaxslarni malaka
                                            <br/>
                                            imtihonidan  o‘tkazish bo‘yicha komissiyasining
                                            <br/>
                                            {protocolItem.fullYear} dagi {protocolItem.number+1}-sonli
                                            <br/>
                                            bayoniga 2-ilova
                                            <br/>
                                        </p>
                                    </td>
                                </tr>
                                </tbody>

                            </table>
                        </div>
                        <div className="title">
                            <p className="filial-text text-center" style={{fontWeight: "700", marginTop:"20px"}} >
                                Kadastr muhandisi malaka shahodatnomasini olishga talabgor bo‘lgan jismoniy shaxslarning malaka imtihonini
                                topshirishdagi to‘g‘ri javoblar bo‘yicha yakuniy natijasi
                                70 foizdan past bo‘lgan, malaka imtihonidan o‘tmaganlar

                            </p>
                            <div className="subtitle" style={{marginTop: "10px", fontWeight:"700", textAlign:"center"}}>
                                <p style={{fontWeight: "700"}}>RO‘YXATI</p>
                            </div>
                        </div>
                        <article>
                            <div className="container">
                                <div className="text-main">
                                    <div className="item">
                                        <table style={{fontSize: "20px!important"}}>
                                            <thead>
                                            <tr style={{border: "1px solid #000", lineHeight:"20px"}}>
                                                <td width="5%" style={{border: "1px solid #000"}}>№</td>
                                                <td width="50%" style={{border: "1px solid #000"}}>Talabgorning F.I.SH</td>
                                                <td width="15%" style={{border: "1px solid #000"}}>To‘g‘ri topilgan savollar soni</td>
                                                <td width="15%" style={{border: "1px solid #000"}}>Foiz (%) ko‘rinishida</td>
                                                <td width="15%" style={{border: "1px solid #000"}}>Topshirilgan  Test imtihoni natijasi</td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {failTasks.map((item, idx) => (
                                                <tr key={idx}>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                        {item.len}
                                                    </td>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                        {item.fullName}
                                                    </td>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                        {item.trueAnswers}
                                                    </td>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                        {item.percentAnswers}
                                                    </td>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                        Muvaffaqiyatsiz
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </article>
                        <div className="heading">
                            <table className="no-border" style={{marginTop:"20px"}}>
                                <tbody>
                                <tr>
                                    <td width="32%">

                                    </td>
                                    <td width="32%">

                                    </td>
                                    <td width="36%">
                                        <p style={{fontWeight:"700", textAlign:"center"}}>Ko‘chmas mulk bo‘yicha kadastr
                                            <br/>
                                            muhandisi malaka shahodatnomasini olishga
                                            <br/>
                                            talabgor bo‘lgan jismoniy shaxslarni malaka
                                            <br/>
                                            imtihonidan  o‘tkazish bo‘yicha komissiyasining
                                            <br/>
                                            {protocolItem.fullYear} dagi {protocolItem.number+1}-sonli
                                            <br/>
                                            bayoniga 3-ilova
                                            <br/>
                                        </p>
                                    </td>
                                </tr>
                                </tbody>

                            </table>
                        </div>
                        <div className="title">
                            <p className="filial-text text-center" style={{fontWeight: "700", marginTop:"20px"}} >
                                Kadastr muhandisi malaka shahodatnomasini olishga talabgor bo‘lgan jismoniy shaxslarning malaka imtihonini topshirish uchun kelmaganlar

                            </p>
                            <div className="subtitle" style={{marginTop: "10px", fontWeight:"700", textAlign:"center"}}>
                                <p style={{fontWeight: "700"}}>RO‘YXATI</p>
                            </div>
                        </div>
                        <article>
                            <div className="container">
                                <div className="text-main">
                                    <div className="item">
                                        <table style={{fontSize: "20px!important"}}>
                                            <thead>
                                            <tr style={{border: "1px solid #000", lineHeight:"20px"}}>
                                                <td width="5%" style={{border: "1px solid #000"}}>№</td>
                                                <td width="50%" style={{border: "1px solid #000"}}>Talabgorning F.I.SH</td>
                                                <td width="15%" style={{border: "1px solid #000"}}>To‘g‘ri topilgan savollar soni</td>
                                                <td width="15%" style={{border: "1px solid #000"}}>Foiz (%) ko‘rinishida</td>
                                                <td width="15%" style={{border: "1px solid #000"}}>Topshirilgan  Test imtihoni natijasi</td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {notComeTasks.map((item, idx) => (
                                                <tr key={idx}>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                        {item.len}
                                                    </td>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                        {item.fullName}
                                                    </td>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                    </td>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                    </td>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                        Imtihonga kelmadi
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </Body>
            ):('')}
            {!loadingInfo ? (
                <Footer>
                    <Button variant="secondary" onClick={handleCloseModalView}>
                        Chiqish
                    </Button>
                    <Button variant="success" onClick={() => handleProtocolGetPdf(protocolItem.id)}>Protkol ni yuklab olish</Button>
                </Footer>
            ):('')}



        </Modal>
    );
}

export default AfterExamProtocol;
