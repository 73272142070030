import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col, Table } from "react-bootstrap";
import fetchApi from "utils/fetchApi";
import TableExam from "components/Exam/TableExam";
import Pagination from "components/Pagination/Pagination";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ExamStopModal from "../components/Exam/ExamStopModal";

const Exams = () => {
  const { user } = useSelector((state) => state);

  // states
  const [examItems, setExamItems] = useState([]);
  const [examLoading, setExamLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const [modalExam, setModalExam] = useState(false);
  const [examId, setExamId] = useState("");
  const [pages, setPages] = useState(1);
  const [allItem, setAllItem] = useState(0);
  const limitData = 10;

  //server funtions

  const handleOpenExamModel = (id) => {
    setExamId(id);
    setModalExam(true);
  }
  const handleCloseExamModel = () => {
    setExamId("");
    setModalExam(false);
  };
  const getExams = (page = 1, limit = limitData, name) => {
    fetchApi
      .get("/exams", {
        params: {
          page,
          limit,
          name,
        },
      })
      .then((res) => {
        const { exams, pages, items } = res.data;
        // console.log(groups)
        setExamItems(exams);
        setPages(pages);
        setExamLoading(false);
        setAllItem(items)
      });
  };

  // Lifecycle
  useEffect(() => {
    getExams(currentPage, limitData);
  }, [currentPage]);

  return (
    <div className="selector-group">
      <ExamStopModal
          examId={examId}
          handleCloseExamModel={handleCloseExamModel}
          modalExam={modalExam}
          setExamLoading={setExamLoading}
          getExams={getExams}
      />
      <Container fluid>
        <div className="flex w-full justify-end mb-2 space-x-5">
          {user?.role?.shortName !== "student" ? (
            <Link
              to="/admin/exam-create"
              className="rounded bg-blue-500 hover:bg-blue-600 text-slate-50 px-4 py-1 text-lg ">
              + Qo'shish
            </Link>
          ) : null}
        </div>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg bg-white  rounded">
              <Card.Header className="w-full">
                <input
                  className="form-control md:w-full lg:w-1/3  bg-slate-50	"
                  placeholder="Search name"
                />
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                {examLoading ? (
                  <h1>Loading...</h1>
                ) : (
                  <TableExam
                      exams={examItems}
                      handleOpenExamModel={handleOpenExamModel}
                  />
                )}
              </Card.Body>
            </Card>
            <Pagination
              nPages={pages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              allItem={allItem}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Exams;
