import React, {useEffect, useState} from "react";

const Pagination = ({ nPages, currentPage, setCurrentPage, allItem }) => {

  const [pages, setPagesState] = useState([]);
  //functions
  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const pager = () => {
    let pagination = [], i = 1;

    while (i <= nPages) {
      if (i <= 3 || //the first three pages
          i >= nPages - 2 || //the last three pages
          i >= currentPage - 1 && i <= currentPage + 1) { //the currentPage, the page before and after
        pagination.push(
            <li
                key={`${i}`}
                className={`page-item ${
                    currentPage === i ? "active" : ""
                } `}>
              <a
                  className="page-link"
                  href="#"
                  onClick={() => setCurrentPage(i)}>
                {i}
              </a>
            </li>
        );
        i++;
      } else { //any other page should be represented by ...
        pagination.push(<li className="page-item"><a href="#" className="page-link">...</a></li>);
        //jump to the next page to be linked in the navigation
        i = i < currentPage ? currentPage - 1 : nPages - 2;
      }
    }

    setPagesState(pagination);
  }


  useEffect(() => {
    pager();
  }, [setCurrentPage]);
  console.log(pages)
  return (
    <nav>
      <ul className="float-left">
        <li style={{fontWeight: "700"}}>Jami: {allItem}</li>
      </ul>
      <ul className="pagination float-right">
        <li className="page-item">
          <a
            className="page-link"
            href="#"
            aria-label="Previous"
            onClick={prevPage}>
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </a>
        </li>
        {pages.map((page) => page)}
        <li className="page-item">
          <a
            className="page-link"
            href="#"
            aria-label="Next"
            onClick={nextPage}>
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
