import React, { useState, useEffect } from "react";
import {Card, Container, Row, Col, Button, Table} from "react-bootstrap";
import fetchApi from "utils/fetchApi";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {useHistory, useParams} from "react-router";
import {toast} from "react-toastify";
import BeforeExamProtocol from "../components/Protocol/BeforeExamProtocol";
import AfterExamProtocol from "../components/Protocol/AfterExamProtocol";
import {FaDownload, FaExpand, FaEye} from "react-icons/fa";
import TableExamCommision from "../components/Protocol/TableExamCommision";
import ModalProtocolApprove from "../components/Protocol/ModalProtocolApprove";

const ProtocolView = () => {
    const params = useParams();
    const history = useHistory();
    const {user} = useSelector(state => state);
    // states
    const [protocolItem, setProtocolItem] = useState("");
    const [allCommision, setAllCommision] = useState([]);
    const [protocolId, setProtocolId] = useState("");
    const [protocolLoading, setProtocolLoading] = useState(true);
    const [modalAfterView, setAfterModalView] = useState(false);
    const [modalBeforeView, setBeforeModalView] = useState(false);
    const [sendToApproveModal, setSendToApproveModal] = useState(false);

    //server funtions
    const handleOpenAfterModalView = (id) => {
        setAfterModalView(true);
    }
    const handleOpenBeforeModalView = (id) => {
        setBeforeModalView(true)
    }
    const handleCloseAfterModalView = () => {
        setAfterModalView(false);
    }
    const handleCloseBeforeModalView = () => {
        setBeforeModalView(false);
    }

    const handleSendToApproveModal = () => {
        setSendToApproveModal(true);
    }

    const handleCloseSendToApproveModal = () => {
        setSendToApproveModal(false);
    }

    const handleSendToApprove = () => {
        fetchApi.get(`/protocol/${protocolItem.id}/send-approve`).then((res) => {
            const result = res.data;
            toast.success(result.message);
        })
    }
    const handleApprove = () => {
        fetchApi.get(`/protocol/${protocolItem.id}/approve`).then((res) => {
            const result = res.data;
            toast.success(result.message);
        })
    }
    const handleGetProtocolOrder = () => {
            const link = document.createElement("a");
            link.target = "blank";
            link.href = protocolItem.orderFile;
            link.download = protocolItem.orderFile;
            link.click()
    }
    const getProtocol = (id) => {
        fetchApi.get(`protocol/${id}/view`).then((res) => {
            const { protocol, allCommision } = res.data;
            console.log("protocol", protocol)
            setProtocolItem(protocol);
            setAllCommision(allCommision);
            setProtocolLoading(false);
        });
    }
    useEffect(() => {
        if (params?.id) {
            setProtocolId(params.id);
            getProtocol(params.id);
        }
    }, []);
    return (
        <div className="selector-group">
            <BeforeExamProtocol
                protocolItem={protocolItem}
                modalView={modalBeforeView}
                handleCloseModalView={handleCloseBeforeModalView}
            />
            <AfterExamProtocol
                protocolItem={protocolItem}
                modalView={modalAfterView}
                handleCloseModalView={handleCloseAfterModalView}
            />
            <ModalProtocolApprove
                modalProtocol={sendToApproveModal}
                protocol={protocolItem}
                handleCloseSendToApproveModal={handleCloseSendToApproveModal}
                getProtocol={getProtocol}
            />

            <Container fluid>
                <Row>
                    <Col md="7">
                        {protocolLoading ? (
                            <h1>Loading...</h1>
                        ) : (
                            <div className="rounded shadow p-2">
                                <ul className="flush">
                                    <li className='p-2'><b>Bayonnoma raqami:</b> {protocolItem.number}</li>
                                    <li className='p-2'><b>Bayonnoma nomi:</b> {protocolItem.name}</li>
                                    <li className='p-2'><b>Tizimdagi holati:</b> {protocolItem.generalStatus.name}</li>
                                    <li className='p-2'>
                                        <b>Imtihondan oldingi Bayonnoma: </b>
                                        <Button variant="success"  style={{padding:'2px 8px'}} onClick={() => handleOpenBeforeModalView(protocolId)}>
                                            <FaEye/>
                                        </Button>
                                    </li>
                                    <li className='p-2'>
                                        <b>Imtihondan keyingi Bayonnoma: </b>
                                        <Button variant="success"  style={{padding:'2px 8px'}} onClick={() => handleOpenAfterModalView(protocolId)}>
                                            <FaEye/>
                                        </Button>
                                    </li>
                                    {/*{protocolItem.orderFile?(*/}
                                    {/*    <li className='p-2'>*/}
                                    {/*        <b>Bayonnoma buyrugini yuklab olish: </b>*/}
                                    {/*        <Button variant="success"  style={{padding:'2px 8px'}} onClick={() => handleGetProtocolOrder()}>*/}
                                    {/*            <FaDownload/>*/}
                                    {/*        </Button>*/}
                                    {/*    </li>*/}
                                    {/*):("")}*/}

                                    <li className='p-2'><b>Kommisiya azolari:</b> </li>
                                </ul>

                                <TableExamCommision
                                    allCommision={allCommision}
                                />
                            </div>
                        )}

                    </Col>
                    {protocolLoading ? (
                        <h1>Loading...</h1>
                    ) : (
                        <Col md="2">
                            {(protocolItem.generalStatus.key == 'new' && (user.role.shortName == "commision-assistant"|| user.role.shortName == "manager" || user.role.shortName === 'superadmin'))?(
                                <Button variant="primary" onClick={handleSendToApproveModal}>
                                    Protokol ni Tasdiqlashga yuborish
                                </Button>
                            ):('')}
                            {protocolItem.localStatus.key == 'waiting-approved' ? (
                                    allCommision.map((item, idx) => (
                                        (item?.userId == user.id && item?.is_approved === 0)?(
                                            <Button variant="primary" onClick={handleApprove}>
                                                Tasdiqlash
                                            </Button>
                                        ):("")
                                    ))
                                ):('')}
                            {protocolItem.localStatus.key == 'second-waiting-approved' ? (
                                allCommision.map((item, idx) => (
                                    (item?.userId == user.id && item?.second_approve === 0)?(
                                        <Button key={idx} variant="primary" onClick={handleApprove}>
                                            Tasdiqlash
                                        </Button>
                                    ):("")
                                ))
                            ):('')}
                        </Col>
                    )}
                </Row>
            </Container>
        </div>
    );
}
export default ProtocolView;