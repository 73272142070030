export const login = (token, user) => {
  const { accessToken, refreshToken } = token;
  localStorage.setItem(
    "user_token",
    JSON.stringify({ accessToken, refreshToken })
  );
  localStorage.setItem(
      "user",
      JSON.stringify(user)
  )

  return {
    type: "LOGIN",
    payload: {
      accessToken,
      refreshToken,
      user,
    },
  };
};

export const logout = () => {
  localStorage.removeItem("user_token");
  localStorage.removeItem("user");
  localStorage.removeItem("filtertask");
  return { type: "LOGOUT" };
};
