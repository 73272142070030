import React, { useState } from "react";
import { useEffect } from "react";
import {Form, Modal, Button, FormCheck, Row, Col} from "react-bootstrap";
import fetchApi from "utils/fetchApi";
import { toast } from "react-toastify";

function ChangeGroupModal (
    {
        taskItem,
        modalAcceptTask,
        handleCloseChangeGroupModal,
        setTaskLoading,
        setTaskItem,
        groups
    }
){
    // console.log(taskItem)
    const { Header, Title, Body, Footer } = Modal;
    const [selectedGroup, setSelectedGroup] = useState('');

    const handleOnChangeSelectGroup = (e) => {
        // console.log(e.target.value)
        setSelectedGroup(e.target.value);
    }

    // server function
    const changeGroup = () => {
        const data = {
            groupId: selectedGroup,
        };
        setTaskLoading(true);
        fetchApi.post(`task/${taskItem.id}/change-group`, data).then((res) => {
            console.log('task', res.data.task)
            toast.success(res.data.message);
            setTaskItem(res.data.task);
            setTaskLoading(false);
        }).catch((err) => {
            toast.error(err);
        });
        setTaskLoading(false);
        handleCloseChangeGroupModal();
    };

    useEffect(() => {
        if (taskItem.groupId){
            setSelectedGroup(taskItem.groupId)
        }
    }, []);
    return (
        <Modal show={modalAcceptTask} onHide={handleCloseChangeGroupModal}>
            <Header closeButton>
                <Title>Guruhni o'zgartirish</Title>
            </Header>
            <Body>
                <Row>
                    <Col md="12">
                        <select
                            value={selectedGroup}
                            className="p-2 form-control w-full  bg-slate-50"
                            onChange={handleOnChangeSelectGroup}>
                            <option disabled value="">
                                Guruhni tanlang...
                            </option>
                            {groups.map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.name}
                                </option>
                                ))}
                        </select>
                    </Col>
                </Row>
            </Body>
            <Footer>
                <Button variant="secondary" onClick={handleCloseChangeGroupModal}>
                    Bekor qilish
                </Button>
                <Button variant="success" onClick={changeGroup}>
                    Yuborish
                </Button>
            </Footer>
        </Modal>
    );
}

export default ChangeGroupModal;