import React, { useState } from "react";
import { useEffect } from "react";
import {Form, Modal, Button, FormCheck, Row, Col} from "react-bootstrap";
import fetchApi from "utils/fetchApi";
import { toast } from "react-toastify";

function SendExamResult({
    taskItem,
    modalSendExamResult,
    handleCloseSendExamResultModal,
    setTaskLoading,
    setTaskItem
}) {
    // components
    const { Control, Label, Group } = Form;
    const { Header, Title, Body, Footer } = Modal;
    // server function
    const SendExamResult = async () => {
        const url = `/task/${taskItem.id}/send-exam-result`;
        setTaskLoading(true);
        fetchApi
            .post(url)
            .then((res) => {
                setTaskItem(res.data.task);
                setTaskLoading(false);
                toast.success(res.data.message);
            })
            .catch((err) => {
                setTaskLoading(false);
                toast.error(err);
            });
        handleCloseSendExamResultModal();
    };

    useEffect(() => {
    }, []);

    return (
        <Modal show={modalSendExamResult} onHide={handleCloseSendExamResultModal} size='lg'>
            <Header closeButton>
                <Title>Imtihon Natijalarini yuborish</Title>
            </Header>
            <Footer>
                <Button variant="secondary" onClick={handleCloseSendExamResultModal}>
                    Bekor qilish
                </Button>
                <Button variant="success" onClick={SendExamResult}>
                    Yuborish
                </Button>
            </Footer>
        </Modal>
    );
}

export default SendExamResult;
