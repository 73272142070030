const userToken = JSON.parse(window.localStorage.getItem("user_token"));
const user = JSON.parse(window.localStorage.getItem("user"));
const accessToken = userToken ? userToken.accessToken : null;
const refreshToken = userToken ? userToken.refreshToken : null;
const initialCheckAuth = userToken ? accessToken : false;

const initialState = {
  accessToken,
  refreshToken,
  initialCheckAuth,
  user,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      const isAuth = !!action.payload.accessToken;
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        initialCheckAuth: isAuth,
        user: action.payload.user,
      };
    case "LOGOUT":
      return {
        ...state,
        accessToken: null,
        refreshToken: null,
        initialCheckAuth: false,
        user: null,
      };
    default:
      return state;
  }
};

export default reducer;
