import React, {useEffect, useState, useRef} from 'react';
import {useParams} from "react-router";
import fetchApi from "../utils/fetchApi";
import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {toast} from "react-toastify";

const UsersView = () => {
    //query params
    const params = useParams();

    //states
    const [userId, setUserId] = useState(params.id)
    const [user, setUser] = useState()
    const [roles, setRoles] = useState([]);
    const [groups, setGroups] = useState([]);
    const [roleName, setRoleName] = useState("");
    const [groupId, setGroupId] = useState("");
    const [groupHidden, setGroupHidden] = useState('hidden');
    const [positionHidden, setPositionHidden] = useState('hidden');
    const [position, setPosition] = useState("");
    //refs
    const groupRef = useRef();
    const positionRef = useRef();
    //handlers
    const handleChangeRole = (e) => {
        let value = e.target.value;
        // console.log("value", value)
         let group_content = groupRef.current
        let position_content = positionRef.current
        // console.log(group_content)
        // let group_content = document.getElementById("group_content");
        if (value === "student"){
            position_content.hidden = true;
            group_content.hidden = false;
            getGroups()
        }else if(value === "commision" || value === "rais" ||value === "commision-assistant" || value === "manager" || value === "rais-deputy"){
            position_content.hidden = false;
            group_content.hidden = true;
        }else{
            position_content.hidden = true;
            group_content.hidden = true;
        }
        setRoleName(value)
    }

    const handleChangePosition = (e) => {
        let value = e.target.value;
        setPosition(value)
    }

    // console.log("roleName", roleName)

    const handleChangeGroup = (e) => {
        let value = e.target.value;
        if (roleName === "student"){
            setGroupId(value);
        }else{
            setGroupId(null);
        }
    }

    //functions
    function getUser(){
        // console.log("userId:", userId)
        const url = `user/${userId}/view`;
        // console.log("url:", url)
        fetchApi.get(url).then((res)=>{
            // console.log(res.data)
            let response_user = res.data.user
            setUser(response_user)
            if (response_user.roleId !== null){
                setRoleName(response_user.role.shortName)
                if (response_user.role.shortName === 'student'){
                    setGroupId(response_user.groupId)
                    setGroupHidden('')
                }else if(response_user.role.shortName === 'commision' || response_user.role.shortName === 'rais' || response_user.role.shortName === 'commision-assistant' || response_user.role.shortName === 'manager' || response_user.role.shortName === 'rais-deputy'){
                    setPositionHidden('');
                    setPosition(response_user.position)
                }
            }
        }).catch();
    }
    function updateUser(){
        const url = `user/${userId}/update`;
        const data = {
            roleName,
            groupId,
            position
        };
        fetchApi.post(url, data).then((res)=>{
            // console.log(res.data)
            toast.success(res.data.message)
        }).catch();
    }

    const getRoles = () => {
        fetchApi.get("/roles").then((res)=>{
            // console.log(res.data)
            let roles = res.data.roles
            // console.log(roles)
            setRoles(roles)
        }).catch();
    }
    function getGroups(){
        fetchApi.get("/groups").then((res)=>{
            // console.log("groups", res.data)
            let groups = res.data.groups
            setGroups(groups)
        }).catch();
    }


    //Hook Effects
    useEffect(() => {
        getGroups();
        getRoles();
        getUser();
        // console.log(groupId)
        // console.log(roleName)
    }, [])

    // console.log("user", user)

    if(!user) return  "Loading..."

    return (
        <div>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col md="4">
                                            <Form.Group>
                                                <label>Foydalanuvchi nomi</label>
                                                <Form.Control
                                                    defaultValue={user.username}
                                                    disabled
                                                    placeholder="Username"
                                                    type="text"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md="4">
                                            <Form.Group>
                                                <label>Ism</label>
                                                <Form.Control
                                                    defaultValue={user.firstname}
                                                    placeholder="First Name"
                                                    type="text"
                                                    disabled
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md="4">
                                            <Form.Group>
                                                <label>Familiya</label>
                                                <Form.Control
                                                    defaultValue={user.lastname}
                                                    placeholder="Last Name"
                                                    type="text"
                                                    disabled
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <Form.Group>
                                                <label htmlFor="exampleInputEmail1">
                                                    Email
                                                </label>
                                                <Form.Control
                                                    defaultValue={user.email}
                                                    disabled
                                                    placeholder="Email address"
                                                    type="email"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md="8">
                                            <Form.Group>
                                                <label>Yasahsh manzili</label>
                                                <Form.Control
                                                    defaultValue={user.address}
                                                    disabled
                                                    placeholder="Address"
                                                    type="text"
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-1" md="4">
                                            <Form.Group>
                                                <label>Tug'ulgan sanasi</label>
                                                <Form.Control
                                                    defaultValue={user.dob}
                                                    placeholder="Date of Birth"
                                                    type="date"
                                                    disabled
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className="px-1" md="4">
                                            <Form.Group>
                                                <label>Telefon nomeri</label>
                                                <Form.Control
                                                    defaultValue={user.phone}
                                                    placeholder="Country"
                                                    type="phone"
                                                    disabled
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className="pl-1" md="4">
                                            <Form.Group>
                                                <label>Jinsi</label>
                                                <Form.Control
                                                    defaultValue={user.gender === "1" ? "Erkak" : (user.gender === "2" ? "Ayol" : "Boshqa")}
                                                    placeholder="Gender"
                                                    type="text"
                                                    disabled
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <div className="form-group">
                                                <label htmlFor="role">Lavozimi</label>
                                                <select name="role" id="role" className="form-control" value={roleName} onChange={handleChangeRole}>
                                                    <option value="">Lavozimni tanlash</option>
                                                    {roles.map((item, key) => {
                                                        // {console.log(key+=1, item.name)}
                                                        return item.shortName !== "superadmin" ? <option key={key} value={item.shortName}>{item.name}</option> : ''
                                                     })}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col md="4" hidden={groupHidden} ref={groupRef} id="group_content">
                                            <label htmlFor="group">Guruh</label>
                                            <select name="group" id="group" className="form-control" value={groupId} onChange={handleChangeGroup}>
                                                <option value="">Guruhni tanlang</option>
                                                {groups.map((item, key) => {
                                                    // {console.log(key+=1, item.name)}
                                                    return <option key={key} value={item.id}>{item.name}</option>
                                                })}
                                            </select>
                                        </Col>
                                        <Col md="4" hidden={positionHidden} ref={positionRef} id="position_content">
                                            <label>Egallab turgan lavozimini kriting</label>
                                            <Form.Control
                                                value={position}
                                                onChange={handleChangePosition}
                                                type="text"
                                                placeholder="Egallab turgan lavozimini kriting"
                                            >

                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    <Button
                                      className="btn-fill pull-right float-right mt-3"
                                      variant="info"
                                      onClick={updateUser}
                                    >
                                      O'zgarishlarni saqlash
                                    </Button>
                                    <div className="clearfix" />
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default UsersView;