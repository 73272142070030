import React, { useState } from "react";
import { useEffect } from "react";
import {Form, Modal, Button, Table} from "react-bootstrap";
import fetchApi from "utils/fetchApi";
import { toast } from "react-toastify";

function ModalProtocolView({
    protocolItem,
    modalView,
    handleCloseModalView,
    protocolCommision,
    setProtocolItemLoad,
    protocolItemLoad,
    rais,
    raisDeputy,
    asCommision,
    allStudents,
    notRejectedStudents,
    rejectedStudents
})
{
    const { Control } = Form;
    const { Header, Title, Body, Footer } = Modal;
    const handleSendToApprove = () => {
        fetchApi.get(`/protocol/${protocolItem.id}/send-approve`).then((res) => {
            const result = res.data;
            toast.success(result.message);
            handleCloseModalView();
        })
    }
    const handleApprove = () => {
        fetchApi.get(`/protocol/${protocolItem.id}/approve`).then((res) => {
            const result = res.data;
            toast.success(result.message);
            handleCloseModalView();
        })
    }
    // server function
    useEffect(() => {

    }, []);
    return (
        <Modal show={modalView} onHide={handleCloseModalView} dialogClassName="protocol-view">
            {!protocolItemLoad ? (
                <Body className="space-y-3" style={{padding:"75px"}}>
                    <div className="">
                        <div className="heading">
                            <table className="no-border">
                                <tbody>
                                    <tr>
                                        <td width="35%">

                                        </td>
                                        <td width="35%">

                                        </td>
                                        <td width="30%">
                                            <p style={{fontWeight:"700", textAlign:"center"}}>"TАSDIQLАYMАN"
                                                <br/>
                                                O‘zbekiston Respublikasi
                                                <br/>
                                                Iqtisodiyot va moliya vazirligi huzuridagi
                                                <br/>
                                                {rais?.position}
                                                <br/>
                                                {rais?.fullName}
                                                <br/>
                                                {protocolItem.fullYear}
                                                <br/>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                        <hr className="style2" />
                        <div className="title">
                            <p className="filial-text text-center"  style={{fontWeight:"700"}}>
                                O‘zbekiston Respublikasi Iqtisodiyot va moliya vazirligi huzuridagi Kadastr
                                agentligining
                                2021-yil 30-apreldagi 130-05-sonli hamda 2023-yil 24-fevraldagi 35-11-sonli
                                buyrug‘lari asosida tashkil
                                qilingan Ko‘chmas mulk bo‘yicha kadastr muhandisi malaka shahodatnomasini olishga
                                talabgor bo‘lgan jismoniy
                                shaxslarni malaka imtihonidan o‘tkazish bo‘yicha komissiyasining
                            </p>
                            <div className="subtitle" style={{marginTop: "10px", textAlign:"center"}}>
                                <p  style={{fontWeight:"700"}}>№ {protocolItem.number}-sonli BAYONI</p>
                            </div>
                        </div>
                        <article>
                            <div className="container">
                                <div className="text-main">
                                    <div className="item">
                                        <table className="no-border">
                                            <tbody>
                                                <tr>
                                                    <td width="30%">
                                                        <div className="first-child" style={{fontWeight:"700"}}>Raislik qiluvchi:</div>
                                                    </td>
                                                    <td width="70%">
                                                        <div className="second-child">
                                                            {rais?.fullName} {rais?.position}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="30%">
                                                        <div className="first-child"  style={{fontWeight:"700"}}>Komissiya aʼzolari:</div>
                                                    </td>
                                                    <td width="70%">
                                                        <div className="second-child">
                                                            <div>{raisDeputy?.position} {raisDeputy?.fullName},</div>
                                                            {protocolCommision.map((item, idx) => (
                                                                <div key={idx}>{item.position} {item.fullName},</div>
                                                            ))}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="30%">
                                                        <div className="first-child"  style={{fontWeight:"700"}}>Komissiya kotibi:</div>
                                                    </td>
                                                    <td width="70%">
                                                        <div className="second-child">
                                                            {asCommision.position} {asCommision.fullName}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                    <div>
                                        <div className="subtitle" style={{margin: "20px", textAlign:"center"}}>
                                            <p style={{fontSize: "24px!important", fontWeight:"700"}}>KUN TАRTIBI</p>
                                        </div>
                                    </div>
                                    <div clas="item">
                                        <div className="third-child" style={{textIndent:"35px", textAlign:"justify"}}>
                                            O‘zbekiston Respublikasi Vazirlar Mahkamasining “Kadastr sohasida ayrim davlat xizmatlari ko‘rsatishning ma’muriy reglamentlarini tasdiqlash to‘g‘risida”gi
                                            2020-yil 2-sentyabrdagi 535-sonli qaroriga 5v-ilova,O‘zbekiston Respublikasi Davlat soliq qo‘mitasi huzuridagi Kadastr agentligining 2021-yil
                                            30-apreldagi “Ko‘chmas mulk bo‘yicha kadastr muhandisining malaka shahodatnomasini berish to‘g‘risida”gi 130-05-sonli buyrug‘i hamda O‘zbekiston Respublikasi
                                            Iqtisodiyot va moliya vazirligi huzuridagi Kadastr agentligining “Kadastr agentligining 2021-yil 30-apreldagi 130-05-son buyrug‘iga
                                            qo‘shimcha va o‘zgartirishlar kiritish to‘g‘risida”gi 2023-yil 24-fevraldagi 35-11-sonli buyrug‘i asosida tashkil qilingan Ko‘chmas mulk bo‘yicha kadastr
                                            muhandisining malaka shahodatnomasini berish bo‘yicha malaka imtihoni komissiyasi tomonidan malaka shahodatnomasini olish maqsadida imtihon
                                            topshirish uchun {allStudents.length} nafar talabgorlardan kelib tushgan arizalarni ko‘rib chiqish haqida.
                                        </div>
                                        <div className="third-child" style={{textIndent:"35px", textAlign:"justify"}}>
                                            Kun tartibidagi masala yuzasidan Davlat kadastrlari palatasi, Kadastr muhandislarining faoliyatini muvofiqlashtirish bo‘limi boshlig‘i
                                            X.Axmedov (mas’ul kotib) so‘zga chiqib, malaka shahodatnomasini olish maqsadida imtihon topshirish uchun kelib tushgan jami {allStudents.length} ta arizalar
                                            “Ko‘chmas mulk bo‘yicha kadastr muhandisining malaka shahodatnomasini berish tartibi to‘g‘risida”gi nizomning 4-bandi talablari asosida o‘rganib chiqish natijasiga ko‘ra
                                             {notRejectedStudents.length} ta arizalar mos kelganligi , {rejectedStudents.length} ta arizalar mos kelmaganligini aytib o‘tdi.
                                        </div>
                                        <div className="third-child" style={{textIndent:"35px", textAlign:"justify"}}>
                                            Vazirlar Mahkamasining 2020-yil 2-sentyabrdagi 535-son qaroriga 5v-ilova, “Toshkent viloyati va Toshkent shahrida ko‘chmas mulk bo‘yicha kadastr
                                            muhandisining malaka shahodatnomasini berish bo‘yicha davlat xizmatlari ko‘rsatish”ning ma’muriy reglamenti 1-bob 4-bandiga muvofiq Malaka imtihonini
                                            topshirish uchun talabgorlar quyidagi talablarga javob berishi lozim:
                                        </div>
                                        <div className="third-child" style={{textIndent:"70px", textAlign:"justify"}}>
                                            geodeziya, bino va inshootlar qurilishi, qurilish ekspertizasi, ko‘chmas mulk
                                            ekspertizasi, arxitektura, yer tuzish va yer kadastri yo‘nalishlari bo‘yicha oliy ma’lumotga ega bo‘lgan; <br/>

                                        </div>
                                        <div className="third-child" style={{textIndent:"70px", textAlign:"justify"}} >
                                            geodeziya, bino va inshootlar qurilishi, qurilish ekspertizasi, ko‘chmas mulk ekspertizasi, arxitektura, yer tuzish va yer kadastri yo‘nalishlari bo‘yicha kamida bir yil ish stajiga ega bo‘lishi
                                            yoki ish stajiga ega bo‘lmagan holda kadastr munosabatlari sohasida kasbiy qayta tayyorlash kurslari bo‘yicha ta’lim olgan talabgorlar malaka imtihonini
                                            topshirishga qo‘yiladilar. Shuningdek, malaka imtihonidan o‘tmagan talabgorlar uni navbatdagi malaka imtihonida takroran topshirish huquqiga ega, biroq
                                            shu kunning o‘zida qayta topshirishga yo‘l qo‘yilmaydi.
                                        </div>
                                        <div className="third-child" style={{textIndent:"35px", textAlign:"justify"}}>
                                            Bunda, talabgor geodeziya va yer kadastri bo‘yicha oliy ma’lumotga ega ekanligidan yoki kadastr munosabatlari sohasida kasbiy qayta tayyorgarlik
                                            dasturi bo‘yicha ta’lim olganligidan qat’iy nazar, geodeziya va yer kadastri bo‘yicha kamida bir yil ish stajiga ega bo‘lishi lozimligi belgilangan.
                                        </div>
                                        <div className="third-child" style={{textIndent:"35px", textAlign:"justify"}}>
                                            Shuningdek, ushbu nizomning  13-bandida ko‘rsatib o‘tilgan holatda, ya’ni:
                                        </div>
                                        <div className="third-child" style={{textIndent:"70px", textAlign:"justify"}}>
                                            talabgor mazkur Reglamentning 4-bandi talablariga javob bermaganda;
                                        </div>
                                        <div className="third-child" style={{textIndent:"70px", textAlign:"justify"}}>
                                            talabgor taqdim qilgan hujjatlar to‘liq bo‘lmaganda yoki noto‘g‘ri ma’lumotlar taqdim qilgani aniqlanganda;
                                        </div>

                                        <div className="third-child" style={{textIndent:"35px", textAlign:"justify"}}>
                                            davlat xizmatlarini ko‘rsatish uchun yig‘imni to‘lamaslik (bunda davlat xizmatidan foydalanish uchun xabarnomada ko‘rsatilgan to‘lov
                                            miqdori bir oy mobaynida to‘lanmagan buyurtmalar avtomatik rad etiladi). Davlat kadastrlari palatasi, Kadastr muhandislarining faoliyatini
                                            muvofiqlashtirish bo‘limi boshlig‘i X.Axmedov qo‘shimcha ravishda ba’zi talabgorlar arizani ketma-ket qaytadan elektron ariza berish tizimiga
                                            yuklaganligi sababli arizalarni rad etish va malaka imtihonini topshirishga ruxsat etilganlar ro‘yxatida aks etganligini ma’lum qildi.
                                        </div>
                                        <div className="third-child" style={{textIndent:"35px", textAlign:"justify"}}>
                                            Kun tartibida muhokama qilingan masalalardan kelib chiqib, yig‘ilish qaror qiladi:
                                        </div>
                                        <div className="third-child" style={{textIndent:"70px", textAlign:"justify"}}>
                                            1. Davlat kadastrlari palatasi, Kadastr muhandislarining faoliyatini muvofiqlashtirish bo‘limi boshlig‘i X.Axmedovning axboroti ma’lumot uchun qabul qilinsin.<br/>

                                        </div>
                                        <div className="third-child" style={{textIndent:"70px", textAlign:"justify"}}>
                                            2. Quyidagilar:
                                            Kadastr muhandisi malaka shahodatnomasini olishga talabgor bo‘lgan jismoniy shaxslarga malaka imtihonini topshirishga ruxsat etilganlar ro‘yxati 1-ilovaga muvofiq;
                                            Kadastr muhandisi malaka shahodatnomasini olishga talabgor bo‘lgan jismoniy shaxslarning malaka imtihonini topshirishga ruxsat etilmaganlar (rad etilgan) ro‘yxati     2-ilovaga muvofiq tasdiqlansin.
                                        </div>
                                        <div className="third-child" style={{textIndent:"70px", textAlign:"justify"}}>
                                            3. Ko‘chmas mulk bo‘yicha kadastr muhandisi malaka shahodatnomasini olishga talabgor bo‘lgan jismoniy shaxslarni malaka imtihonidan o‘tkazish
                                            bo‘yicha komissiya kotibi X.Axmedov:
                                            ushbu bayonning 1-ilovada keltirilgan ro‘yxatdagi jismoniy shaxslarga malaka imtihonini o‘tkazish sanasi, vaqti va joyi haqida imtihon
                                            o‘tkaziladigan kundan kamida o‘n kun oldin mobil telefoni (mavjud bo‘lganda), pochta yoki elektron aloqa vositasi orqali xabardor qilinishini ta’minlasin;
                                            ushbu bayonning 2-ilovasida keltirilgan ro‘yxatdagi jismoniy shaxslarga malaka imtihonini topshirishga ruxsat berishni rad etilganligi
                                            va ularga rad etish sabablari ko‘rsatilgan holda, qabul qilingan qaror haqida uch ish kuni ichida yozma yoki elektron shaklda xabardor qilinishini ta’minlasin;
                                        </div>
                                        <div className="third-child" style={{textIndent:"35px", textAlign:"justify"}}>
                                            Mazkur bayonda belgilab berilgan topshiriqlar ijrosini nazorat qilish Davlat kadastrlari palatasi raisi M.Valiyev zimmasiga yuklatilsin.
                                        </div>
                                    </div>
                                    <div>
                                        <div className="subtitle" style={{margin: "20px 0"}}>
                                            <p style={{fontSize: "24px!important", fontWeight:"700"}}>Komissiya aʼzolari:</p>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <table className="no-border" style={{width:"100%"}}>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="first-child"  style={{fontWeight:"700"}}>{raisDeputy?.position}:</div>
                                                    </td>
                                                    <td width="30%" style={{textAlign: "center"}}>
                                                        {(raisDeputy?.examCommision.is_approved ==1)?(
                                                            <img style={{height:"125px"}} src={raisDeputy.qrCode}/>
                                                        ):""}
                                                    </td>
                                                    <td width="30%">
                                                        <div className="second-child">{raisDeputy?.fullName}</div>
                                                    </td>
                                                </tr>
                                                {protocolCommision.map((item, idx) => (
                                                    <tr key={idx}>
                                                        <td width="40%">
                                                            <div className="first-child"  style={{fontWeight:"700"}}>{item.position}:</div>
                                                        </td>
                                                        <td width="30%" style={{textAlign: "center"}}>
                                                            {(item.examCommision.is_approved ==1)?(
                                                                <img style={{height:"125px"}} src={item.qrCode}/>
                                                            ):""}

                                                        </td>
                                                        <td width="30%">
                                                            <div className="second-child">{item.fullName}</div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </article>
                        <div className="heading" style={{marginTop:"30px"}}>
                            <table className="no-border">
                                <tbody>
                                    <tr>
                                        <td width="32%">

                                        </td>
                                        <td width="32%">

                                        </td>
                                        <td width="36%">
                                            <p  style={{fontWeight:"700", textAlign:"center"}}>Ko‘chmas mulk bo‘yicha kadastr
                                                <br/>
                                                muhandisi malaka shahodatnomasini olishga
                                                <br/>
                                                talabgor bo‘lgan jismoniy shaxslarni malaka
                                                <br/>
                                                imtihonidan  o‘tkazish bo‘yicha komissiyasining
                                                <br/>
                                                {protocolItem.fullYear} dagi {protocolItem.number}-sonli
                                                <br/>
                                                bayoniga 1-ilova
                                                <br/>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                        <div className="title">
                            <p className="filial-text text-center" style={{fontWeight: "700", marginTop:"20px"}} >
                                Kadastr muhandisi malaka shahodatnomasini olishga talabgor bo‘lgan jismoniy shaxslarga malaka imtihonini topshirishga ruxsat etilganlar
                            </p>
                            <div className="subtitle" style={{marginTop: "10px", textAlign:"center"}}>
                                <p style={{fontWeight: "700"}}>RO‘YXATI</p>
                            </div>
                        </div>
                        <article>
                        <div className="container">
                            <div className="text-main">
                                <div className="item">
                                    <table style={{fontSize: "20px!important"}}>
                                        <thead>
                                            <tr style={{border: "1px solid #000", lineHeight:"20px"}}>
                                                <td rowSpan='2' width="5%" style={{border: "1px solid #000"}}>№</td>
                                                <td rowSpan='2' width="20%" style={{border: "1px solid #000"}}>Talabgorning F.I.SH</td>
                                                <td rowSpan='2' width="10%" style={{border: "1px solid #000"}}>Sohadagi mehnat staji</td>
                                                <td rowSpan='2' width="20%" style={{border: "1px solid #000"}}>Ta’lim olgan muassasa nomi</td>
                                                <td rowSpan='2' width="5%" style={{border: "1px solid #000"}}>Mutaxassisligi</td>
                                                <td rowSpan='2' width="5%" style={{border: "1px solid #000"}}>Ма’lumoti</td>
                                                <td colSpan='2' width="35%" style={{border: "1px solid #000"}}>Malaka oshirish o‘quv kurslarida qatnashganligi </td>
                                            </tr>
                                            <tr style={{border: "1px solid #000"}}>
                                                <td width="" style={{border: "1px solid #000"}}>Yakunlagan sanasi</td>
                                                <td width="" style={{border: "1px solid #000"}}>Sertifikat raqami</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {notRejectedStudents.map((item, idx) => (
                                                <tr key={idx}>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                        {item.len}
                                                    </td>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                        {item.fullName}
                                                    </td>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                        {item.taskData.experience}
                                                    </td>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                        {item.eduName}
                                                    </td>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                        {item.eduSpec}
                                                    </td>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                        Oliy
                                                    </td>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                        {item.certificateDate}
                                                    </td>
                                                    <td width="5%" style={{border: "1px solid #000"}}>
                                                        {item.certificateNumber}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </article>
                        <div className="heading">
                                <table className="no-border" style={{marginTop:"20px"}}>
                                    <tbody>
                                        <tr>
                                            <td width="32%">

                                            </td>
                                            <td width="32%">

                                            </td>
                                            <td width="36%">
                                                <p style={{fontWeight:"700", textAlign:"center"}}>Ko‘chmas mulk bo‘yicha kadastr
                                                    <br/>
                                                    muhandisi malaka shahodatnomasini olishga
                                                    <br/>
                                                    talabgor bo‘lgan jismoniy shaxslarni malaka
                                                    <br/>
                                                    imtihonidan  o‘tkazish bo‘yicha komissiyasining
                                                    <br/>
                                                    {protocolItem.fullYear} dagi {protocolItem.number}-sonli
                                                    <br/>
                                                    bayoniga 2-ilova
                                                    <br/>
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        <div className="title">
                            <p className="filial-text text-center" style={{fontWeight: "700", marginTop:"20px"}} >
                                Kadastr muhandisi malaka shahodatnomasini olishga talabgor bo‘lgan jismoniy
                                shaxslarning malaka imtihonini topshirishga ruxsat etilmaganlar  (rad etilgan)
                            </p>
                            <div className="subtitle" style={{marginTop: "10px", fontWeight:"700", textAlign:"center"}}>
                                <p style={{fontWeight: "700"}}>RO‘YXATI</p>
                            </div>
                        </div>
                        <article>
                            <div className="container">
                                <div className="text-main">
                                    <div className="item">
                                        <table style={{fontSize: "20px!important"}}>
                                            <thead>
                                                <tr className="s-td" style={{border: "1px solid #000", lineHeight:"20px"}}>
                                                    <td width="5%" style={{border: "1px solid #000"}}>№</td>
                                                    <td width="20%" style={{border: "1px solid #000"}}>Talabgorning F.I.SH</td>
                                                    <td width="10%" style={{border: "1px solid #000"}}>Sohadagi mehnat staji</td>
                                                    <td width="5%" style={{border: "1px solid #000"}}>Mutaxassisligi</td>
                                                    <td width="5%" style={{border: "1px solid #000"}}>Ма’lumoti</td>
                                                    <td width="35%" style={{border: "1px solid #000"}}>Vazirlar Mahkamasining 2020-yil 2-sentabrdagi 535-son
                                                        qarori 5v-ilovasini 13-bandiga binoan</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rejectedStudents.map((item, idx) => (
                                                    <tr key={idx}>
                                                        <td style={{border: "1px solid #000"}}>
                                                            {item.len}
                                                        </td>
                                                        <td style={{border: "1px solid #000"}}>
                                                            {item.fullName}
                                                        </td>
                                                        <td style={{border: "1px solid #000"}}>
                                                            {item.taskData.experience}
                                                        </td>
                                                        <td style={{border: "1px solid #000"}}>
                                                            {item.eduSpec}
                                                        </td>
                                                        <td style={{border: "1px solid #000"}}>
                                                            Oliy
                                                        </td>
                                                        <td style={{border: "1px solid #000"}}>
                                                            {(item.generalStatus.key === "dxm-rejected")?(
                                                                <div>{item.generalStatus.name}</div>
                                                            ):""}
                                                            {(item.generalStatus.key === "rejected")?(
                                                                <div>Vazirlar Mahkamasining 2020-yil 2-sentabrdagi 535-son qarori 5v-ilovasini 13-bandiga binoan</div>
                                                            ):""}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>

                </Body>
            ):('')}
            {!protocolItemLoad ? (
                <Footer>
                    <Button variant="secondary" onClick={handleCloseModalView}>
                        Chiqish
                    </Button>
                    {protocolItem.generalStatus.key == 'new'?(
                        <Button variant="primary" onClick={handleSendToApprove}>
                            Protokol ni Tasdiqlashga yuborish
                        </Button>
                    ):('')}
                    {protocolItem.localStatus.key == 'waiting-approved'?(
                        <Button variant="primary" onClick={handleApprove}>
                            Tasdiqlash
                        </Button>
                    ):('')}
                </Footer>
            ):('')}



        </Modal>
    );
}

export default ModalProtocolView;
