import React, { useState } from "react";
import { useEffect } from "react";
import {Form, Modal, Button, Table} from "react-bootstrap";
import fetchApi from "utils/fetchApi";
import { toast } from "react-toastify";

function ModalProtocol({
                        protocolEdit,
                        modalProtocol,
                        currentPage,
                        handleCloseProtocolModel,
                        setProtocolLoading,
                        getProtocols,
                    }) {
    // components
    const { Control } = Form;
    const { Header, Title, Body, Footer } = Modal;

    // states
    const [protocolName, setProtocolName] = useState("");
    const [protocolNumber, setProtocolNumber] = useState(0);
    // handler function
    const handleChangeProtocolName = (e) => setProtocolName(e.target.value);
    const handleChangeProtocolNumber = (e) => setProtocolNumber(e.target.value);
    // server function
    const addProtocol = (e) => {
        const data = { name: protocolName, number: protocolNumber };
        const { id } = protocolEdit;
        const url = id ? `/protocol/${protocolEdit.id}/update` : "/protocol/store";

        fetchApi
            .post(url, data)
            .then((res) => {
                setProtocolLoading(true);
                setProtocolName("");
                getProtocols(currentPage);
                // setSelectedCommissions([]);
                // setProtocolFile(null);
                toast.success(res.data.message);
            })
            .catch((err) => {
                toast.error(err.data.message);
            });
        handleCloseProtocolModel();
    };

    useEffect(() => {
        setProtocolName(protocolEdit?.name);
        setProtocolNumber(protocolEdit?.number);
        // getProtocolCommissions();
    }, [protocolEdit]);
    return (
        <Modal show={modalProtocol} onHide={handleCloseProtocolModel}>
            <Header closeButton>
                <Title>{protocolEdit.id ? "Protokolni taxrirlash" : "Protokol qo'shish"}</Title>
            </Header>
            <Form>
                <Body className="space-y-3">
                    <Control
                        value={protocolName}
                        onChange={handleChangeProtocolName}
                        type="text"
                        placeholder="Protokol nomi"
                    />
                    <Control
                        value={protocolNumber}
                        onChange={handleChangeProtocolNumber}
                        type="number"
                        placeholder="Protokol raqami"
                    />

                    {/*<Control*/}
                    {/*    onChange={handleChangeProtocolFile}*/}
                    {/*    type="file"*/}
                    {/*    placeholder="Bayonnoma buyrug'i"*/}
                    {/*/>*/}
                    {/*<Table striped bordered responsive hover size="sm">*/}
                    {/*        <thead>*/}
                    {/*        <tr >*/}
                    {/*            <th className="border-0">*/}
                    {/*                Harakatlar*/}
                    {/*            </th>*/}
                    {/*            <th className="border-0">*/}
                    {/*                F.I.O*/}
                    {/*            </th>*/}
                    {/*        </tr>*/}
                    {/*        </thead>*/}
                    {/*        <tbody>*/}
                    {/*        {examCommissions.map((examCommission, index) => (*/}
                    {/*            <tr key={index}>*/}
                    {/*                <td>*/}
                    {/*                    <div className="flex space-x-2">*/}
                    {/*                        <input*/}
                    {/*                            className="hover:cursor-pointer"*/}
                    {/*                            id={`label-answer-${index}`}*/}
                    {/*                            onChange={(e) => handleCheckedCommission(e, examCommission)}*/}
                    {/*                            type="checkbox"*/}
                    {/*                            name="commision"*/}
                    {/*                            checked={selectedCommissions.includes(examCommission.id)}*/}
                    {/*                            value={examCommission.id}*/}
                    {/*                        />*/}
                    {/*                    </div>*/}
                    {/*                </td>*/}
                    {/*                <td>*/}
                    {/*                    {examCommission.firstname + " " + examCommission.lastname + " " + examCommission.middlename}*/}
                    {/*                </td>*/}
                    {/*            </tr>*/}
                    {/*        ))}*/}
                    {/*        </tbody>*/}
                    {/*    </Table>*/}

                </Body>
                <Footer>
                    <Button variant="secondary" onClick={handleCloseProtocolModel}>
                        Bekor qilish
                    </Button>
                    <Button variant="primary" onClick={addProtocol}>
                        {protocolEdit.id ? "Saqlash" : "Qo'shish"}
                    </Button>
                </Footer>
            </Form>

        </Modal>
    );
}

export default ModalProtocol;
