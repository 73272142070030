import React from "react";
import { Modal, Button } from "react-bootstrap";
import fetchApi from "utils/fetchApi";
import { toast } from "react-toastify";

const ModalDelete = ({ url, modalDelete, handleCloseDeleteModel, getDataRetry, currentPage}) => {
  const { Header, Title, Body } = Modal;

  const handleDelete = () => {
    fetchApi
      .delete(url)
      .then((res) => {
        handleCloseDeleteModel();
        getDataRetry(currentPage);
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.data.message);
      });
  };

  return (
    <Modal show={modalDelete} onHide={handleCloseDeleteModel}>
      <Header closeButton>
        <Title>O'chirish</Title>
      </Header>
      <Body className="flex justify-between space-x-3">
        <Button variant="secondary" onClick={handleCloseDeleteModel}>
          Yo'q
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Ha
        </Button>
      </Body>
    </Modal>
  );
};

export default ModalDelete;
