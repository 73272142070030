import React, {useState, useEffect} from "react";
import {Card, Container} from "react-bootstrap";
import TableProtocol from "components/Protocol/TableProtocol";
import ModalProtocolComponent from "components/Protocol/ModalProtocol";
import AddButton from "components/FormComponents/AddButton";
import fetchApi from "utils/fetchApi";
import Pagination from "components/Pagination/Pagination";
import ModalDelete from "components/models/ModalDelete";
import ModalProtocolView from "components/Protocol/ModalProtocolView";
import {all} from "axios";
import {useSelector} from "react-redux";

const Protocol = () => {

    const {user} = useSelector(state => state);

    // components
    const {Header, Body} = Card;
    // states
    const [modalProtocol, setModalProtocol] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [protocolItems, setProtocolItems] = useState([]);
    const [protocolEdit, setProtocolEdit] = useState({name: "", id: "", number: "", commision: []});

    const [protocolItem, setProtocolItem] = useState("");
    const [protocolItemLoad, setProtocolItemLoad] = useState(true);
    const [protocolCommision, setProtocolCommision] = useState([]);
    const [protocolRais, setProtocolRais] = useState("");
    const [protocolRaisDeputy, setProtocolRaisDeputy] = useState("");
    const [protcolAsCommision, setProtocolAsCommision] = useState("");
    const [allStudents, setAllStudents] = useState([]);
    const [rejectedStudents, setRejStudents] = useState([]);
    const [notRejStudents, setNotRejStudents] = useState([]);
    const [modalView, setModalView] = useState(false);

    const [protocolId, setProtocolId] = useState("");
    const [protocolLoading, setProtocolLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [searchName, setSearchName] = useState("");
    const [allItem, setAllItem] = useState(0);
    const limitData = 10;
    // modal function
    const handleOpenProtocolModel = () => setModalProtocol(true);
    const handleCloseProtocolModel = () => {
        setModalProtocol(false);
        setProtocolEdit({name: "", id: "", number: "", commision: []});
    };
    const handleOpenDeleteModel = (id) => {
        setModalDelete(true);
        setProtocolId(id);
    };
    const handleCloseDeleteModel = () => setModalDelete(false);
    const handleOpenModalView = (id) => {
        fetchApi.get(`/protocol/${id}/view`).then((res) => {
            const {
                protocol,
                commision,
                rais,
                raisDeputy,
                asCommision,
                allStudents,
                rejectedStudents,
                notRejectedStudents
            } = res.data;
            setProtocolCommision(commision);
            setProtocolItem(protocol);
            setProtocolRais(rais);
            setProtocolRaisDeputy(raisDeputy);
            setAllStudents(allStudents);
            setProtocolAsCommision(asCommision);
            setRejStudents(rejectedStudents);
            setNotRejStudents(notRejectedStudents);
            setProtocolItemLoad(false);
        })
        setModalView(true);

    }

    const handleProtocolGetPdf = (id) => {
        fetchApi.get(`/protocol/${id}/get-url`).then((res) => {
            const link = document.createElement("a");
            console.log(res);
            link.href = res.data;
            link.download = res.data;
            link.click()
        })
    }
    const handleCloseModalView = () => {
        setModalView(false);
        setProtocolItem("");
        setProtocolCommision([]);
        setProtocolItemLoad(true);
    }
    // server function
    const getProtocols = (page = 1, limit = limitData, name) => {
        fetchApi
            .get("/protocols", {
                params: {
                    page,
                    limit,
                    name,
                },
            })
            .then((res) => {
                const {protocols, pages, items} = res.data;
                // console.log(protocols)
                setProtocolItems(protocols);
                setPages(pages);
                setProtocolLoading(false);
                setAllItem(items)
            });
    };

    const handleProtocolEdit = (id) => {
        fetchApi.get(`/protocol/${id}/view`).then((res) => {
            const {protocol} = res.data;
            setProtocolEdit(protocol);
            handleOpenProtocolModel();
        });
    };

    // Lifecycle
    useEffect(() => {
        getProtocols(currentPage, limitData, searchName);
    }, [currentPage, searchName]);
    return (
        <div className="selector-group">
            <ModalProtocolComponent
                setProtocolLoading={setProtocolLoading}
                modalProtocol={modalProtocol}
                protocolEdit={protocolEdit}
                getProtocols={getProtocols}
                currentPage={currentPage}
                handleCloseProtocolModel={handleCloseProtocolModel}
            />
            <ModalDelete
                url={`/protocol/${protocolId}/delete`}
                modalDelete={modalDelete}
                handleCloseDeleteModel={handleCloseDeleteModel}
                getDataRetry={getProtocols}
                currentPage={currentPage}
            />
            <ModalProtocolView
                protocolItem={protocolItem}
                modalView={modalView}
                handleCloseModalView={handleCloseModalView}
                protocolCommision={protocolCommision}
                setProtocolItemLoad={setProtocolItemLoad}
                protocolItemLoad={protocolItemLoad}
                rais={protocolRais}
                raisDeputy={protocolRaisDeputy}
                asCommision={protcolAsCommision}
                allStudents={allStudents}
                notRejectedStudents={notRejStudents}
                rejectedStudents={rejectedStudents}
            />
            <Container fluid>
                <div className="flex w-full justify-end mb-2">
                    {["manager", "superadmin"].includes(user.role.shortName) ? (
                        <AddButton onClick={handleOpenProtocolModel}/>
                    ) : (
                        <div></div>
                    )}

                </div>
                <Card className="card-plain table-plain-bg bg-white  rounded">
                    <Header className="w-full">
                        <input
                            className="form-control md:w-full lg:w-1/3 bg-slate-50	"
                            placeholder="Search name"
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                        />
                    </Header>
                    <Body>
                        {protocolLoading ? (
                            <h1>Yuklanmoqda...</h1>
                        ) : (
                            <TableProtocol
                                currentPage={currentPage}
                                limitData={limitData}
                                protocols={protocolItems}
                                handleProtocolEdit={handleProtocolEdit}
                                handleOpenDeleteModel={handleOpenDeleteModel}
                                handleOpenModalView={handleOpenModalView}
                                handleCloseModalView={handleCloseModalView}
                                handleProtocolGetPdf={handleProtocolGetPdf}
                            />
                        )}
                    </Body>
                </Card>
                <Pagination
                    nPages={pages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    allItem={allItem}
                />
            </Container>
        </div>
    );
};

export default Protocol;
