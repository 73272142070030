import React, {useEffect, useState} from "react";
import { Card, Container, Row, Col, Table } from "react-bootstrap";
import { RiDeleteBin6Line } from "react-icons/ri";
import { students } from "server";
import fetchApi from "../utils/fetchApi";
import {Link} from "react-router-dom";
import {FaEye} from "react-icons/fa";
import Pagination from "../components/Pagination/Pagination";
import format from "../utils/moment";
import {getLocalStorge, setLocalStorge} from "../utils/localStorge";

const Students = () => {


  let localFilter = getLocalStorge('filterstudent');
  if(!localFilter){
    localFilter = {
      createdAt:'',
      page: 1,
      limit: 10,
      name: '',
      fio: '',
    }
  }

  //states
  const [students, setStudents] = useState([]);
  const [loadingStudents, setLoadingStudents] = useState(true);
  const [allItem, setAllItem] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(3);
  const [pages, setPages] = useState(1);
  const [filterExam, setTaskFilter] = useState(localFilter);

  //functions
  const getStudents = () => {
      fetchApi.get("/students", {
        params: filterExam
      }).then((res)=>{
        // console.log(res.data)
        setStudents(res.data.students);
        setAllItem(res.data.items);
        setPages(res.data.pages)
        setLoadingStudents(false);
      })
  }

  useEffect(() => {
    setLocalStorge('filterexam', filterExam);
    getStudents();
  }, [filterExam]);
  useEffect(() => {
    setTaskFilter({...filterExam,page:currentPage});
  }, [currentPage])
  const handlerSearchByFio = (e) => {
    let fio = e.target.value
    setTaskFilter({...filterExam,fio: fio});
  }
  const handlerSearchByPassport = (e) => {
    let passport = e.target.value
    setTaskFilter({...filterExam,passport});
  }

  return (
    <div className="selector-group">
      {
        loadingStudents ? <h1>Yuklanmoqda....</h1>:<Container fluid>
          <Row>
            <Col md="12">
              <Card className="card-plain table-plain-bg bg-white  rounded">
                <Card.Header className="w-full">
                  <Row>
                    <Col md="3">
                      <input
                          type="text"
                          className="form-control md:w-full  bg-slate-50	"
                          placeholder="F.I.O"
                          onChange={handlerSearchByFio}
                          value={filterExam.fio}
                      />
                    </Col>
                    <Col md="3">
                      <input
                          type="text"
                          className="form-control md:w-full  bg-slate-50	"
                          placeholder="PASSPORT"
                          onChange={handlerSearchByPassport}
                          value={filterExam.passport}
                      />
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table striped bordered responsive hover size="sm">
                    <thead>
                    <tr>
                      <th className="border-0">№</th>
                      <th className="border-0">Foydalanuvchi nomi</th>
                      <th className="border-0">Foydalanuvchi FIO</th>
                      <th className="border-0">Passport</th>
                      <th className="border-0">Email</th>
                      <th className="border-0">Telefon nomeri</th>
                      <th className="border-0">Guruh</th>
                      <th className="border-0">Oxirgi o'zgartirilgan sana</th>
                      <th className="border-0">Harakatlar</th>
                    </tr>
                    </thead>
                    <tbody>
                    {students.map((item, idx) => (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td>{item.username}</td>
                          <td>{item.firstname + " " + item.lastname}</td>
                          <td>{item.passport}</td>
                          <td>{item.email}</td>
                          <td>{item.phone}</td>
                          <td>{item.group?.name}</td>
                          <td>{format.formatDate(item.updatedAt)}</td>
                          <td className="flex space-x-2 full-height align-center justify-center">
                            <Link
                                to={`/admin/user/view/${item.id}`}
                                className="btn btn-sm btn-success">
                              <FaEye />
                            </Link>
                            <div
                                className="btn btn-sm btn-danger"
                                onClick={() => handleOpenDeleteModel(item.id)}>
                              <RiDeleteBin6Line />
                            </div>
                          </td>
                        </tr>
                    ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
              <Pagination
                  nPages = { pages }
                  currentPage = { currentPage }
                  setCurrentPage = { setCurrentPage }
                  allItem={allItem}
              />
            </Col>
          </Row>
        </Container>
      }
    </div>
  );
};

export default Students;
