import React from "react";
import { RiDeleteBin6Line} from "react-icons/ri";
import { FaEdit, FaEye, FaDownload } from "react-icons/fa";
import { Table } from "react-bootstrap";
import limitCount from "utils/limitCount";
import format from "utils/moment";
import {Link} from "react-router-dom";

const TableProtocol = (
    {
        protocols,
        handleProtocolEdit,
        handleOpenDeleteModel,
        currentPage,
        limitData,
        handleOpenModalView,
        handleCloseModalView,
        handleProtocolGetPdf
    }
) => {
    return (
        <Table striped bordered responsive hover size="sm">
            <thead>
            <tr>
                <th className="border-0">ID</th>
                <th className="border-0">Nomi</th>
                <th className="border-0">Holati</th>
                <th className="border-0">Yaratilgan sana</th>
                <th className="border-0">Harakatlar</th>
            </tr>
            </thead>
            <tbody>
            {protocols.map((item, idx) => (
                <tr key={item.id}>
                    <td>{limitCount(limitData, currentPage, idx)}</td>
                    <td>{item.name}</td>
                    <td>{item.generalStatus.name}</td>
                    <td>{format.formatDate(item.createdAt)}</td>
                    <td className="flex space-x-2 full-height align-center justify-center">
                        {/*<div*/}
                        {/*    className="btn btn-sm btn-primary"*/}
                        {/*    onClick={() => handleOpenModalView(item.id)}>*/}
                        {/*    <FaEye />*/}
                        {/*</div>*/}
                        <Link
                            to={`protocol/${item.id}/view`}
                            className="btn btn-sm btn-primary">
                            <FaEye/>
                        </Link>
                        <div
                            className="btn btn-sm btn-success"
                            onClick={() => handleProtocolEdit(item.id)}>
                            <FaEdit />
                        </div>
                        <div
                            className="btn btn-sm btn-danger"
                            onClick={() => handleOpenDeleteModel(item.id)}>
                            <RiDeleteBin6Line />
                        </div>
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
    );
};

export default TableProtocol;
