import React, { useState } from "react";
import { useEffect } from "react";
import {Form, Modal, Button, FormCheck, Row, Col} from "react-bootstrap";
import fetchApi from "utils/fetchApi";
import { toast } from "react-toastify";

function ChangeProtocolModal (
    {
        taskItem,
        modalAcceptTask,
        handleCloseChangeProtocolModal,
        setTaskLoading,
        setTaskItem,
        protocols
    }
){
    // console.log(taskItem)
    const { Header, Title, Body, Footer } = Modal;
    const [selectedProtocol, setSelectedProtocol] = useState('');

    const handleOnChangeSelectProtocol = (e) => {
        // console.log(e.target.value)
        setSelectedProtocol(e.target.value);
    }

    // server function
    const changeProtocol = () => {
        const data = {
            protocolId: selectedProtocol,
        };
        setTaskLoading(true);
        fetchApi.post(`task/${taskItem.id}/change-protocol`, data).then((res) => {
            toast.success(res.data.message);
            setTaskItem(res.data.task);
            setTaskLoading(false);
        }).catch((err) => {
            toast.error(err);
        });
        setTaskLoading(false);
        handleCloseChangeProtocolModal();
    };

    useEffect(() => {
        if (taskItem.protocolId){
            setSelectedProtocol(taskItem.protocolId)
        }
    }, []);
    return (
        <Modal show={modalAcceptTask} onHide={handleCloseChangeProtocolModal}>
            <Header closeButton>
                <Title>Bayonnomani o'zgartirish</Title>
            </Header>
            <Body>
                <Row>
                    <Col md="12">
                        <select
                            value={selectedProtocol}
                            className="p-2 form-control w-full  bg-slate-50"
                            onChange={handleOnChangeSelectProtocol}>
                            <option disabled value="">
                                Bayonnomani tanlang...
                            </option>
                            {protocols.map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
            </Body>
            <Footer>
                <Button variant="secondary" onClick={handleCloseChangeProtocolModal}>
                    Bekor qilish
                </Button>
                <Button variant="success" onClick={changeProtocol}>
                    Yuborish
                </Button>
            </Footer>
        </Modal>
    );
}

export default ChangeProtocolModal;