import React from "react";
import { FaEye, FaPlay } from "react-icons/fa";
import { Table } from "react-bootstrap";
import limitCount from "utils/limitCount";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const TableResult = ({result}) => {
const parse = require("html-react-parser");
    return (
        <Table striped bordered responsive hover size="sm">
        <thead>
            <tr>
            <th className="border-0">#</th>
            <th className="border-0">Savol</th>
            <th className="border-0">Ball</th>
            <th className="border-0">Javoblar</th>
            <th className="border-0">Ball</th>
            </tr>
        </thead>
        <tbody>
            {result.map((item, idx) => (
            <tr key={idx}>
                <td>{idx + 1}</td>
                <td className="px-1">{parse(item.question.name)}</td>
                <td className="px-1">{item.question.point}</td>
                <td className="px-1">
                    <ul>
                        {item.question.answer.map((a, key) => (
                            <li key={key} className={`mb-1 pl-1 rounded-sm ${item?.isTrue && a?.id == item?.checkedId && "bg-green-500"} ${!item?.isTrue && a?.id == item?.checkedId && "bg-red-500" } ` }>
                                {key+1 + ". "} {parse(a.name)}
                            </li>
                        ))}
                    </ul>
                </td>
                <td className="px-1">{item.isTrue? item.question.point : 0}</td>
                <td>
                    
                </td>
            </tr>
            ))}
        </tbody>
        </Table>
  );
};

export default TableResult;
