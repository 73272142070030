import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { Table } from "react-bootstrap";
import limitCount from "utils/limitCount";
import format from "../../utils/moment";

const TableGroup = ({
  groups,
  handleGroupEdit,
  handleOpenDeleteModel,
  currentPage,
  limitData,
}) => {
  return (
    <Table striped bordered responsive hover size="sm">
      <thead>
        <tr>
          <th className="border-0">ID</th>
          <th className="border-0">Nomi</th>
          <th className="border-0">Holati</th>
          <th className="border-0">Arizalar soni</th>
          <th className="border-0">Kiritilgan sana</th>
          <th className="border-0">Harakatlar</th>
        </tr>
      </thead>
      <tbody>
        {groups.map((item, idx) => (
          <tr key={item.id}>
            <td>{limitCount(limitData, currentPage, idx)}</td>
            <td>{item.name}</td>
            <td>{item.status}</td>
            <td>{item.tasks.length}</td>
              <td>{format.formatDate(item.createdAt)}</td>
              <td className="flex space-x-2 full-height align-center justify-center">
              <div
                className="btn btn-sm btn-success"
                onClick={() => handleGroupEdit(item.id)}>
                <FaEdit />
              </div>
              <div
                className="btn btn-sm btn-danger"
                onClick={() => handleOpenDeleteModel(item.id)}>
                <RiDeleteBin6Line />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TableGroup;
