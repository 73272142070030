import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { RiDeleteBin6Line } from "react-icons/ri";
import ModalDelete from "../components/models/ModalDelete";
import Pagination from "../components/Pagination/Pagination";
import fetchApi from "../utils/fetchApi";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";

const UsersList = () => {
  // states
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState(null);

  //pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [pages, setPages] = useState(1);
  const [allItem, setAllItem] = useState(0);

  // modal states
  const [modalDelete, setModalDelete] = useState(false);

  // handler function
  const handleOpenDeleteModel = (id) => {
    setModalDelete(true);
    setUserId(id);
  };
  const handleCloseDeleteModel = () => setModalDelete(false);

  // get function
  const getUsers = (page = null, limit = recordsPerPage) => {
    fetchApi
      .get("/users", {
        params: {
          page,
          limit,
        },
      })
      .then((res) => {
        const { users, pages, items } = res.data;
        // console.log("Users:", users)
        setUsers(users);
        setPages(pages);
        setAllItem(items)
        setLoadingUsers(true);
      });
  };

  // Effect Hook
  useEffect(() => {
    getUsers(currentPage, recordsPerPage);
  }, [currentPage]);

  return (
    <div className="selector-group">
      <Container fluid>
        <Row>
          <Col md="12">
            <div className="w-full lg:mb-5 md:mb-2 ">
              <input
                className="form-control "
                placeholder="Nomi bilan qidirish"
              />
            </div>
            <Card className="card-plain table-plain-bg bg-white  rounded">
              <Card.Body className="table-full-width table-responsive px-0">
                <Table striped bordered responsive hover size="sm">
                  <thead>
                    <tr>
                      <th className="border-0">№</th>
                      <th className="border-0">Foydalanuvchi nomi</th>
                      <th className="border-0">F.I.SH</th>
                      <th className="border-0">Email</th>
                      <th className="border-0">Lavozimi</th>
                      <th className="border-0">Harakatlar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loadingUsers ? (
                      <tr>
                        <td>Loading ...</td>
                      </tr>
                    ) : (
                      users.map((item, idx) => (
                        <tr key={item.id}>
                          <td>{idx + 1}</td>
                          <td>{item.username}</td>
                          <td>{item.firstname + " " + item.lastname}</td>
                          <td>{item.email}</td>
                          <td>
                            {item.role !== null ? item.role.name : "Guest" + ""}
                          </td>
                          <td className="flex space-x-2 full-height align-center justify-center">
                            <Link
                              to={`/admin/user/view/${item.id}`}
                              className="btn btn-sm btn-success">
                              <FaEye />
                            </Link>
                            <div
                              className="btn btn-sm btn-danger"
                              onClick={() => handleOpenDeleteModel(item.id)}>
                              <RiDeleteBin6Line />
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <ModalDelete
        url={`/user/${userId}/delete`}
        modalDelete={modalDelete}
        handleCloseDeleteModel={handleCloseDeleteModel}
        getDataRetry={getUsers}
        currentPage={currentPage}
      />
      <Pagination
        nPages={pages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        allItem={allItem}
      />
    </div>
  );
};

export default UsersList;
