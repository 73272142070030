import axios from "axios";

const {REACT_APP_BASE_URL, REACT_APP_LOCAL_URL, REACT_APP_ENV} = process.env;

export const BASE_URL =
    REACT_APP_ENV === "production" ? REACT_APP_BASE_URL : REACT_APP_LOCAL_URL;

axios.defaults.baseURL = BASE_URL;
axios.interceptors.request.use(
    (config) => {
        const userToken = JSON.parse(window.localStorage.getItem("user_token"));
        const token = userToken ? userToken.accessToken : undefined;
        config.headers = {
            "x-access-token": token,
        };

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalConfig = error.config;
        const responseStatus = error.response.status;

        if (responseStatus === 401) {
            window.localStorage.removeItem("user_token");
            window.localStorage.removeItem("filtertask");
            return Promise.reject(error);
        }

        const userToken = JSON.parse(window.localStorage.getItem("user_token"));
        const refreshToken = userToken ? userToken.refreshToken : null;

        try {
            if (responseStatus === 403 && !originalConfig._retry) {
                originalConfig._retry = true;
                console.log(originalConfig);
                const result = await fetch(`${BASE_URL}/refresh-token`, {
                    method: "GET",
                    headers: {
                        "x-refresh-token": refreshToken,
                    },
                });
                if (result.status === 401) {
                    window.localStorage.removeItem("user_token");
                    window.localStorage.removeItem("filtertask");
                    window.location.reload();
                }
                const response = await result.json();
                const {accessToken} = response;
                window.localStorage.setItem(
                    "user_token",
                    JSON.stringify({...userToken, accessToken})
                );
                if (originalConfig.data)
                    originalConfig.data = JSON.parse(originalConfig.data)

                return axios(originalConfig);
            }
        } catch (err) {
            return Promise.reject(err);
        }

        return Promise.reject(error);
    }
);

export default axios;
