import React, {useState, useEffect} from "react";
import { Card, Container, Row, Col, Table } from "react-bootstrap";
import { RiDeleteBin6Line } from "react-icons/ri";
import { subjects } from "server";
import fetchApi from "../utils/fetchApi";
import Pagination from "../components/Pagination/Pagination";
import {FaEdit} from "react-icons/fa";
import ModalDelete from "components/models/ModalDelete";
import SubjectUpdateModal from "components/Subject/SubjectUpdateModal";
import {toast} from "react-toastify";
import format from "../utils/moment";
import PaginationNew from "../components/Pagination/PaginationNew";



const Subjects = () => {
  // states
  const [subject, setSubjectTitle] = useState("");
  const [count, setSubjectCount] = useState(0);
  const [subjectLoading, setSubjectLoading] = useState(true);
  const [subjectItems, setSubjectItems] = useState([]);
  const [subjectId, setSubjectId] = useState(null);
  //pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(3);
  const [pages, setPages] = useState(1);
  const [allItem, setAllItem] = useState(0);
  // modal states
  const [modalDelete, setModalDelete] = useState(false);

  // handler function
  const handleChangeTitle = (e) => {
    setSubjectTitle(e.target.value)
  };
  const handleChangeCount = (e) => {
    setSubjectCount(e.target.value)
  };
  const handleOpenDeleteModel = (id) => {
    setModalDelete(true);
    setSubjectId(id);
  };
  const handleCloseDeleteModel = () => setModalDelete(false);

  // add function
  function addSubject() {
    const data = { name: subject, count: count};
    fetchApi.post("/subject/store", data).then((res) => {
      // console.log(res.data);
      setSubjectLoading(true);
      setSubjectTitle("");
      setSubjectCount(0);
      getSubjects(currentPage, recordsPerPage);
      toast.success(res.data.message);
    });
  }

  // get function
  const getSubjects = (page = null, limit = recordsPerPage) => {
    fetchApi
        .get("/subjects", {
          params: {
            page,
            limit,
          },
        })
        .then((res) => {
          const { subjects, pages, items } = res.data;
          // console.log("Subjects:", subjects)
          setSubjectItems(subjects);
          setSubjectLoading(false);
          setPages(pages)
          setAllItem(items)
        });
  };

  // Effect Hook
  useEffect(() => {
    getSubjects(currentPage, recordsPerPage);
  }, [currentPage]);

  return (
    <div className="selector-group">
      <Container fluid>
        <Row>
          <Col md="12" lg="5">
            <div className="flex justify-between items-center px-4 py-2 bg-gray-200 rounded ">
              <div className="text-gray-500 text-lg">Yangi fan qo'shish</div>
              {/*<button className=" py-2 px-3 rounded bg-lime-500 font-bold text-white hover:bg-lime-600 transition">*/}
              {/*  + Add*/}
              {/*</button>*/}
            </div>
            <div className="form-group  bg-white px-4 py-2">
              {/*<label htmlFor="exampleInputEmail1 text-xs mb-0">Subject title</label>*/}
              <input
                type="text"
                className="form-control"
                placeholder="Fan nomi"
                value={subject}
                onChange={handleChangeTitle}
              />
              <input
                  type="number"
                  className="form-control mt-2"
                  placeholder="Savollar soni"
                  value={count}
                  onChange={handleChangeCount}
              />
              <div className="flex justify-end">
                <button className="mt-2 py-2 px-3 rounded bg-sky-500 font-bold text-white hover:bg-sky-600 transition" onClick={addSubject}>
                  Saqlash
                </button>
              </div>
            </div>
          </Col>
          <Col md="12" lg="7">
            <div className="w-full lg:mb-5 md:mb-2 ">
              <input className="form-control " placeholder="Nomi bilan qidirish" />
            </div>
            <Card className="card-plain table-plain-bg bg-white  rounded">
              <Card.Body className="table-full-width table-responsive px-0">
                <Table striped bordered responsive hover size="sm">
                  <thead>
                    <tr>
                      <th className="border-0">ID</th>
                      <th className="border-0">Nomi</th>
                      <th className="border-0">Savollar soni</th>
                      <th className="border-0">Kiritilgan sana</th>
                      <th className="border-0">Harakatlar</th>
                    </tr>
                  </thead>
                  <tbody>
                  {subjectLoading?<tr><td>Loading ...</td></tr>:subjectItems.map((item, idx) => (
                      <tr key={item.id}>
                        <td>{idx + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.count}</td>
                        <td>
                          {format.formatDate(item.createdAt)}
                        </td>
                        <td className="flex space-x-2 full-height align-center justify-center">
                          {/*<SubjectUpdateModal subject={item} setSubjectLoading={setSubjectLoading} getSubjects={getSubjects} currentPage={currentPage} />*/}
                          <div
                              className="btn btn-sm btn-danger"
                              onClick={() => handleOpenDeleteModel(item.id)}>
                            <RiDeleteBin6Line />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <Pagination
                nPages = { pages }
                currentPage = { currentPage }
                setCurrentPage = { setCurrentPage }
                allItem={allItem}
            />
          </Col>
        </Row>
      </Container>
      <ModalDelete
          url={`/subject/delete/${subjectId}`}
          modalDelete={modalDelete}
          handleCloseDeleteModel={handleCloseDeleteModel}
          getDataRetry={getSubjects}
          currentPage={currentPage}
      />

    </div>
  );
};

export default Subjects;
