import React from 'react'
import {useState} from 'react';
import { Container, Row, Col, Card, Form, Button, Table } from "react-bootstrap";
function SubjectCount({subject, handleShowCount}) {
    const [hiddenCount, setHiddenCount] = useState(true);
  return (
    <tr >
        <td >
            <div className="flex space-x-2">
                <input
                    className="hover:cursor-pointer"
                    onChange={(e) => {
                        setHiddenCount(!hiddenCount)
                        handleShowCount(subject, hiddenCount)
                    }}
                    type="checkbox"
                    name="commision"
                    // checked={selectedSubjects.includes(subject.id)}
                    value={subject.id}
                />
            </div>
        </td>
        <td>
            {subject.name}
        </td>
        <td width={"30%"} className='p-2'>
            {subject.count}
        </td>
    </tr>
  )
}

export default SubjectCount
