import React from "react";
import { FaEye, FaPlay } from "react-icons/fa";
import { Table } from "react-bootstrap";
import limitCount from "utils/limitCount";
import { Link } from "react-router-dom";

const TableExamResults = ({results}) => {
  return (
    <Table striped bordered responsive hover size="sm">
      <thead>
        <tr>
          <th className="border-0">#</th>
          <th className="border-0">Ariza</th>
            <th className="border-0">F.I.O</th>
          <th className="border-0">Imtihon sanasi</th>
          <th className="border-0">Imtihon natijasi</th>
          <th className="border-0">Ball</th>
        </tr>
      </thead>
      <tbody>
        {results.map((item, idx) => (
          <tr key={idx}>
            <td>{idx + 1}</td>
              <td className="px-1">
                  <Link
                      to={`task/${item.task.id}/view`}
                      className="">
                      {item.task.epiguId}
                  </Link>
              </td>
              {(item.userId != null)? (
                  <td className="px-1">{item?.user?.firstname + " " + item?.user?.lastname}</td>
              ):(
                  <td className="px-1">{item?.task?.fullName}</td>
              )}

            <td className="px-1">{item.exam.startDate}</td>
            <td className="px-1">{item.status == "fail"? ("O'tmadi"):(item.status == "pass"?("O'tdi"):("Kelmadi"))}</td>
            <td className="px-1">{item.point}</td>
            <td>
                {item.status != "notcome" ? (
                    <Link
                        to={`exam-result/${item.id}/view`}
                        className="btn btn-sm btn-primary">
                        <FaEye/>
                    </Link>
                ):("")}

            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TableExamResults;
