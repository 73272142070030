import React from "react";
import { useEffect, useState } from "react";
import {Modal, Button, Form, Row, Col} from "react-bootstrap";
import fetchApi from "utils/fetchApi";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";

const SubjectUpdateModal = ({
  subject,
  setSubjectLoading,
  getSubjects,
  currentPage,
}) => {
  //states
  const [show, setShow] = useState(false);
  const [newTitle, setNewTitle] = useState(subject.name);
  const [newCount, setNewCount] = useState(subject.count);

  //handlers
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChangeTitleSubject = (e) => {
    // console.log(e.target.value);
    setNewTitle(e.target.value);
  };

  const handleChangeCountSubject = (e) => {
    // console.log(e.target.value);
    setNewCount(e.target.value);
  };

  //functions
  const updateSubject = () => {
    const data = { name: newTitle, count:newCount };
    const url = `/subject/${subject.id}/update`;
    // console.log(url);
    fetchApi
      .post(url, data)
      .then((res) => {
        // console.log(res.data);
        setSubjectLoading(true);
        getSubjects(currentPage);
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.data.message);
      });
    handleClose();
  };

  return (
    <>
      <Button variant="primary" className="btn-sm" onClick={handleShow}>
        <FaEdit />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Fanni o'zgartirish</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <input
                  className="form-control"
                  value={newTitle}
                  onChange={handleChangeTitleSubject}
                  type="text"
                  placeholder="Fan nomi"
              />
            </Col>
            <Col md="12" className="mt-2">
              <input
                  className="form-control"
                  value={newCount}
                  onChange={handleChangeCountSubject}
                  type="number"
                  placeholder="Savollar soni"
              />
            </Col>
          </Row>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Chiqish
          </Button>
          <Button variant="primary" onClick={updateSubject}>
            O'zgarishlarni saqlash
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubjectUpdateModal;
