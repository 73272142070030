import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router";
import { Container, Row, Col, Card, Form, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import fetchApi from "utils/fetchApi";
import SubjectCount from "components/Exam/SubjectCount";
import { Label } from "reactstrap";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function ExamCrud() {
  const { Control, Select } = Form;
  const params = useParams();
  const history = useHistory();

  //states
  const [name, setExamName] = useState("");
  const [groups, setGroups] = useState([]);
  const [groupId, setGroupId] = useState("");
  const [passPercent, setPercent] = useState(70);
  const [duration, setDuration] = useState(60);
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [protocol, setSelectedProtocol] = useState("");
  const [protocols, setProtocols] = useState([]);

  //handle functions
  const handleChangeProtocol = (e) => setSelectedProtocol(e.target.value);
  const handleChangeGroup = (e) => setGroupId(e.target.value);
  const handleChangeName = (e) => setExamName(e.target.value);
  const handleChangePassPercent = (e) => setPercent(e.target.value);
  const handleChangeDuration = (e) => setDuration(e.target.value);
  const handleChangeStatus = (e) => setStatus(e.target.value);
  const handleChangeStartDate = (e) => {
    setStartDate(e);
  }
  //server
  const getGroup = () => {
    fetchApi.get("/exam/get-groups").then((res) => {
      const { groups } = res.data;
      setGroups(groups);
    });
  };
  const getExamProtocols = () => {
    fetchApi.get("/protocol/for-exam").then((res) => {
      const protocols = res.data;
      setProtocols(protocols);
    });
  };

  const addExam = () => {
    const data = {
      groupId,
      passPercent,
      duration,
      status,
      startDate: format(startDate, "yyyy-MM-dd HH:mm:ss"),
      name,
      protocol
    };
    const url = "/exam/store";
    fetchApi
      .post(url, data)
      .then((res) => {
        toast.success(res.data.message);
        setProtocols([]);
        setSelectedProtocol("");
        setGroupId("");
        setDuration("");
        setExamName("");
        setPercent("");
        setStartDate("");
        setStatus("");
        params?.id && history.push("/admin/exams");
      })
      .catch((err) => {
        toast.error(err.data.message);
      });
  };

  //useEffect
  useEffect(() => {
    getGroup();
    getExamProtocols();
  }, []);

  return (
    <div className="selector-group">
      <Container fluid>
        <Row>
          <Col xs={12}>
            <Row>
              <Col>
                <Label>Imtihon nomi</Label>
                <Control
                  value={name}
                  onChange={handleChangeName}
                  type="text"
                  placeholder="Imtihon nomi"
                />
              </Col>

              <Col>
                <Label>Imtihondan o'tish foizi</Label>
                <Control
                    readOnly={true}
                  value={passPercent}
                  onChange={handleChangePassPercent}
                  type="number"
                  placeholder="Imtihondan o'tish bali"
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Label>Imtihon kuni</Label>
                <div className="flex justify-start ">
                  <DatePicker
                    className="form-control"
                    selected={startDate}
                    onChange={handleChangeStartDate}
                    showTimeSelect
                    timeFormat="p"
                    dateFormat="Y-MM-dd, k:mm:ss"
                  />
                </div>
              </Col>
              <Col>
                <Label>Imtihon davomiyligi</Label>
                <Control
                    readOnly={true}
                  value={duration}
                  onChange={handleChangeDuration}
                  type="number"
                  placeholder="Imtihon davomiyligi"
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Label>Imtihon statusini tanlang</Label>
                <Select
                  value={status}
                  onChange={handleChangeStatus}
                  className="form-control">
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Select>
              </Col>
              <Col>
                <Label>Guruh tanlang</Label>
                <Select
                  value={groupId}
                  onChange={handleChangeGroup}
                  className="form-control">
                  <option disabled value="">
                    Guruh tanlang ...
                  </option>
                  {groups.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </Col>
              <Col>
                <Label>Bayonnomani tanlang</Label>
                <Select
                  value={protocol}
                  onChange={handleChangeProtocol}
                  className="form-control">
                  <option disabled value="">
                    Bayonnomani tanlang ...
                  </option>
                  {protocols.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name + "-" + item.number}
                    </option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={6}>
            <button
              type="button"
              onClick={addExam}
              className="rounded bg-blue-500 hover:bg-blue-600 text-slate-50 px-4 py-1 text-md ">
              {params.id ? "Saqlash" : "Qo'shish"}
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ExamCrud;
