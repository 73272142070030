import React from "react";
import { FaEye, FaPlay } from "react-icons/fa";
import { Table } from "react-bootstrap";
import format from "../../utils/moment";

const TableExamCommision = ({allCommision}) => {
    const parse = require("html-react-parser");
    return (
        <Table striped bordered responsive hover>
            <thead>
            <tr>
                <th className="border-0">#</th>
                <th className="border-0">F.I.O</th>
                <th className="border-0">Tasdiqlangan sana</th>
                <th className="border-0">Tasdiqlangan sana</th>
            </tr>
            </thead>
            <tbody>
            {allCommision.map((item, idx) => (
                <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td className="px-1">
                        {item.user.firstname + " " + item.user.lastname + " " + item.user.middlename}
                    </td>
                    <td className="px-1">
                        {item.first_approved_at?(format.formatDateTime(item.first_approved_at)):""}
                    </td>
                    <td className="px-1">
                        {item.second_approved_at?(format.formatDateTime(item.second_approved_at)):""}
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
    );
};

export default TableExamCommision;
