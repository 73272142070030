import React, { useState } from "react";
import { useEffect } from "react";
import {Form, Modal, Button, Table} from "react-bootstrap";
import fetchApi from "utils/fetchApi";
import { toast } from "react-toastify";

function ModalProtocolApprove({modalProtocol, protocol, handleCloseSendToApproveModal, getProtocol}) {
    // components
    const { Control } = Form;
    const { Header, Title, Body, Footer } = Modal;

    // states
    const [examCommissions, setExamCommissions] = useState([]);
    const [selectedCommissions, setSelectedCommissions] = useState([]);
    // const [protocolFile, setProtocolFile] = useState(null);

    // handler function

    // const handleChangeProtocolFile = (e) => {
    //     console.log(e.target.files[0]);
    //     setProtocolFile(e.target.files[0]);
    // }

    const handleCheckedCommission = (e) => {
        let commissionId = parseInt(e.target.value);
        if(selectedCommissions.includes(commissionId)){
            setSelectedCommissions(selectedCommissions.filter(i=>i!==commissionId))
        }else{
            setSelectedCommissions([...selectedCommissions, commissionId])
        }
    }

    const getProtocolCommissions = () => {
        fetchApi.get("/exam-commisions").then((res) => {
            const { users } = res.data;
            setExamCommissions(users);
        });
    }

    // server function
    const updateProtocol = (e) => {
        const formData = new FormData();
        // if(protocolFile){
        //     formData.append(
        //         'orderFile',
        //         protocolFile
        //     );
        // }
        // formData.append("selectedCommissions", JSON.stringify(selectedCommissions));
        const url = `/protocol/${protocol.id}/send-approve`;
        const data = { selectedCommissions: selectedCommissions };
        fetchApi
            .post(url, data)
            .then((res) => {
                setSelectedCommissions([]);
                // setProtocolFile(null);
                getProtocol(protocol.id);
                toast.success(res.data.message);
            })
            .catch((err) => {
                toast.error(err.data.message);
            });
        handleCloseSendToApproveModal();
    };

    useEffect(() => {
        getProtocolCommissions();
    }, [protocol]);
    return (
        <Modal show={modalProtocol} onHide={handleCloseSendToApproveModal}>
            <Header closeButton>
                <Title>Protokol ni Tasdiqlashga yuborish</Title>
            </Header>
            <Form>
                <Body className="space-y-3">
                    {/*<Control*/}
                    {/*    onChange={handleChangeProtocolFile}*/}
                    {/*    type="file"*/}
                    {/*    placeholder="Bayonnoma buyrug'i"*/}
                    {/*/>*/}
                    {/*<Table striped bordered responsive hover size="sm">*/}
                    {/*    <thead>*/}
                    {/*        <tr>*/}
                    {/*            <th className="border-0">*/}
                    {/*                Harakatlar*/}
                    {/*            </th>*/}
                    {/*            <th className="border-0">*/}
                    {/*                F.I.O*/}
                    {/*            </th>*/}
                    {/*        </tr>*/}
                    {/*    </thead>*/}
                    {/*    <tbody>*/}
                    {/*    {examCommissions.map((examCommission, index) => (*/}
                    {/*        <tr key={index}>*/}
                    {/*            <td>*/}
                    {/*                <div className="flex space-x-2">*/}
                    {/*                    <input*/}
                    {/*                        className="hover:cursor-pointer"*/}
                    {/*                        id={`label-answer-${index}`}*/}
                    {/*                        onChange={(e) => handleCheckedCommission(e, examCommission)}*/}
                    {/*                        type="checkbox"*/}
                    {/*                        name="commision"*/}
                    {/*                        checked={selectedCommissions.includes(examCommission.id)}*/}
                    {/*                        value={examCommission.id}*/}
                    {/*                    />*/}
                    {/*                </div>*/}
                    {/*            </td>*/}
                    {/*            <td>*/}
                    {/*                {examCommission.firstname + " " + examCommission.lastname + " " + examCommission.middlename}*/}
                    {/*            </td>*/}
                    {/*        </tr>*/}
                    {/*    ))}*/}
                    {/*    </tbody>*/}
                    {/*</Table>*/}

                </Body>
                <Footer>
                    <Button variant="secondary" onClick={handleCloseSendToApproveModal}>
                        Bekor qilish
                    </Button>
                    <Button variant="primary" onClick={updateProtocol}>
                        {protocol.id ? "Yuborish" : "Qo'shish"}
                    </Button>
                </Footer>
            </Form>

        </Modal>
    );
}

export default ModalProtocolApprove;
