import React, {useState} from "react";
import {FaPlay, FaStop} from "react-icons/fa";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import format from "utils/moment";
import {useSelector} from "react-redux";

const TableExam = ({
    exams,
    handleOpenExamModel,
}) => {
    const {user} = useSelector(state => state);
  return (

      <Table striped bordered responsive hover size="sm">
          <thead>
          <tr>
              <th className="border-0">#</th>
              <th className="border-0">Title</th>
              <th className="border-0">Start Date</th>
              <th className="border-0">Duration</th>
              <th className="border-0">Status</th>
              <th className="border-0">Created At</th>
          </tr>
          </thead>
          <tbody>
          {exams.map((item, idx) => (
              (user.groupId == item.groupId || user.role.shortname != 'student') ? (
                  <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td className="px-1">{item?.name}</td>
                      <td className="px-1">{format.formatDateTimeForExam(item?.startDate)}</td>
                      <td className="px-1">{item?.duration}</td>
                      <td className="px-1">{(item?.status == "active")?("Aktiv"):("Imtihon to'xtatilgan")}</td>
                      <td className="px-1">{format.formatDate(item?.createdAt)}</td>
                      <td>
                          {(item?.status == "active")?(
                              <Link to={`test/${item?.id}`} className="btn btn-sm btn-primary">
                                  <FaPlay />
                              </Link>
                          ):("")}
                          {(item?.status == "active")?(
                              <div
                                  className="btn btn-sm btn-success"
                                  onClick={() => handleOpenExamModel(item.id)}>
                                  <FaStop />
                              </div>
                          ):("")}

                      </td>
                  </tr>
              ): ("")

          ))}
          </tbody>
      </Table>

  );
};

export default TableExam;
