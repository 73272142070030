import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Container, Row, Col, Table, Form } from "react-bootstrap";
import { RiDeleteBin6Line } from "react-icons/ri";
// import { GrView } from "react-icons/gr";
import { FaEye } from "react-icons/fa";
// import { questions } from "server";
import fetchApi from "../utils/fetchApi";
import Pagination from "../components/Pagination/Pagination";
import ModalDelete from "../components/models/ModalDelete";
import format from "../utils/moment";

const Questions = () => {
  // template
  const {} = Form;

  //library
  const parse = require("html-react-parser");

  //states
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [questionId, setQuestionId] = useState([]);
  //pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(15);
  const [pages, setPages] = useState(1);
  const [allItem, setAllItem] = useState(0);
  // modal states
  const [modalDelete, setModalDelete] = useState(false);

  // handlers
  const handleOpenDeleteModel = (id) => {
    setModalDelete(true);
    setQuestionId(id);
  };
  const handleCloseDeleteModel = () => setModalDelete(false);

  //functions
  function getQuestions(page = null, limit = recordsPerPage) {
    fetchApi
      .get("/questions", {
        params: {
          page,
          limit,
        },
      })
      .then((res) => {
        // console.log(res.data)
        setQuestions(res.data.questions);
        setLoadingQuestions(true);
        setPages(res.data.pages);
        setAllItem(res.data.items);
      })
      .catch();
  }

  //Effect Hook
  useEffect(() => {
    getQuestions(currentPage, recordsPerPage);
  }, [currentPage]);

  //render html
  return (
    <div className="selector-group">
      <Container fluid>
        <div className="flex w-full justify-end mb-2">
          <Link
            to="/admin/question-create"
            className="rounded bg-blue-500 hover:bg-blue-600 text-slate-50 px-4 py-1 text-lg ">
            + Qo'shish
          </Link>
        </div>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg bg-white  rounded">
              {/*<Card.Header className="flex w-full justify-end">*/}
              {/*  <div className="w-full flex lg:mb-5 md:mb-2 space-x-2 ">*/}
              {/*    <select className="p-2 form-control md:w-full lg:w-1/3  bg-slate-50">*/}
              {/*      <option value="UZ">Uzbeksion</option>*/}
              {/*      <option value="US">United States</option>*/}
              {/*      <option value="CA">Canada</option>*/}
              {/*      <option value="FR">France</option>*/}
              {/*      <option value="DE">Germany</option>*/}
              {/*    </select>*/}
              {/*    <input*/}
              {/*      className="form-control md:w-full lg:w-1/3  bg-slate-50	"*/}
              {/*      placeholder="Search name"*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</Card.Header>*/}
              <Card.Body className="table-full-width table-responsive px-0 question-list">
                {loadingQuestions ? (
                  <Table striped bordered responsive hover size="sm">
                    <thead>
                      <tr>
                        <th className="border-0">#</th>
                        <th className="border-0">Savol</th>
                        <th className="border-0">Holati</th>
                        <th className="border-0">Fani</th>
                        <th className="border-0">O'zgartirilgan sana</th>
                        <th className="border-0">Harakatlar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {questions.map((item, idx) => (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td>{parse(item.name)}</td>
                          <td>{item.status}</td>
                          <td>{item.subject.name}</td>
                          <td>{format.formatDate(item.updatedAt)}</td>
                          <td>
                            <div className="flex space-x-2 full-height align-center justify-center">
                              <Link
                                to={`/admin/question-create/${item.id}`}
                                className="btn btn-sm btn-success">
                                <FaEye />
                              </Link>
                              <div
                                className="btn btn-sm btn-danger"
                                onClick={() => handleOpenDeleteModel(item.id)}>
                                <RiDeleteBin6Line />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <h3 className="text-center">Loading...</h3>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Pagination
              nPages={pages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              allItem={allItem}
            />
          </Col>
        </Row>
      </Container>
      <ModalDelete
        url={`/question/${questionId}/delete`}
        modalDelete={modalDelete}
        handleCloseDeleteModel={handleCloseDeleteModel}
        getDataRetry={getQuestions}
        currentPage={currentPage}
      />
    </div>
  );
};

export default Questions;
