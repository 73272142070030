import React, { useState } from "react";
import { useEffect } from "react";
import {Form, Modal, Button, FormCheck, Row, Col} from "react-bootstrap";
import fetchApi from "utils/fetchApi";
import { toast } from "react-toastify";

function ExamStopModal({
    examId,
    handleCloseExamModel,
    modalExam,
    setExamLoading,
    getExams
}) {
    // components
    const { Header, Title, Footer } = Modal;
    // server function
    const StopExam = async () => {
        const url = `/exam/${examId}/update`;
        fetchApi
            .get(url)
            .then((res) => {
                getExams();
                setExamLoading(false);
                toast.success(res.data.message);
            })
            .catch((err) => {
                setExamLoading(false);
                toast.error(err);
            });
        handleCloseExamModel();
    };

    useEffect(() => {
    }, []);

    return (
        <Modal show={modalExam} onHide={handleCloseExamModel} size='lg'>
            <Header closeButton>
                <Title>Imtihonni to'xtatish</Title>
            </Header>
            <Footer>
                <Button variant="secondary" onClick={handleCloseExamModel}>
                    Bekor qilish
                </Button>
                <Button variant="success" onClick={StopExam}>
                    To'xtatish
                </Button>
            </Footer>
        </Modal>
    );
}

export default ExamStopModal;
