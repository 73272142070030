import React, { useState, useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import TableGroup from "components/Group/TableGroup";
import ModalGroupComponent from "components/Group/ModalGroup";
import AddButton from "components/FormComponents/AddButton";
import fetchApi from "utils/fetchApi";
import Pagination from "components/Pagination/Pagination";
import ModalDelete from "components/models/ModalDelete";

const Groups = () => {
  // components
  const { Header, Body } = Card;

  
  // states
  const [modalGroup, setModalGroup] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [groupItems, setGroupItems] = useState([]);
  const [groupEdit, setGroupEdit] = useState({ name: "", id: "" });
  const [groudId, setGroupId] = useState("");
  const [groupLoading, setGroupLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [searchName, setSearchName] = useState("");
  const [allItem, setAllItem] = useState(0);
  const limitData = 10;

  // modal function
  const handleOpenGroupModel = () => setModalGroup(true);
  const handleCloseGroupModel = () => {
    setModalGroup(false);
    setGroupEdit({ name: "", id: "" });
  };
  const handleOpenDeleteModel = (id) => {
    setModalDelete(true);
    setGroupId(id);
  };
  const handleCloseDeleteModel = () => setModalDelete(false);

  // server function
  const getGroups = (page = 1, limit = limitData, name) => {
    fetchApi
      .get("/groups", {
        params: {
          page,
          limit,
          name,
        },
      })
      .then((res) => {
        const { groups, pages, items } = res.data;
        // console.log(groups)
        setAllItem(items)
        setGroupItems(groups);
        setPages(pages);
        setGroupLoading(false);
      });
  };

  const handleGroupEdit = (id) => {
    fetchApi.get(`/group/${id}/view`).then((res) => {
      const { group } = res.data;
      setGroupEdit(group);
      handleOpenGroupModel();
    });
  };

  // Lifecycle
  useEffect(() => {
    getGroups(currentPage, limitData, searchName);
  }, [currentPage, searchName]);
  return (
    <div className="selector-group">
      <ModalGroupComponent
        setGroupLoading={setGroupLoading}
        modalGroup={modalGroup}
        groupEdit={groupEdit}
        getGroups={getGroups}
        currentPage={currentPage}
        handleCloseGroupModel={handleCloseGroupModel}
      />
      <ModalDelete
        url={`/group/${groudId}/delete`}
        modalDelete={modalDelete}
        handleCloseDeleteModel={handleCloseDeleteModel}
        getDataRetry={getGroups}
        currentPage={currentPage}
      />
      <Container fluid>
        <div className="flex w-full justify-end mb-2">
          <AddButton onClick={handleOpenGroupModel} />
        </div>
        <Card className="card-plain table-plain-bg bg-white  rounded">
          <Header className="w-full">
            <input
              className="form-control md:w-full lg:w-1/3 bg-slate-50	"
              placeholder="Search name"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
            />
          </Header>
          <Body>
            {groupLoading ? (
              <h1>Loading...</h1>
            ) : (
              <TableGroup
                currentPage={currentPage}
                limitData={limitData}
                groups={groupItems}
                handleGroupEdit={handleGroupEdit}
                handleOpenDeleteModel={handleOpenDeleteModel}
              />
            )}
          </Body>
        </Card>
        <Pagination
          nPages={pages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          allItem={allItem}
        />
      </Container>
    </div>
  );
};

export default Groups;
