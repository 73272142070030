import Dashboard from "views/Dashboard.js";
import Groups from "views/Groups";
import Subjects from "views/Subjects";
import Questions from "views/Questions";
import Exams from "views/Exams";
import ExamCrud from "views/ExamCrud";
import Students from "views/Students";
import UserProfile from "views/UserProfile.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import Icons from "views/Icons.js";
import Notifications from "views/Notifications.js";
import StudentExams from "views/StudentExams";
import QuestionsCrud from "views/QuestionsCrud";
import Roles from "./views/Roles";
import UsersList from "./views/UsersList";
import UsersView from "./views/UsersView";
import TestExecution from "views/TestExecution";
import ExamResults from "views/ExamResult";
import ExamResultView from "views/ExamResultView";
import Tasks from "./views/Tasks";
import Protocol from "./views/Protocol";
import ProtocolView from "./views/ProtocolView";
import TaskView from "./views/TaskView";
const dashboardRoutes = [
  {
    // hidden: ["superadmin","commision","rais-deputy", "rais"],
    hidden: ["superadmin", "commision", "commision-assistant","manager", "rais-deputy", "rais"],
    path: "/dashboard",
    name: "Bosh sahifa",
    icon: "nc-icon nc-grid-45",
    component: Dashboard,
    layout: "/admin",
  },
  {
    hidden: ["superadmin", "commision", "commision-assistant","manager", "rais-deputy", "rais"],
    // hidden: ["superadmin", "commision", "rais-deputy", "rais"],
    path: "/tasks",
    name: "Arizalar",
    icon: "nc-icon nc-layers-3",
    component: Tasks,
    layout: "/admin",
  },
  {
    hidden: ["superadmin", "commision", "commision-assistant","manager", "rais-deputy", "rais"],
    // hidden: ["superadmin", "commision", "rais-deputy", "rais"],
    path: "/protocols",
    name: "Bayonnomalar",
    icon: "nc-icon nc-layers-3",
    component: Protocol,
    layout: "/admin",
  },
  {
    hidden: ["superadmin", "commision", "commision-assistant", "manager","rais-deputy", "rais"],
    path: "/students",
    name: "Talabgorlar",
    icon: "nc-icon nc-badge",
    component: Students,
    layout: "/admin",
  },
  {
    hidden: ["superadmin", "commision", "commision-assistant","manager", "rais-deputy", "rais"],
    // hidden: ["superadmin",  "rais-deputy", "rais"],
    path: "/groups",
    name: "Guruhlar",
    icon: "nc-icon nc-layers-3",
    component: Groups,
    layout: "/admin",
  },
  {
    hidden: ["superadmin", "commision", "commision-assistant", "manager","rais-deputy", "rais"],
    path: "/subjects",
    name: "Fanlar",
    icon: "nc-icon nc-bag",
    component: Subjects,
    layout: "/admin",
  },
  {
    hidden: ["superadmin", "commision", "commision-assistant", "manager", "rais-deputy", "rais"],
    path: "/questions",
    name: "Savollar",
    icon: "nc-icon nc-ruler-pencil",
    component: Questions,
    layout: "/admin",
  },
  {
    hidden: ["superadmin", "commision", "student","manager", "commision-assistant"],
    upgrade: true,
    path: "/test/:id?",
    name: "Test",
    component: TestExecution,
    layout: "/admin",
  },
  {
    hidden: ["superadmin", "commision", "commision-assistant", "manager",],
    upgrade: true,
    path: "/question-create/:id?",
    name: "Savol",
    icon: "nc-icon nc-ruler-pencil",
    component: QuestionsCrud,
    layout: "/admin",
  },
  {
    hidden: ["student", "superadmin", "commision", "commision-assistant", "manager", "rais-deputy", "rais"],
    path: "/exams",
    name: "Imtihon",
    icon: "nc-icon nc-key-25",
    component: Exams,
    layout: "/admin",
  },
  {
    hidden: ["superadmin", "commision", "commision-assistant", "manager", "rais-deputy", "rais", "student"],
    path: "/exam-results",
    name: "Imtihon natijalari",
    icon: "nc-icon nc-notes",
    component: ExamResults,
    layout: "/admin",
  },
  {
    hidden: ["superadmin", "commision", "commision-assistant", "manager",],
    upgrade: true,
    path: "/exam-create",
    name: "Imtihon",
    icon: "nc-icon nc-key-25",
    component: ExamCrud,
    layout: "/admin",
  },
  {
    hidden: ["superadmin", "commision", "commision-assistant", "manager", "rais-deputy", "rais"],
    path: "/users",
    name: "Foydalanuvchilar",
    icon: "nc-icon nc-single-02",
    component: UsersList,
    layout: "/admin",
  },
  {
    hidden: ["superadmin", "commision", "commision-assistant", "manager", "rais-deputy", "rais"],
    upgrade: true,
    path: "/user/view/:id",
    name: "Foydalanuvchi",
    component: UsersView,
    layout: "/admin",
  },
  {
    hidden: ["superadmin", "commision", "student", "commision-assistant", "manager", "rais-deputy", "rais"],
    path: "/profil",
    name: "Profil",
    icon: "nc-icon nc-circle-09",
    component: UserProfile,
    layout: "/admin",
  },
  {
    hidden: ["superadmin", "commision", "commision-assistant", "manager",],
    upgrade: true,
    path: "/exam-result/:id/view",
    name: "Natija",
    icon: "nc-icon nc-ruler-pencil",
    component: ExamResultView,
    layout: "/admin",
  },
  {
    hidden: ["superadmin", "commision", "commision-assistant", "manager", "rais-deputy", "rais"],
    upgrade: true,
    path: "/task/:id/view",
    name: "Buyurtma",
    icon: "nc-icon nc-ruler-pencil",
    component: TaskView,
    layout: "/admin",
  },
  {
    hidden: ["superadmin", "commision", "commision-assistant", "manager", "rais-deputy", "rais"],
    upgrade: true,
    path: "/protocol/:id/view",
    name: "Protokol",
    icon: "nc-icon nc-ruler-pencil",
    component: ProtocolView,
    layout: "/admin",
  },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   icon: "nc-icon nc-notes",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "nc-icon nc-paper-2",
  //   component: Typography,
  //   layout: "/admin",
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/admin",
  // },
];

export default dashboardRoutes;
