import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col, Table } from "react-bootstrap";
import fetchApi from "utils/fetchApi";
import TableExamResults from "components/Exam/TableExamResults";
import Pagination from "components/Pagination/Pagination";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {getLocalStorge, setLocalStorge} from "../utils/localStorge";

const ExamResults = () => {

  const {user} = useSelector(state=>state)

  let localFilter = getLocalStorge('filterexam');
  if(!localFilter){
    localFilter = {
      epiguid:'',
      createdAt:'',
      page: 1,
      limit: 10,
      name: '',
      fio: '',
      selectedStatus: '',
    }
  }

  // states
  const [results, setExamResult] = useState([]);
  const [examLoading, setExamLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [allItem, setAllItem] = useState(0);
  const limitData = 10;
  const [filterExam, setTaskFilter] = useState(localFilter);


  const handlerSearchByEpiguId = (e) => {
    let id = e.target.value;
    setTaskFilter({...filterExam,epiguid: id});
  }

  const handlerSearchByDate = (e) => {
    let createdAt = e.target.value;
    setTaskFilter({...filterExam,createdAt});
  }
  const handlerSearchByFio = (e) => {
    let fio = e.target.value
    setTaskFilter({...filterExam,fio: fio});
  }

  const handlerSearchByStatus = (e) => {
    let id = e.target.value
    setTaskFilter({...filterExam,selectedStatus: id});
  }

  //server funtions
  const getExams = (page = 1, limit = limitData, name) => {
    fetchApi
      .get("/exam/results", {
        params: filterExam
      })
      .then((res) => {
        const { results, pages, items } = res.data;
        // console.log(groups)
        setExamResult(results);
        setPages(pages);
        setExamLoading(false);
        setAllItem(items)
      });
  };
  useEffect(() => {
    setLocalStorge('filterexam', filterExam);
    getExams();
  }, [filterExam]);
  // Lifecycle
  useEffect(() => {
    getExams(currentPage, limitData);
  }, [currentPage]);
  useEffect(() => {
    setTaskFilter({...filterExam,page:currentPage});
  }, [currentPage])

  return (
    <div className="selector-group">
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg bg-white  rounded">
              <Card.Header className="w-full">
                <Row>
                  <Col md="3">
                    <input
                        type="text"
                        className="form-control md:w-full  bg-slate-50	"
                        placeholder="Ariza raqami"
                        onChange={handlerSearchByEpiguId}
                        value={filterExam.epiguid}
                    />
                  </Col>
                  <Col md="3">
                    <input
                        type="text"
                        className="form-control md:w-full  bg-slate-50	"
                        placeholder="F.I.O"
                        onChange={handlerSearchByFio}
                        value={filterExam.fio}
                    />
                  </Col>
                  <Col md="3">
                    <select
                        onChange={handlerSearchByStatus}
                        className="p-2 form-control md:w-full  bg-slate-50">
                      <option value="">
                        Holati
                      </option>
                      <option value="notcome">
                        Kelmadi
                      </option>
                      <option value="pass">
                        O'tdi
                      </option>
                      <option value="fail">
                        O'tmadi
                      </option>
                    </select>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                {examLoading ? (
                  <h1>Loading...</h1>
                ) : (
                  <TableExamResults
                    results={results}
                  />
                )}
              </Card.Body>
            </Card>
            <Pagination
              nPages={pages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              allItem={allItem}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ExamResults;
