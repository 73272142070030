import React from "react";

function AddButton({ onClick }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="rounded bg-blue-500 hover:bg-blue-600 text-slate-50 px-4 py-1 text-lg ">
      + Qo'shish
    </button>
  );
}

export default AddButton;
