import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import Login from "views/Login";
import Redirectlogin from "views/Redirect";
import { useSelector } from "react-redux";


function App() {
    const state = useSelector((state) => state);
    const { initialCheckAuth } = state;
    const {user} = useSelector(state => state);

  // return (
  //   <Switch>
  //     <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
  //     <Redirect to="/admin/tasks" />
  //   </Switch>
  // );

  return initialCheckAuth ? (
    <Switch>
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        {user?.role?.shortName == "student" ? (
            <Redirect to="/admin/exams" />
        ) : ["commision", "commision-assistant", "manager"].includes(user?.role?.shortName )?(
            <Redirect to="/admin/questions" />
        ) :(
            <Redirect to="/admin/dashboard" />
        )}

    </Switch>
  ) : (
    <Switch>
      <Route path="/login" render={(props) => <Login {...props} />} />
      <Route
        path="/one-get-logged"
        render={(props) => <Redirectlogin {...props} />}
      />
      <Redirect to="/login" />
    </Switch>
  );
}

export default App;
