import React from "react";
import { useState } from "react";

const Pagination = ({ nPages, currentPage, setCurrentPage, allItem }) => {
  const [showAllPages, setShowAllPages] = useState(false);

  //functions
  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  const pageNumbers = [];

  const maxPagesToShow = 7;
  const pagesBeforeDots = 3;
  const pagesAfterDots = 3;

  if (nPages <= maxPagesToShow || showAllPages) {
    for (let i = 1; i <= nPages; i++) {
      pageNumbers.push(i);
    }
  } else if (currentPage <= pagesBeforeDots + 1) {
    for (let i = 1; i <= maxPagesToShow - pagesAfterDots; i++) {
      pageNumbers.push(i);
    }
    pageNumbers.push("...");
    for (let i = nPages - pagesAfterDots + 1; i <= nPages; i++) {
      pageNumbers.push(i);
    }
  } else if (currentPage >= nPages - pagesAfterDots) {
    pageNumbers.push(1);
    pageNumbers.push("...");
    for (let i = currentPage - pagesAfterDots; i <= nPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    pageNumbers.push(1);
    pageNumbers.push("...");
    for (
      let i = currentPage - pagesBeforeDots;
      i <= currentPage + pagesAfterDots;
      i++
    ) {
      pageNumbers.push(i);
    }
    pageNumbers.push("...");
    pageNumbers.push(nPages);
  }

  const handlePageClick = (pgNumber) => {
    if (pgNumber === "...") {
      setShowAllPages(!showAllPages);
    } else if (pgNumber !== currentPage) {
      setCurrentPage(pgNumber);
    }
  };

  return (
    <nav>
      <ul className="float-left">
        <li style={{ fontWeight: "700" }}>Jami: {allItem}</li>
      </ul>
      <ul className="pagination float-right">
        <li className="page-item">
          <a
            className="page-link"
            href="#"
            aria-label="Previous"
            onClick={prevPage}
          >
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </a>
        </li>
        {pageNumbers.map((pgNumber, index) => (
          <li
            key={index}
            className={`page-item ${pgNumber === "..." ? "disabled" : ""} ${
              currentPage === pgNumber ? "active" : ""
            }`}
          >
            <a
              className="page-link"
              href="#"
              onClick={() => handlePageClick(pgNumber)}
            >
              {pgNumber}
            </a>
          </li>
        ))}
        <li className="page-item">
          <a
            className="page-link"
            href="#"
            aria-label="Next"
            onClick={nextPage}
          >
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
