export const groups = [
  {
    id: "1",
    name: "grux 1",
    status: "Active",
    count: 1,
    language: "uzbek",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "2",
    name: "grux 2",
    status: "Active",
    count: 1,
    language: "uzbek",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "3",
    name: "grux 4",
    status: "Active",
    count: 1,
    language: "uzbek",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "4",
    name: "imtixon",
    status: "Active",
    count: 1,
    language: "uzbek",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "5",
    name: "imtixo 21",
    status: "Active",
    count: 1,
    language: "uzbek",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "6",
    name: "imtioxon 5",
    status: "Active",
    count: 1,
    language: "uzbek",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "7",
    name: "grux 1",
    status: "Active",
    count: 1,
    language: "uzbek",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "8",
    name: "grux 1",
    status: "Active",
    count: 1,
    language: "uzbek",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "9",
    name: "grux 1",
    status: "Active",
    count: 1,
    language: "uzbek",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "10",
    name: "grux 1",
    status: "Active",
    count: 1,
    language: "uzbek",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "11",
    name: "grux 1",
    status: "Active",
    count: 1,
    language: "uzbek",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "12",
    name: "grux 3",
    status: "Active",
    count: 1,
    language: "uzbek",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "13",
    name: "grux 1",
    status: "Active",
    count: 1,
    language: "uzbek",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "14",
    name: "grux 1",
    status: "Active",
    count: 1,
    language: "uzbek",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "15",
    name: "grux 1",
    status: "Active",
    count: 1,
    language: "uzbek",
    date: "26.10.2022 09:28:54",
  },
];

export const students = [
  {
    id: "1",
    firstname: "Oybek",
    lastname: "Polatov",
    passport: "AB0631853",
    email: "(not set)",
    phone: "+(998) 97 456-12-21",
    status: "approved",
    updated: "26.10.2022 14:52:18",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "1",
    firstname: "Oybek",
    lastname: "Polatov",
    passport: "AB0631853",
    email: "(not set)",
    phone: "+(998) 97 456-12-21",
    status: "approved",
    updated: "26.10.2022 14:52:18",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "1",
    firstname: "Oybek",
    lastname: "Polatov",
    passport: "AB0631853",
    email: "(not set)",
    phone: "+(998) 97 456-12-21",
    status: "approved",
    updated: "26.10.2022 14:52:18",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "1",
    firstname: "Oybek",
    lastname: "Polatov",
    passport: "AB0631853",
    email: "(not set)",
    phone: "+(998) 97 456-12-21",
    status: "approved",
    updated: "26.10.2022 14:52:18",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "1",
    firstname: "Oybek",
    lastname: "Polatov",
    passport: "AB0631853",
    email: "(not set)",
    phone: "+(998) 97 456-12-21",
    status: "approved",
    updated: "26.10.2022 14:52:18",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "1",
    firstname: "Oybek",
    lastname: "Polatov",
    passport: "AB0631853",
    email: "(not set)",
    phone: "+(998) 97 456-12-21",
    status: "approved",
    updated: "26.10.2022 14:52:18",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "1",
    firstname: "Oybek",
    lastname: "Polatov",
    passport: "AB0631853",
    email: "(not set)",
    phone: "+(998) 97 456-12-21",
    status: "approved",
    updated: "26.10.2022 14:52:18",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "1",
    firstname: "Oybek",
    lastname: "Polatov",
    passport: "AB0631853",
    email: "(not set)",
    phone: "+(998) 97 456-12-21",
    status: "approved",
    updated: "26.10.2022 14:52:18",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "1",
    firstname: "Oybek",
    lastname: "Polatov",
    passport: "AB0631853",
    email: "(not set)",
    phone: "+(998) 97 456-12-21",
    status: "approved",
    updated: "26.10.2022 14:52:18",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "1",
    firstname: "Oybek",
    lastname: "Polatov",
    passport: "AB0631853",
    email: "(not set)",
    phone: "+(998) 97 456-12-21",
    status: "approved",
    updated: "26.10.2022 14:52:18",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "1",
    firstname: "Oybek",
    lastname: "Polatov",
    passport: "AB0631853",
    email: "(not set)",
    phone: "+(998) 97 456-12-21",
    status: "approved",
    updated: "26.10.2022 14:52:18",
    date: "26.10.2022 09:28:54",
  },
  {
    id: "1",
    firstname: "Oybek",
    lastname: "Polatov",
    passport: "AB0631853",
    email: "(not set)",
    phone: "+(998) 97 456-12-21",
    status: "approved",
    updated: "26.10.2022 14:52:18",
    date: "26.10.2022 09:28:54",
  },
];

export const subjects = [
  {
    id: "2",
    title:
      "Бино ва иншоатлар техник инвентаризациялашда қўлланиладиган шартли белгилар.",
    date: "19.01.2021 16:16:05",
  },
  {
    id: "3",
    title:
      "Бино ва иншоатлар техник инвентаризациялашда қўлланиладиган шартли белгилар.",
    date: "19.01.2021 16:16:05",
  },
  {
    id: "1",
    title:
      "Бино ва иншоатлар техник инвентаризациялашда қўлланиладиган шартли белгилар.",
    date: "19.01.2021 16:16:05",
  },
];

export const questions = [
  {
    id: 1,
    title:
      "В каком варианте правильно указан топографический условный знак, показанный ниже?",
    status: "Enable",
    subject:
      "Вопросы по условным обозначениям, используемым в технической инвентаризации зданий и сооружений",
    date: "08.10.2021 12:13:07",
  },
  {
    id: 2,
    title:
      "В каком варианте правильно указан топографический условный знак, показанный ниже?",
    status: "Enable",
    subject:
      "Вопросы по условным обозначениям, используемым в технической инвентаризации зданий и сооружений",
    date: "08.10.2021 12:13:07",
  },
  {
    id: 3,
    title:
      "В каком варианте правильно указан топографический условный знак, показанный ниже?",
    status: "Enable",
    subject:
      "Вопросы по условным обозначениям, используемым в технической инвентаризации зданий и сооружений",
    date: "08.10.2021 12:13:07",
  },
  {
    id: 4,
    title:
      "В каком варианте правильно указан топографический условный знак, показанный ниже?",
    status: "Enable",
    subject:
      "Вопросы по условным обозначениям, используемым в технической инвентаризации зданий и сооружений",
    date: "08.10.2021 12:13:07",
  },
  {
    id: 5,
    title:
      "В каком варианте правильно указан топографический условный знак, показанный ниже?",
    status: "Enable",
    subject:
      "Вопросы по условным обозначениям, используемым в технической инвентаризации зданий и сооружений",
    date: "08.10.2021 12:13:07",
  },
  {
    id: 6,
    title:
      "В каком варианте правильно указан топографический условный знак, показанный ниже?",
    status: "Enable",
    subject:
      "Вопросы по условным обозначениям, используемым в технической инвентаризации зданий и сооружений",
    date: "08.10.2021 12:13:07",
  },
  {
    id: 7,
    title:
      "В каком варианте правильно указан топографический условный знак, показанный ниже?",
    status: "Enable",
    subject:
      "Вопросы по условным обозначениям, используемым в технической инвентаризации зданий и сооружений",
    date: "08.10.2021 12:13:07",
  },
  {
    id: 1,
    title:
      "В каком варианте правильно указан топографический условный знак, показанный ниже?",
    status: "Enable",
    subject:
      "Вопросы по условным обозначениям, используемым в технической инвентаризации зданий и сооружений",
    date: "08.10.2021 12:13:07",
  },
  {
    id: 2,
    title:
      "В каком варианте правильно указан топографический условный знак, показанный ниже?",
    status: "Enable",
    subject:
      "Вопросы по условным обозначениям, используемым в технической инвентаризации зданий и сооружений",
    date: "08.10.2021 12:13:07",
  },
  {
    id: 3,
    title:
      "В каком варианте правильно указан топографический условный знак, показанный ниже?",
    status: "Enable",
    subject:
      "Вопросы по условным обозначениям, используемым в технической инвентаризации зданий и сооружений",
    date: "08.10.2021 12:13:07",
  },
  {
    id: 4,
    title:
      "В каком варианте правильно указан топографический условный знак, показанный ниже?",
    status: "Enable",
    subject:
      "Вопросы по условным обозначениям, используемым в технической инвентаризации зданий и сооружений",
    date: "08.10.2021 12:13:07",
  },
  {
    id: 5,
    title:
      "В каком варианте правильно указан топографический условный знак, показанный ниже?",
    status: "Enable",
    subject:
      "Вопросы по условным обозначениям, используемым в технической инвентаризации зданий и сооружений",
    date: "08.10.2021 12:13:07",
  },
  {
    id: 6,
    title:
      "В каком варианте правильно указан топографический условный знак, показанный ниже?",
    status: "Enable",
    subject:
      "Вопросы по условным обозначениям, используемым в технической инвентаризации зданий и сооружений",
    date: "08.10.2021 12:13:07",
  },
  {
    id: 7,
    title:
      "В каком варианте правильно указан топографический условный знак, показанный ниже?",
    status: "Enable",
    subject:
      "Вопросы по условным обозначениям, используемым в технической инвентаризации зданий и сооружений",
    date: "08.10.2021 12:13:07",
  },
];

export const exams = [
  {
    id: 1,
    title: "muxandislar-7",
    start_date: "26.10.2022 14:55:00",
    duration: "60",
    status: "enable",
    create_date: "26.10.2022 14:53:19",
  },
  {
    id: 2,
    title: "muxandislar-7",
    start_date: "26.10.2022 14:55:00",
    duration: "60",
    status: "enable",
    create_date: "26.10.2022 14:53:19",
  },
  {
    id: 3,
    title: "muxandislar-7",
    start_date: "26.10.2022 14:55:00",
    duration: "60",
    status: "enable",
    create_date: "26.10.2022 14:53:19",
  },
  {
    id: 4,
    title: "muxandislar-7",
    start_date: "26.10.2022 14:55:00",
    duration: "60",
    status: "enable",
    create_date: "26.10.2022 14:53:19",
  },
  {
    id: 5,
    title: "muxandislar-7",
    start_date: "26.10.2022 14:55:00",
    duration: "60",
    status: "enable",
    create_date: "26.10.2022 14:53:19",
  },
  {
    id: 6,
    title: "muxandislar-7",
    start_date: "26.10.2022 14:55:00",
    duration: "60",
    status: "enable",
    create_date: "26.10.2022 14:53:19",
  },
];

export const student_exams = [
  {
    id: 1,
    title: "malaka shoxodat",
    student: "Qalandarov Davlatkhuja",
    savol: "100",
    javob: "87",
    ball: "174",
    ball_f: "87",
    create_date: "26.10.2022 10:35:14",
  },
  {
    id: 2,
    title: "malaka shoxodat",
    student: "Qalandarov Davlatkhuja",
    savol: "100",
    javob: "87",
    ball: "174",
    ball_f: "87",
    create_date: "26.10.2022 10:35:14",
  },
  {
    id: 3,
    title: "malaka shoxodat",
    student: "Qalandarov Davlatkhuja",
    savol: "100",
    javob: "87",
    ball: "174",
    ball_f: "87",
    create_date: "26.10.2022 10:35:14",
  },
  {
    id: 4,
    title: "malaka shoxodat",
    student: "Qalandarov Davlatkhuja",
    savol: "100",
    javob: "87",
    ball: "174",
    ball_f: "87",
    create_date: "26.10.2022 10:35:14",
  },
  {
    id: 5,
    title: "malaka shoxodat",
    student: "Qalandarov Davlatkhuja",
    savol: "100",
    javob: "87",
    ball: "174",
    ball_f: "87",
    create_date: "26.10.2022 10:35:14",
  },
  {
    id: 6,
    title: "malaka shoxodat",
    student: "Qalandarov Davlatkhuja",
    savol: "100",
    javob: "87",
    ball: "174",
    ball_f: "87",
    create_date: "26.10.2022 10:35:14",
  },
];

let data = [
  {
    test: "Lorem Ipsum is simply dummy text of the printing and typesettingads industry.",
    answers: [
      {
        name: "sections 1.10.32 and 1.10.33 of de Finibus",
        isTrue: false,
      },
      {
        name: "There are many variations of passages of Lorem Ipsum avafilable, but the",
        isTrue: false,
      },
      {
        name: "If you are going to use a passage of Lorem Ipsum,f you need to be",
        isTrue: false,
      },
      {
        name: "comes from a line in section 1.10.32.",
        isTrue: false,
      },
    ],
  },
  {
    test: "Lorem Isfpsum is ssimply dummy textd of the printing and typesetting industry.",
    answers: [
      {
        name: "sections 1.10.32 and 1.10.33 of de Finibus",
        isTrue: false,
      },
      {
        name: "There are many variations of passages of Lorem Ipsum available, but the",
        isTrue: false,
      },
      {
        name: "If you are going to use a passage of Lorem Ipsum, you need to be",
        isTrue: false,
      },
      {
        name: "comes from a line in section 1.10.32.",
        isTrue: false,
      },
    ],
  },
  {
    test: "Lorem Ipsum is simplfdy dummy text of the printing and typesetting industry.",
    answers: [
      {
        name: "sections 1.10.32 and 1.10.33 of de Finibus",
        isTrue: false,
      },
      {
        name: "There are many variations of passages of Lorem Ipsum available, but the",
        isTrue: false,
      },
      {
        name: "If you are going to use a passage of Lorem Ipsum, you need to be",
        isTrue: false,
      },
      {
        name: "comes from a line in section 1.10.32.",
        isTrue: false,
      },
    ],
  },
  {
    test: "Lorem Ipsum is simply dummysd text of the printing and typesetting industry.",
    answers: [
      {
        name: "sectionsd 1.10.32 and 1.10.33 of de Finibus",
        isTrue: false,
      },
      {
        name: "There are manfdy variations of passages of Lorem Ipsum available, but the",
        isTrue: false,
      },
      {
        name: "If you are going to use a passage of Lorem Ipsum, you need to be",
        isTrue: false,
      },
      {
        name: "comes from a line in section 1.10.32.",
        isTrue: false,
      },
    ],
  },
  {
    test: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    answers: [
      {
        name: "sections 1.10.32 and 1.10.33 of de Finibus",
        isTrue: false,
      },
      {
        name: "There are many variations of passages of Lorem Ipsum available, but the",
        isTrue: false,
      },
      {
        name: "If you are going to use a passage of Lorem Ipsum, you need to be",
        isTrue: false,
      },
      {
        name: "comes from a line in section 1.10.32.",
        isTrue: false,
      },
    ],
  },
  {
    test: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    answers: [
      {
        name: "sections 1.10.32 and 1.10.33 of de Finibus",
        isTrue: false,
      },
      {
        name: "There are many variations of passages of Lorem Ipsum available, but the",
        isTrue: false,
      },
      {
        name: "If you are going to use a passage of Lorem Ipsum, you need to be",
        isTrue: false,
      },
      {
        name: "comes from a line in section 1.10.32.",
        isTrue: false,
      },
    ],
  },
  {
    test: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    answers: [
      {
        name: "sections 1.10.32 and 1.10.33 of de Finibus",
        isTrue: false,
      },
      {
        name: "There are many variations of passages of Lorem Ipsum available, but the",
        isTrue: false,
      },
      {
        name: "If you are going to use a passage of Lorem Ipsum, you need to be",
        isTrue: false,
      },
      {
        name: "comes from a line in section 1.10.32.",
        isTrue: false,
      },
    ],
  },
  {
    test: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    answers: [
      {
        name: "sections 1.10.32 and 1.10.33 of de Finibus",
        isTrue: false,
      },
      {
        name: "There are many variations of passages of Lorem Ipsum available, but the",
        isTrue: false,
      },
      {
        name: "If you are going to use a passage of Lorem Ipsum, you need to be",
        isTrue: false,
      },
      {
        name: "comes from a line in section 1.10.32.",
        isTrue: false,
      },
    ],
  },
  {
    test: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    answers: [
      {
        name: "sections 1.10.32 and 1.10.33 of de Finibus",
        isTrue: false,
      },
      {
        name: "There are many variations of passages of Lorem Ipsum available, but the",
        isTrue: false,
      },
      {
        name: "If you are going to use a passage of Lorem Ipsum, you need to be",
        isTrue: false,
      },
      {
        name: "comes from a line in section 1.10.32.",
        isTrue: false,
      },
    ],
  },
  {
    test: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    answers: [
      {
        name: "sections 1.10.32 and 1.10.33 of de Finibus",
        isTrue: false,
      },
      {
        name: "There are many variations of passages of Lorem Ipsum available, but the",
        isTrue: false,
      },
      {
        name: "If you are going to use a passage of Lorem Ipsum, you need to be",
        isTrue: false,
      },
      {
        name: "comes from a line in section 1.10.32.",
        isTrue: false,
      },
    ],
  },
  {
    test: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    answers: [
      {
        name: "sections 1.10.32 and 1.10.33 of de Finibus",
        isTrue: false,
      },
      {
        name: "There are many variations of passages of Lorem Ipsum available, but the",
        isTrue: false,
      },
      {
        name: "If you are going to use a passage of Lorem Ipsum, you need to be",
        isTrue: false,
      },
      {
        name: "comes from a line in section 1.10.32.",
        isTrue: false,
      },
    ],
  },
  {
    test: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    answers: [
      {
        name: "sections 1.10.32 and 1.10.33 of de Finibus",
        isTrue: false,
      },
      {
        name: "There are many variations of passages of Lorem Ipsum available, but the",
        isTrue: false,
      },
      {
        name: "If you are going to use a passage of Lorem Ipsum, you need to be",
        isTrue: false,
      },
      {
        name: "comes from a line in section 1.10.32.",
        isTrue: false,
      },
    ],
  },
  {
    test: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    answers: [
      {
        name: "sections 1.10.32 and 1.10.33 of de Finibus",
        isTrue: false,
      },
      {
        name: "There are many variations of passages of Lorem Ipsum available, but the",
        isTrue: false,
      },
      {
        name: "If you are going to use a passage of Lorem Ipsum, you need to be",
        isTrue: false,
      },
      {
        name: "comes from a line in section 1.10.32.",
        isTrue: false,
      },
    ],
  },
  {
    test: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    answers: [
      {
        name: "sections 1.10.32 and 1.10.33 of de Finibus",
        isTrue: false,
      },
      {
        name: "There are many variations of passages of Lorem Ipsum available, but the",
        isTrue: false,
      },
      {
        name: "If you are going to use a passage of Lorem Ipsum, you need to be",
        isTrue: false,
      },
      {
        name: "comes from a line in section 1.10.32.",
        isTrue: false,
      },
    ],
  },
  {
    test: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    answers: [
      {
        name: "sections 1.10.32 and 1.10.33 of de Finibus",
        isTrue: false,
      },
      {
        name: "There are many variations of passages of Lorem Ipsum available, but the",
        isTrue: false,
      },
      {
        name: "If you are going to use a passage of Lorem Ipsum, you need to be",
        isTrue: false,
      },
      {
        name: "comes from a line in section 1.10.32.",
        isTrue: false,
      },
    ],
  },
  {
    test: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    answers: [
      {
        name: "sections 1.10.32 and 1.10.33 of de Finibus",
        isTrue: false,
      },
      {
        name: "There are many variations of passages of Lorem Ipsum available, but the",
        isTrue: false,
      },
      {
        name: "If you are going to use a passage of Lorem Ipsum, you need to be",
        isTrue: false,
      },
      {
        name: "comes from a line in section 1.10.32.",
        isTrue: false,
      },
    ],
  },
  {
    test: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    answers: [
      {
        name: "sections 1.10.32 and 1.10.33 of de Finibus",
        isTrue: false,
      },
      {
        name: "There are many variations of passages of Lorem Ipsum available, but the",
        isTrue: false,
      },
      {
        name: "If you are going to use a passage of Lorem Ipsum, you need to be",
        isTrue: false,
      },
      {
        name: "comes from a line in section 1.10.32.",
        isTrue: false,
      },
    ],
  },
  {
    test: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    answers: [
      {
        name: "sections 1.10.32 and 1.10.33 of de Finibus",
        isTrue: false,
      },
      {
        name: "There are many variations of passages of Lorem Ipsum available, but the",
        isTrue: false,
      },
      {
        name: "If you are going to use a passage of Lorem Ipsum, you need to be",
        isTrue: false,
      },
      {
        name: "comes from a line in section 1.10.32.",
        isTrue: false,
      },
    ],
  },
  {
    test: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    answers: [
      {
        name: "sections 1.10.32 and 1.10.33 of de Finibus",
        isTrue: false,
      },
      {
        name: "There are many variations of passages of Lorem Ipsum available, but the",
        isTrue: false,
      },
      {
        name: "If you are going to use a passage of Lorem Ipsum, you need to be",
        isTrue: false,
      },
      {
        name: "comes from a line in section 1.10.32.",
        isTrue: false,
      },
    ],
  },
  {
    test: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    answers: [
      {
        name: "sections 1.10.32 and 1.10.33 of de Finibus",
        isTrue: false,
      },
      {
        name: "There are many variations of passages of Lorem Ipsum available, but the",
        isTrue: false,
      },
      {
        name: "If you are going to use a passage of Lorem Ipsum, you need to be",
        isTrue: false,
      },
      {
        name: "comes from a line in section 1.10.32.",
        isTrue: false,
      },
    ],
  },
];

export const tests = data.map((item, index) => ({
  id: index,
  test: item.test,
  answers: item.answers.map((e, index) => ({
    id: index,
    ...e,
  })),
}));

export const test = {
  exam: {
    id: 1,
    groupId: 1,
    name: "exam 1",
    startDate: "2022-12-20T13:14:55.954Z",
    passPercent: 70,
    duration: 60,
    status: "active",
    createdAt: "2022-12-19T13:15:10.566Z",
    updatedAt: "2022-12-19T13:15:12.452Z",
  },
  questions: [
    {
      id: 8,
      name: "<p>sdfasdfa asdf asdf asdf adsf asdf a</p>",
      subjectId: 2,
      status: "active",
      createdAt: "2022-12-19T13:50:01.755Z",
      updatedAt: "2022-12-19T13:50:01.755Z",
      answer: [
        {
          name: "asdf sd f asf asdf asdf",
          id: 27,
        },
        {
          name: "a sdf sdf ds a sdf asdf ",
          id: 30,
        },
        {
          name: " asdfa sdf dsf as fasdf a",
          id: 29,
        },
        {
          name: " asdf sdf ads fasd fad",
          id: 28,
        },
      ],
    },
    {
      id: 6,
      name: "<p>asd asd asd asd sdasdasdas</p>",
      subjectId: 2,
      status: "active",
      createdAt: "2022-12-19T10:03:19.262Z",
      updatedAt: "2022-12-19T10:03:19.262Z",
      answer: [
        {
          name: "asd asd asd asd as",
          id: 20,
        },
        {
          name: "a sdasd asd asd asd as",
          id: 21,
        },
        {
          name: "asd asd asd asdas",
          id: 19,
        },
        {
          name: " asdasda sd asd asdas",
          id: 22,
        },
      ],
    },
    {
      id: 3,
      name: "<p>asdasdas das das dasd asdasdas da sda sdas</p>",
      subjectId: 3,
      status: "active",
      createdAt: "2022-12-19T10:02:13.263Z",
      updatedAt: "2022-12-19T10:02:13.263Z",
      answer: [
        {
          name: "sad asd asd asdasdas",
          id: 9,
        },
        {
          name: "asdas das dasd asda",
          id: 8,
        },
        {
          name: "asdas dasd as",
          id: 10,
        },
        {
          name: "asdasda sd asd asdas",
          id: 7,
        },
      ],
    },
    {
      id: 4,
      name: "<p>asd asd asdasd ads asdasda&nbsp;</p>",
      subjectId: 3,
      status: "active",
      createdAt: "2022-12-19T10:02:33.206Z",
      updatedAt: "2022-12-19T10:02:33.206Z",
      answer: [
        {
          name: "asd asdasdasdasdas",
          id: 13,
        },
        {
          name: "asd asdasdas",
          id: 11,
        },
        {
          name: "sad sdasd asd as",
          id: 14,
        },
        {
          name: "asd asd asdasdas",
          id: 12,
        },
      ],
    },
    {
      id: 9,
      name: "<p>sdf asd fsd fsdf ads fsdf ad</p>",
      subjectId: 1,
      status: "active",
      createdAt: "2022-12-19T13:50:20.260Z",
      updatedAt: "2022-12-19T13:50:20.260Z",
      answer: [
        {
          name: "as df df asf asdf as fas df",
          id: 33,
        },
        {
          name: "as df asdf asd ",
          id: 34,
        },
        {
          name: "asdf sdf s dfa sdf asf",
          id: 31,
        },
      ],
    },
  ],
};
