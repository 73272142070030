import React from "react";
import moment from 'moment';


const formatDate = (date) =>{
    return moment(date).utc().format('DD.MM.YYYY')
}
const formatDateTime = (date) => {
    return moment(date).add(5, 'hours').utc().format('DD.MM.YYYY HH:mm')
}
const formatDateTimeForExam = (date) => {
    return moment(date).utc().format('DD.MM.YYYY HH:mm')
}
export default {
    formatDate,
    formatDateTime,
    formatDateTimeForExam
}
