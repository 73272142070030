import React, { useState, useEffect } from "react";
import {Card, Container, Row, Col, Table, Button, Form} from "react-bootstrap";
import fetchApi from "utils/fetchApi";
import TableResult from "components/Exam/TableResult";
import TableSubjectCount from "components/Exam/TableSubjectCount";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {toast} from "react-toastify";
import {FaDownload} from "react-icons/fa";

const ExamResultView = () => {
    const { Control } = Form;
    const params = useParams();
    const {user} = useSelector(state=>state)
    
    // states
    const [result, setResult] = useState([]);
    const [subjectCount, setSubjectCount] = useState([]);
    const [examLoading, setExamLoading] = useState(true);
    const [subjectLoading, setSubjectLoading] = useState(true);
    const [examResultFile, setExamResultFile] = useState(null);

    //server funtions
    const getResult = (name) => {
        let url = "/exam-result/" + params.id + "/view";
        fetchApi
        .get(url, {
            params: {
            name,
            },
        })
        .then((res) => {
            const result = res.data;
            setResult(result);
            setExamLoading(false);
        });
    };
    const handleChangeExamFile = (e) => {
        setExamResultFile(e.target.files[0]);
    }
    const getSubject = () => {
        let url = "/exam-result/" + params.id + "/subject";
        fetchApi
        .get(url, {
            params: {
            name,
            },
        })
        .then((res) => {
            const subjects = res.data;
            console.log(subjects);
            setSubjectCount(subjects);
            setSubjectLoading(false);
        });
    }

    const handleGetResultOrder = () => {
        const link = document.createElement("a");
        link.target = "blank";
        link.href = result.orderFile;
        link.download = result.orderFile;
        link.click()
    }

    const updateExamResult = () => {
        setExamLoading(false);
        const formData = new FormData();
        const url = `/exam-result/${result.id}/update`;
        formData.append(
            'orderFile',
            examResultFile,
            examResultFile.name
        );
        fetchApi
            .post(url, formData)
            .then((res) => {
                setExamLoading(false);
                setExamResultFile(null);
                toast.success(res.data.message);
            })
            .catch((err) => {
                toast.error(err.data.message);
            });
    }

  // Lifecycle
  useEffect(() => {
    getResult();
    getSubject();
  }, []);
  

  return (
    <div className="selector-group">
      <Container fluid>
        <Row>
        <Col md="6">
            <Card className="card-plain table-plain-bg bg-white  rounded">
                <Card.Header>
                    Fanlar va savollar soni
                </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                {subjectLoading ? (
                  <h1>Loading...</h1>
                ) : (
                  <TableSubjectCount
                    subjects={subjectCount}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
            <Col md="6">
                <Card className="card-plain table-plain-bg bg-white  rounded">
                    <Form>
                        <Card.Header>
                            Natija xolatini o'zgartrish
                        </Card.Header>
                        <Card.Body>
                            <Control
                                onChange={handleChangeExamFile}
                                type="file"
                                placeholder="Akt"
                            />
                        </Card.Body>
                        <Card.Footer>
                            {/*<Row>*/}
                                <Col md="6">
                                    <Button variant="primary" onClick={updateExamResult}>
                                        O'zgartrish
                                    </Button>
                                </Col>
                                <Col md="6">
                                    {result.orderFile ? (
                                        <div>
                                            Aktni yuklab olish :
                                            <Button variant="primary" onClick={handleGetResultOrder}>
                                                <FaDownload></FaDownload>
                                            </Button>
                                        </div>

                                    ) : ("")}
                                </Col>
                            {/*</Row>*/}
                        </Card.Footer>
                    </Form>
                </Card>
            </Col>
          <Col md="12">
            <Card className="card-plain table-plain-bg bg-white  rounded">
                <Card.Header>
                    Savollar va Javoblar
                </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                {examLoading ? (
                  <h1>Loading...</h1>
                ) : (
                  <TableResult
                    result={result.resultData}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ExamResultView;
