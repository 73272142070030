import React from "react";
import { FaPlay, FaEye } from "react-icons/fa";
import { Table } from "react-bootstrap";
import limitCount from "utils/limitCount";
import { Link } from "react-router-dom";
import format from "../../utils/moment";

const TableTask = (
    {
        exams,
        currentPage,
        limitData
    }
) => {
      let  itemCount = (currentPage - 1) * limitData + 1
    return (
        <Table striped bordered responsive hover size="sm">
            <thead>
            <tr>
                <th className="border-0">#</th>
                <th className="border-0">Ariza</th>
                <th className="border-0">Arizachi</th>
                <th className="border-0">Viloyat</th>
                <th className="border-0">Tuman</th>
                <th className="border-0">Kelib tushgan sana</th>
                <th className="border-0">Holati</th>
                <th className="border-0">Harakat</th>
            </tr>
            </thead>
            <tbody>
            {exams.map((item, idx) => (
                <tr key={idx}>
                    <td>{itemCount + idx}</td>
                    <td className="px-1">{item?.epiguId}</td>
                    <td className="px-1">{item?.fullName}</td>
                    <td className="px-1">{item?.region?.name}</td>
                    <td className="px-1">{item?.district?.name}</td>
                    <td className="px-1">
                        {format.formatDate(item.createdAt)}
                    </td>
                    <td className="px-1">{item?.generalStatus?.name}</td>
                    <td>
                        <Link
                            to={`task/${item.id}/view`}
                            className="btn btn-sm btn-primary">
                            <FaEye/>
                        </Link>
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
    );
};

export default TableTask;
