import React from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import { logout } from "store/action";
import { useDispatch } from "react-redux";
import routes from "routes.js";
import { MdOutlineExitToApp } from "react-icons/md";

function Header() {
  //components
  const { Brand, Toggle, Collapse } = Navbar;
  const { Item, Link } = Nav;

  // HOOKs
  const location = useLocation();
  const dispatch = useDispatch();

  // handler function
  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "";
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
  };

  return (
    <Navbar
      style={{
        borderLeft: "1px solid #fff",
        backgroundColor: "rgb(96 165 250)",
      }}
      expand="lg">
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
          <Button
            variant="dark"
            className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
            onClick={mobileSidebarToggle}>
            <i className="fas fa-ellipsis-v" />
          </Button>
          <Brand
            href="#home"
            onClick={(e) => e.preventDefault()}
            className="mr-2 text-white">
            {getBrandText()}
          </Brand>
        </div>
        <Toggle aria-controls="basic-navbar-nav" className="mr-2">
          <span className="navbar-toggler-bar burger-lines" />
          <span className="navbar-toggler-bar burger-lines" />
          <span className="navbar-toggler-bar burger-lines" />
        </Toggle>
        <Nav
          className="ml-auto"
          navbar
          style={{
            borderRadius: "6px",
            backgroundColor: "white",
          }}>
          <Item>
            <Button
              style={{
                borderRadius: "6px",
                backgroundColor: "white",
              }}
              className="btn btn-sm btn-primary m-0 d-flex align-items-center"
              onClick={handleLogout}>
              <MdOutlineExitToApp />
              <span>Chiqish</span>
            </Button>
          </Item>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default Header;
