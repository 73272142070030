import React from "react";
import { FaEye, FaPlay } from "react-icons/fa";
import { Table } from "react-bootstrap";
import limitCount from "utils/limitCount";
import { Link } from "react-router-dom";

const TableSubjectCount = ({subjects}) => {
const parse = require("html-react-parser");
    return (
        <Table striped bordered responsive hover size="sm">
        <thead>
            <tr>
            <th className="border-0">#</th>
            <th className="border-0">Fan</th>
            <th className="border-0">Savollar soni</th>
            </tr>
        </thead>
        <tbody>
            {subjects.map((item, idx) => (
            <tr key={idx}>
                <td>{idx + 1}</td>
                <td className="px-1">{item.subject.name}</td>
                <td className="px-1">{item.count}</td>
                <td>
                    
                </td>
            </tr>
            ))}
        </tbody>
        </Table>
  );
};

export default TableSubjectCount;
